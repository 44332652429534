import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import Lightbox from "react-image-lightbox";

import 'swiper/css';
import "swiper/css/navigation";

import { Navigation } from "swiper"
import Axios from 'axios';
import ContractUse from './ContractUse';
import { Button } from 'reactstrap';

class SingleImovelCardCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            count:0,
            allPhotos:[],
            verifyPremium:false,
            contractAccept:'false',
            isGalleryZoom: false,
            acceptContract: false,
            showCustomButton: true,
        }
        this.handleContractClosed = this.handleContractClosed.bind(this);
    }
    
    componentDidUpdate(prevProps) {
      let params = new URLSearchParams(window.location.search)
      const access = params.get('ce29')
      const userId = params.get('ba05a')
      if (prevProps.photos !== this.props.photos) {
        Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
          property_id:this.props.imovel.id
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          this.setState({ showCustomButton: false })
          if(userId){
            Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
              type:access,
              user_id:userId
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
              this.setState({verifyPremium: true})
            })
            .catch(response => {
              this.setState({verifyPremium: false})
            })
          }

          this.setState({ allPhotos: this.props.photos.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1) });
        })
        .catch(response => {
          if(this.props.public !== 1){
            this.setState({ allPhotos: this.props.photos.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1) });
            this.setState({ contractAccept: 'accept' });
          }else{
            if(userId){
              Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
                type:access,
                user_id:userId
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({verifyPremium: true})
              })
              .catch(response => {
                this.setState({verifyPremium: false})
              })
            }
            this.setState({ allPhotos: this.props.photos });
          }
        })
      }
    }
  
    handleContractClosed() {
      this.setState({ acceptContract: false });
      Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
        type:localStorage.getItem('access'),
        user_id:localStorage.getItem('id'),
        property_id:this.props.imovel.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({ showCustomButton: false, contractAccept: 'accepted' })
        this.setState({ allPhotos: this.props.photos });
      })
      .catch(response => {
        this.setState({ showCustomButton: true })
      })
    }    


    render() {

        const {photoIndex, allPhotos, verifyPremium, showCustomButton} = this.state;
        const { premium } = this.props;

        console.log(allPhotos)

        const customToolbarButtons = showCustomButton ? [
          <Button key="more" onClick={() => {
            this.setState({acceptContract: true})
            }}>
            Ver mais fotos
          </Button>
        ] : [];

        
        return (
            <React.Fragment>
              <ContractUse
                acceptContract={this.state.acceptContract}
                onContractClosed={this.handleContractClosed}
                onContractAcceptFotos={this.props.onContractAcceptFotos}
                imovel={this.props.imovel}
                premium={premium}
                origem={'fotos'}
              />
              <Swiper
                slidesPerView={1}
                initialSlide={2}
                spaceBetween={0}
                speed={500}
                effect='fade'
                navigation={true}
                breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                    1360: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                      initialSlide: 0,
                    },
                  }}
                modules={[Navigation]}
                className="SwiperImovelCard"
                >
                  {
                    allPhotos?.map((photos, index) => (
                      <SwiperSlide key={index}>
                        <img 
                          alt='Foto imóvel'
                          src={photos?.url} 
                          className="slidePhoto" 
                          onClick={() => {
                              this.setState({ isGalleryZoom: true, photoIndex: index })
                          }}
                        ></img>
                      </SwiperSlide>
                    ))}
                </Swiper>
                {this.state.isGalleryZoom ? (
                  
                  <Lightbox
                    mainSrc={allPhotos[photoIndex]?.url}
                    nextSrc={allPhotos[(photoIndex + 1) % allPhotos.length]?.url}
                    prevSrc={
                      allPhotos[(photoIndex + allPhotos.length - 1) % allPhotos.length]?.url
                    }
                    onCloseRequest={() => this.setState({ isGalleryZoom: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + allPhotos.length - 1) % allPhotos.length
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % allPhotos.length
                    })
                    }
                  />
            
                ) : null}
            </React.Fragment>
        );
    }
}

export default SingleImovelCardCarousel;   