import './styles.scss';
import Cadore from './../../../../../assets/images/cadorefoguete2.png'
import Logo from './../../../../../assets/images/popups/logotinder.png'

export const BannerConecta = () => {
    return(
        <>
        <div className="banner-conecta">
            <div className="info-col">
                <div className="info-col-title">
                    <div className="info-col-subtitle">
                        <span>Garanta o</span>
                        <span>CUPOM</span>
                    </div>
                    <div className="info-col-promo">
                        <div className="info-promo">
                            <span>30% DESCONTO</span>
                            <span>CONECTA</span>
                        </div>
                    </div>
                </div>
                <span>
                    <p>Venda seus imóveis</p>
                    <p>em parceria com milhares de corretores.</p>
                </span>
                <img className="logo-conecta" src={Logo}/>
            </div>
            <div className="image-col">
                <div className="image-col-title">
                    O jeito americano
                    <span>de vender imóveis</span>
                </div>
                <img className="cadore-foguete-conecta" src={Cadore}></img>
            </div>
        </div>
        <div className="conecta-more-info">
            <a href="/home" target="_blank">Precisa de mais informações? <b>Clique aqui.</b></a>
        </div>
        </>
    )
}