import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, NavLink, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button, Nav, NavItem, TabContent, TabPane, CardText, CardTitle, Form, Modal, ModalHeader, ModalBody, Progress } from "reactstrap";
import { Link } from "react-router-dom";
import Axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import './../../assets/scss/custom/components/_editProperties.scss'
import classnames from "classnames";
import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import Dropzone from 'react-dropzone';
import CurrencyInput from '../../components/Inputs/CurrencyInput';
import { Draggable } from 'react-drag-reorder';
import PhotosOrder from './photosOrder';
import Cafezinho from './../../assets/images/upload/cafezinho.png';
import Contrato from './../../assets/images/upload/contrato.png';
import Corretores from './../../assets/images/upload/corretores.png';
import Instante from './../../assets/images/upload/instante.png';
import Upload from './../../assets/images/upload/upload.png';
import Sucesso from './../../assets/images/upload/sucesso.png';
import HorizontalCard from '../Dashboard/HorizontalCard';
import HorizontalMiniCard from '../Dashboard/HorizontalMiniCard';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

require('moment/locale/pt.js');


class editPropertiesFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imovel: [],
      reason:'',
      reasonOther:'',
      activeTabJustify: "5",
      disbadge: true,
			optioncount: 0,
      advanceclass: "badgecount",
      selectedBroker: [],
      selectedRealEstate: [],
      selectedOwner: [],
      selectedConstruction:[],
      selectedUf: [],
      selectedCity: [],
      selectOptionsBroker: [],
      selectOptionsRealEstate: [],
      selectOptionsConstructions:[],
      selectOptionsBrokerByRealEstate: [],
      selectOptionsOwner: [],
      selectOptionsUf: [],
      selectOptionsCity: [],
      selectedFiles: [],
      selectedAuth: [],
      selectedDocs: [],
      stateSelected: '',
      confirm_both:false,
      citySelected:'',
      andar:'',
      statesAddress: [],
      cityAddress: [],
      logradouro:'',
      dragPhotos:'',
      bairro:'',
      condominioValor:'',
      valor:'',
      valorPromocional:'',
      iptuValor:'',
      marinhaValor:'',
      tipoContrato:'',
      comissao:'',
      nomeProprietario:'',
      emailProprietario:'',
      telefoneProprietario: '',
      tipoImovel:'',
      matricula:'',
      registro:'',
      title:'',
      areaTerreno:'',
      areaConstruida:'',
      areaPrivativa:'',
      descricao:'',
      nomeResponsavel:'',
      contatoResponsavel: '',
      status:0,
      oldStatus:0,
      cep:'',
      number:'',
      complemento:'',
      pais:'',
      empreendimento:'',
      construcao:'',
      torres:'',
      andares:'',
      unidadesAndar:'',
      featuresEmpreendList:[],
      nGaragens:'',
      nQuartos:'',
      nSuites:'',
      nDemiSuites:'',
      nBanheiros:'',
      nLavabos:'',
      nElevadores:'',
      nPavimentos:'',
      featuresList:[],
      video:'',
      tour:'',
      photos:[],
      tags:[],
      workflow_observation:'',
      workflow:null,
      selectedOption: [],
      tagsOptions:[],
      modal_center: false,
      update_success: false,
      update_error: false,
      selectedMulti: null,
      photosOrder:[],
      accept_download: true,
      uploadPercentage: 0,
      uploading: false,
      dots: '.',
      selectedFiles: [],
      deleteSuccess:false,
      errors:[],
      deleteError:false,
      count:0,
      progressBars: [],
      deleteSuccessDoc:false,
      errorsDoc:[],
      deleteErrorDoc:false,
      countDoc:0,
      progressBarsDoc: [],
      deleteSuccessAuth:false,
      errorsAuth:[],
      deleteErrorAuth:false,
      countAuth:0,
      progressBarsAuth: [],
      checkAddressProperty:[],
      checkAddress:false,
      updateSuccess:false,
      updateError:false,
      sendImageError:false,

      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Imóveis", link: "/editar-imoveis" },
        { title: localStorage.getItem('edit_property_sku'), link: "#" },
    ],
    }
    this.optionchange = this.optionchange.bind(this);
    this.handleSelectState = this.handleSelectState.bind(this);
    this.handleSendPhotos = this.handleSendPhotos.bind(this);
    this.handleSendDocs = this.handleSendDocs.bind(this);
    this.handleSendAuth = this.handleSendAuth.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.handleDeleteDoc = this.handleDeleteDoc.bind(this);
    this.handleCheckAddress = this.handleCheckAddress.bind(this);
    this.showToast.bind(this);
    // this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  showToast(type, title, message = "") {
    var positionClass = "toast-bottom-right";
    
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration
    };
  
    if (type === "info") {
      toastr.info(message, title);
    } else if (type === "warning") {
      toastr.warning(message, title);
    } else if (type === "error") {
      toastr.error(message, title);
    } else {
      toastr.success(message, title);
    }
  }    

  componentWillUnmount() {
    clearInterval(this.dotsInterval); // Limpar na desmontagem do componente
  }

  componentDidMount(){
    this.dotsInterval = setInterval(() => {
      this.setState(prevState => ({
        dots: prevState.dots.length < 3 ? prevState.dots + '.' : '.'
      }));
    }, 500); // Atualiza a cada 500ms
      document.getElementById('load').classList.add('active')
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            document.getElementById('accept_download').click()

            const type = localStorage.getItem('access') === 'S' ? 'T' : localStorage.getItem('access')
            const userId = localStorage.getItem('access') === 'S' ? localStorage.getItem('construction_id') : localStorage.getItem('id')
            const imovelId = window.location.search.replace('?', '')
            const data = {
              access: type,
              user_id: userId,
              property_id: imovelId
            }

            if(localStorage.getItem('access') !== 'A'){
              Axios.post(process.env.REACT_APP_API + '/api/auth/validate-auth', data,
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')} })
              .then(response => {
              })
              .catch(response => {
                // error('Você naõ tem permissão para alterar este imóvel.')
                setTimeout(() => {
                  window.open('./editar-imoveis', '_parent')
                }, 500);
              })
            }

                Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + imovelId,
                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(response => {
                  this.setState({imovel: response.data})

                  setTimeout(() => {
                    document.getElementById('load').classList.remove('active')
                  }, 300);
                  this.setState({tipoContrato: response.data.sell_contract_type})
                  this.setState({status: response.data.status})
                  this.setState({oldStatus: response.data.status})
                  this.setState({comissao: response.data.commission_percentage_on_sale})
                  this.setState({nomeProprietario: response.data.owner_name})
                  this.setState({emailProprietario: response.data.owner?.email})
                  this.setState({telefoneProprietario: response.data.owner?.phone})
                  this.setState({nomeCorretor: response.data.broker?.name})
                  this.setState({emailCorretor: response.data.broker?.email})
                  this.setState({telefoneCorretor: response.data.broker?.phone})
                  this.setState({nomeImobiliaria: response.data.real_estate?.name})
                  this.setState({emailImobiliaria: response.data.real_estate?.email})
                  this.setState({telefoneImobiliaria: response.data.real_estate?.phone})
                  this.setState({tipoImovel: response.data.categories.id})
                  this.setState({title: response.data.title})
                  this.setState({matricula: response.data.registry})
                  this.setState({registro: response.data.real_estate_registration})
                  this.setState({condominioValor: response.data.building_tax_value})
                  this.setState({iptuValor: response.data.city_tax_value})
                  this.setState({marinhaValor: response.data.fee_marina})
                  this.setState({areaTerreno: response.data.terrain_measures})
                  this.setState({areaConstruida: response.data.build_measures})
                  this.setState({areaPrivativa: response.data.endorsed_measures})
                  this.setState({valor: response.data.sale_value})
                  console.log(response.data.sale_value)
                  this.setState({valorPromocional: response.data.sell_price})
                  this.setState({descricao: response.data.description})
                  this.setState({nomeResponsavel: response.data.responsible_visit_name})
                  this.setState({contatoResponsavel: response.data.responsible_visit_phone})
                  this.setState({nGaragens: response.data.parking_spots})
                  this.setState({nQuartos: response.data.dorms_number})
                  this.setState({nSuites: response.data.suites_number})
                  this.setState({nDemiSuites: response.data.semi_suites})
                  this.setState({nBanheiros: response.data.bathroom_number})
                  this.setState({nLavabos: response.data.n_washrooms})
                  this.setState({nElevadores: response.data.elevator_number})
                  this.setState({nPavimentos: response.data.floor_number})
                  this.setState({video: response.data.youtube_video})
                  this.setState({tour: response.data.tour_360})
                  this.setState({andar: response.data.address_properties.floor ? response.data.address_properties.floor : ''})

                  this.setState({cep: response.data.address_properties.cep ? response.data.address_properties.cep : ''})
                  this.setState({logradouro: response.data.address_properties.street ? response.data.address_properties.street : ''})
                  this.setState({numero: response.data.address_properties.number ? response.data.address_properties.number : ''})
                  this.setState({unidade: response.data.address_properties.unities ? response.data.address_properties.unities : ''})
                  this.setState({complemento: response.data.address_properties.complement ? response.data.address_properties.complement : ''})
                  this.setState({bairro: response.data.address_properties.district ? response.data.address_properties.district : ''})
                  this.setState({pais: response.data.address_properties.country ? response.data.address_properties.country : ''})
                  if(response.data.address_properties.state){
                    this.handleSelectState({
                      value:response.data.address_properties.state,
                      label:response.data.address_properties.state
                    })
                  }
                  if(response.data.address_properties.city){
                    this.setState({citySelected: {
                      value:response.data.address_properties.city,
                      label:response.data.address_properties.city
                    }});
                  }

                  this.setState({empreendimento: response.data.address_properties.enterprise ? response.data.address_properties.enterprise : ''})

                  this.setState({workflow: response.data.workflow})
                  this.setState({workflow_observation: response.data.workflow_observation})
                  // this.setState({tags: response.data?.tags})

                  if(response.data.broker?.id){
                    this.setState({selectedBroker: {
                      value:response.data.broker.id,
                      label:response.data.broker.name
                    }})
                  }
                  if(response.data.real_estate?.id){
                    this.setState({selectedRealEstate: {
                      value:response.data.real_estate.id,
                      label:response.data.real_estate.name
                    }})
                  }
                  if(response.data.construction?.id){
                    this.setState({selectedConstruction: {
                      value:response.data.construction.id,
                      label:response.data.construction.name
                    }})
                  }
                  if(response.data.owner?.id && response.data?.owner?.id !== 302){
                    this.setState({selectedOwner: {
                      value:response.data.owner.id,
                      label:response.data.owner.name
                    }})
                  }

                  if(response.data.real_state_financing === 1){document.getElementById('financiamento').click()}
                  if(response.data.accept_property === 1){document.getElementById('permutaImovel').click()}
                  if(response.data.accept_car === 1){document.getElementById('permutaAutomovel').click()}
                  // if(response.data?.accept_object_exchange === 1){document.getElementById('permutaObjeto').click()}

                  response.data.features?.map((feature, index) => {
                    this.state.featuresEmpreendList.push(feature.name)
                  },[])

                  if(this.state.featuresEmpreendList.includes("Piscina")){document.getElementById('empPiscina').click()}
                  if(this.state.featuresEmpreendList.includes("Espaço Gourmet Empreendimento")){document.getElementById('empEspacoGourmet').click()}
                  if(this.state.featuresEmpreendList.includes("Salão de festas")){document.getElementById('empSalaoFestas').click()}

                  response.data?.features.map((feature, index) => {
                    this.state.featuresList.push(feature.name)
                  },[])

                  if(this.state.featuresList.includes("Churrasqueira a carvão")){document.getElementById('churrasqueiraCarvao').click()}
                  if(this.state.featuresList.includes("Closet")){document.getElementById('closet').click()}
                  if(this.state.featuresList.includes("Banheira")){document.getElementById('banheira').click()}
                  if(this.state.featuresList.includes("Piscina Privativa")){document.getElementById('piscinaPriv').click()}
                  if(this.state.featuresList.includes("Gás encanado")){document.getElementById('gasEncanado').click()}
                  if(response.data?.decorated === 1){document.getElementById('decorado').click()}

                    Axios.get(process.env.REACT_APP_API + '/api/auth/owners-select',
                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                      for(var i = 0; response.data?.length > i; i++){
                        this.state.selectOptionsOwner.push({
                          value: response.data[i].id,
                          label: response.data[i].name
                        })
                      }
                    })
                    Axios.get(process.env.REACT_APP_API + '/api/auth/brokers-select',
                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                      for(var i = 0; response.data?.length > i; i++){
                        this.state.selectOptionsBroker.push({
                          value: response.data[i].id,
                          label: response.data[i].name
                        })
                      }
                      })
                    Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-select',
                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                      for(var i = 0; response.data?.length > i; i++){
                        this.state.selectOptionsRealEstate.push({
                          value: response.data[i].id,
                          label: response.data[i].name
                        })
                      }
                    })

                    Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions',
                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                      for(var i = 0; response.data?.length > i; i++){
                        this.state.selectOptionsConstructions.push({
                          value: response.data[i].id,
                          label: response.data[i].name
                        })
                      }
                    })

                    if(localStorage.getItem('access') === 'I'){
                      Axios.get(process.env.REACT_APP_API + '/api/auth/real_estate_teams/by_real_estate/' + localStorage.getItem('id'),
                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
                          console.log(response.data.realEstateTeams)
              
                          for(var i = 0; response.data?.realEstateTeams?.length > i; i++){
                            this.state.selectOptionsBrokerByRealEstate.push({
                              value: response.data.realEstateTeams[i]?.broker.id,
                              label: response.data.realEstateTeams[i]?.broker.name
                            })
                          }
                        })
                    }
                    // Axios.post(process.env.REACT_APP_API + '/api/tag/' + 't',{},
                    // {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    // .then(response => {
                    //   this.setState({tagsOptions: response.data})
                    //   console.log(response.data)
                    // this.fetchOptions("");
                    // })
              })

            Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
            .then(response => {
              const states = []
              for(var i = 0; response.data?.length > i; i++){
                states.push({
                  value: response.data[i].sigla,
                  label: response.data[i].sigla
                })
              }
              this.setState({statesAddress: states})
            })
          })
          .catch(response =>{
            localStorage.removeItem("history_url")
            setTimeout(() => {
              window.open("/login", '_parent')
            }, 300);
          })
  }

  loadOptions = async (inputValue, callback) => {
    try {
        const response = await Axios.post(process.env.REACT_APP_API + `/api/tag/${inputValue}`, {}, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}});
        const results = response.data;
        const options = results.map((item) => ({
            value: item.id,
            label: item.name,
        }));
        callback(options);
    } catch (error) {
        console.log(error);
    }
};



  handleCreate = (inputValue) => {

    const { selectedOption } = this.state;

    Axios.post(process.env.REACT_APP_API + `/api/tag/`, {name: inputValue},
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      setTimeout(() => {
        Axios.post(process.env.REACT_APP_API + `/api/tag/` + inputValue, {},
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          setTimeout(() => {
            resp.data.filter(tag => tag?.name === inputValue).map((tagAdd) => {
              this.setState({selectedOption: [...selectedOption, {label: tagAdd?.name, value: tagAdd?.id}]})
              this.state.tags.push(tagAdd?.id)
            })
          }, 500);
        })
      }, 500);
    })
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    for(var i = 0; i < selectedOption.length; i++){
      if(!this.state.tags.includes(selectedOption[i].value)){
        this.state.tags.push(selectedOption[i].value);
      }
    }
  };

  handleAcceptedFiles = files => {
    const acceptedFiles = [];
    files.forEach(file => {
        if (file.size <= 8 * 1024 * 1024) {
            acceptedFiles.push(file);
        } else {
            this.setState(prevState => ({
                errors: [...prevState.errors, { name: file.name, message: 'Tamanho excede 8MB' }]
            }));
        }
    });

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedFiles: files });
    this.handleSendPhotos()
  };

  handleAcceptedAuth = files => {
    console.log(files)
    const acceptedFiles = [];
    files.forEach(file => {
        if (file.size <= 8 * 1024 * 1024) {
            acceptedFiles.push(file);
        } else {
            this.setState(prevState => ({
                errors: [...prevState.errors, { name: file.name, message: 'Tamanho excede 8MB' }]
            }));
        }
    });

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedAuth: files });
    this.handleSendAuth()
  };

  handleAcceptedDocs = files => {
    const acceptedFiles = [];
    files.forEach(file => {
        if (file.size <= 8 * 1024 * 1024) {
            acceptedFiles.push(file);
        } else {
            this.setState(prevState => ({
                errors: [...prevState.errors, { name: file.name, message: 'Tamanho excede 8MB' }]
            }));
        }
    });

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size)
      })
    );

    this.setState({ selectedDocs: files });
    this.handleSendDocs()
  };
  
  handleSendPhotos = () => {
    document.getElementById('load').classList.add('active')
    const { selectedFiles } = this.state;
    const totalPhotos = selectedFiles.length;
    this.setState({ progressBars: new Array(totalPhotos).fill(0) });

    if(selectedFiles.length > 0){

      selectedFiles.forEach((photo, index) => {
        const data = new FormData();
        data.append(`photo[0][file]`, photo);
        data.append(`photo[0][position]`, index);

        setTimeout(() => {
          Axios.post(process.env.REACT_APP_API + '/api/auth/property-update-images/' + this.state.imovel?.id, data, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
            onUploadProgress: event => {
                const progress = Math.round(100 * event.loaded) / event.total;
                const updatedProgressBars = [...this.state.progressBars];
                updatedProgressBars[index] = progress;
                this.setState({ progressBars: updatedProgressBars }, () => {
                  this.forceUpdate();
              });
            }
        }).then(response => {
            if (index === totalPhotos - 1) {
                document.getElementById('load').classList.remove('active')

                Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + this.state.imovel?.id, {
                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {
                  this.setState({imovel: response.data})
                   this.setState({selectedFiles: []})
                    this.setState({count : this.state.count + 1})
                })
            }
        }).catch(error => {
          const updatedErrors = [...this.state.errors];
          updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
          this.setState({ errors: updatedErrors });
          this.setState({sendImageError: true})
        });
        }, 500);

      });
    }
  }

  handleSendDocs = () => {
    document.getElementById('load').classList.add('active')
    const { selectedDocs } = this.state;
    const totalPhotos = selectedDocs.length;
    this.setState({ progressBarsDoc: new Array(totalPhotos).fill(0) });

    if(selectedDocs.length > 0){

      selectedDocs.forEach((photo, index) => {
        const data = new FormData();
        data.append(`docs[]`, this.state.selectedDocs[index]);

        setTimeout(() => {
          Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' + this.state.imovel?.id, data, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
            onUploadProgress: event => {
                const progress = Math.round(100 * event.loaded) / event.total;
                const updatedProgressBars = [...this.state.progressBarsDoc];
                updatedProgressBars[index] = progress;
                this.setState({ progressBarsDoc: updatedProgressBars }, () => {
                  this.forceUpdate();
              });
            }
        }).then(response => {
            if (index === totalPhotos - 1) {
                document.getElementById('load').classList.remove('active')

                Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + this.state.imovel?.id, {
                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {
                  this.setState({imovel: response.data})
                   this.setState({selectedDocs: []})
                    this.setState({countDoc : this.state.countDoc + 1})
                })
            }
        }).catch(error => {
          const updatedErrors = [...this.state.errorsDoc];
          updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
          this.setState({ errorsDoc: updatedErrors });
          this.setState({sendImageError: true})
        });
        }, 500);

      });
    }
  }

  handleSendAuth = () => {
    document.getElementById('load').classList.add('active')
    const { selectedAuth } = this.state;
    const totalPhotos = selectedAuth.length;
    this.setState({ progressBarsAuth: new Array(totalPhotos).fill(0) });

    if(selectedAuth.length > 0){

      selectedAuth.forEach((photo, index) => {
        const data = new FormData();
        data.append('auth_photo', this.state.selectedAuth[0])

        setTimeout(() => {
          Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' + this.state.imovel?.id, data, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') },
            onUploadProgress: event => {
                const progress = Math.round(100 * event.loaded) / event.total;
                const updatedProgressBars = [...this.state.progressBarsAuth];
                updatedProgressBars[index] = progress;
                this.setState({ progressBarsAuth: updatedProgressBars }, () => {
                  this.forceUpdate();
              });
            }
        }).then(response => {
            if (index === totalPhotos - 1) {
                document.getElementById('load').classList.remove('active')

                Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + this.state.imovel?.id, {
                  headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
                }).then(response => {
                  this.setState({imovel: response.data})
                   this.setState({selectedAuth: []})
                    this.setState({countAuth : this.state.countAuth + 1})
                })
            }
        }).catch(error => {
          const updatedErrors = [...this.state.errorsAuth];
          updatedErrors[index] = { isError: true, message: 'Erro no envio da imagem' };
          this.setState({ errorsAuth: updatedErrors });
          this.setState({sendImageError: true})
        });
        }, 500);

      });
    }
  }
  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };


  toggleCustomJustified(tab) {
		if (this.state.activeTabJustify !== tab) {
			this.setState({
				activeTabJustify: tab
			});
		}
	}

  optionchange(event) {
		var count = event.target.value.length;
		if (count > 0) {
			this.setState({ disbadge: true });
		} else {
			this.setState({ disbadge: false });
		}
		if (count > 99) {
			this.setState({ advanceclass: "badgecountextra" });
		} else {
			this.setState({ advanceclass: "badgecount" });
		}
		this.setState({ optioncount: event.target.value.length });
	}

  handleSelectState = stateSelected => {
		this.setState({ stateSelected });
    Axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + stateSelected.value + '/municipios')
    .then(response => {
      const city = []
      for(var i = 0; response.data?.length > i; i++){
        city.push({
          label: response.data[i].nome,
          value: response.data[i].nome
        })
      }
      this.setState({cityAddress: city})
    })
	};

  handleFormData = (orders, update) => {
    const data = new FormData();
    for(let i = 0; i < update.length; i++){
      data.append('order[]', orders[i])
      data.append('id[]', update[i])
    }
    this.setState({ photosOrder: data });

    // this.updatePositions()
    Axios.post(process.env.REACT_APP_API + '/api/auth/photo-order', this.state.photosOrder,
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}
    }).then(response => {
      document.getElementById('load').classList.remove('active')
      this.showToast('success', 'Fotos reordenadas com sucesso!')
    }).catch(response => {
      document.getElementById('load').classList.remove('active')
      this.showToast('error', 'Houve um problema com a ordenação, tente novamente ou informe o suporte.')
    })
  };

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  deleteImage(photo_id) {
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
      this.setState({error_auth:true})
    }else{
    Axios.post(process.env.REACT_APP_API + '/api/auth/photo-delete/' + photo_id, {},
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({deleteSuccess: true})

        Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + this.state.imovel?.id, {
          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
          this.setState({imovel: response.data})
           this.setState({count : this.state.count + 1})
        })
      })              
      .catch(response => {
        this.setState({deleteError: true})
      })
    }
   }

   handleDeleteDoc(doc_id){
    if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
      this.setState({error_auth:true})
    }else{
    Axios.post(process.env.REACT_APP_API + '/api/auth/document-delete/' + doc_id, {},
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({deleteSuccess: true})

        Axios.get(process.env.REACT_APP_API + '/api/auth/property/' + this.state.imovel?.id, {
          headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        }).then(response => {
          this.setState({imovel: response.data})
           this.setState({count : this.state.count + 1})
        })
      })              
      .catch(response => {
        this.setState({deleteError: true})
      })
    }
   }

   handleCheckAddress(){
    this.setState({checkAddress: true})
    Axios.get(process.env.REACT_APP_API + '/api/auth/check-property-address/' + this.state.imovel.id,
    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
    .then(response => {
      console.log(response.data)
      this.setState({checkAddressProperty:response.data})
    })
   }

  render() {

    const Phone = props => (
      <InputMask
        mask="(99) 99999-9999"
        value={props.value}
        className="form-control input-color"
        placeholder="(99) 99999-9999"
        style={{height:'37px'}}
        onChange={props.onChange}
      >
        {inputProps => (
          <MaterialInput {...inputProps} type="tel" disableUnderline />
        )}
      </InputMask>
    );

    const formattedDate = this.formatDate(new Date());

    const {imovel} = this.state;
    const { dragPhotos, selectedOwner, selectedRealEstate, selectedConstruction, tagsOptions, tags, selectedOption, statesAddress, cityAddress, citySelected, stateSelected, selectedBroker, selectOptionsBroker, selectOptionsConstructions, selectOptionsRealEstate, selectOptionsOwner, selectOptionsUf, selectOptionsCity } = this.state;
    
    return (
        <div className="page-content" id="propertyEdit">
        <Container fluid={true}>
          <Breadcrumbs title="Editar imóvel" breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-auto home-icon">
                      <i className="ri-home-2-fill"></i>
                    </Col>
                    <Col>
                      <p style={{display:'flex', alignItems:'center', gap:'8px', fontSize:'18px', fontWeight:'600'}}>{imovel?.title}
                      <Button style={{marginLeft: '10px', padding:'5px'}} onClick={() => {
                        setTimeout(() => {
                          window.open("../imovel/" + imovel?.slug + '?owner', '_blank')
                        }, 300);
                      }}>Ver imóvel</Button>
                      {localStorage.getItem('access') === 'A' &&
                        <Button style={{marginLeft: '10px', padding:'5px'}} onClick={this.handleCheckAddress}>Checar endereço</Button>
                      }
                      </p><small>({imovel?.sku})</small>
                    </Col>
                  </Row>
                  <Row className="edtiPropertyStatusContainer">
                    <Col className="col-auto" style={{display:'flex', alignItems:'center', gap:'8px'}}>
                      <Label style={{display:'flex', alignItems:'center', gap:'5px'}}>Situação: <i className="ri-error-warning-line" onClick={() => {
                        this.setState({modal_center: true})
                      }}
                      style={{cursor:'pointer'}}></i></Label>
                      {imovel?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Pendente</Badge>
                      : imovel?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Rep. Fotos</Badge>
                      : imovel?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Rep. Aut. Venda</Badge>
                      : imovel?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Rep. Prop.</Badge>
                      : imovel?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Rep. Imóvel</Badge>
                      : imovel?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Rep. Fora do padrão</Badge>
                      : imovel?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Repetido</Badge>
                      : imovel?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Vendido (ext)</Badge>
                      : imovel?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Vendido (fs)</Badge>
                      : imovel?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Rep. Imóvel</Badge>
                      : imovel?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Bloqueado</Badge>
                      : imovel?.workflow === 13 ? <Badge className="bg-warning rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Pendente Atualização</Badge>
                      : imovel?.workflow === 14 ? <Badge className="bg-danger rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Bloqueado Atualização</Badge>
                      : imovel?.workflow === 20 ? <Badge className="bg-light rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer'}} onClick={() => {
                        this.setState({modal_center: true})
                      }}>Rascunho</Badge>
                      : <Badge style={{fontSize:'16px', cursor:'pointer'}} className="bg-success me-1 rounded-pill" onClick={() => {
                        this.setState({modal_center: true})
                      }}>Aprovado</Badge>}
                    </Col>
                    <Col className="col-auto" style={{display:'flex', alignItems:'center', gap:'8px'}}>
                      <Label>Status:</Label>
                      <select className="form-control" value={this.state.status} onChange={(e) => this.setState({status: e.target.value})}>
                        <option value={0}>Inativo</option>
                        <option value={1}>Ativo</option>
                      </select>
                    </Col>
                    <Col className="col-auto">
                      {localStorage.getItem('level') !== '2' &&
                      <Button className="btn-success" onClick={() => {
                        if(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view'){
                          this.setState({error_auth:true})
                        }else{
                        document.getElementById('load').classList.add('active')
                        const data1 = new FormData()

                        if(localStorage.getItem('access') === 'A'){
                          if(selectedBroker.value){
                            data1.append('broker_id', selectedBroker.value)
                          }
                          if(selectedRealEstate.value){
                            data1.append('real_estate_id', selectedRealEstate.value)
                          }
                          if(selectedOwner.value){
                            data1.append('owner_id', selectedOwner.value)
                          }
                          if(selectedConstruction.value){
                            data1.append('construction_id', selectedConstruction.value)
                          }
                        }
                        if(localStorage.getItem('access') === 'I'){
                          if(selectedBroker.value){
                            data1.append('broker_id', selectedBroker.value)
                          }
                        }
                        data1.append('owner_name', this.state.nomeProprietario)
                        data1.append('title', this.state.title)

                       data1.append('status', this.state.status)
                       data1.append('last_update', formattedDate)
                        data1.append('contract_type', 'sell')
                        data1.append('sell_contract_type', this.state.tipoContrato)
                        if(this.state.comissao){data1.append('commission_percentage_on_sale', this.state.comissao)}
                        data1.append('category_id', this.state.tipoImovel)
                        data1.append('registry', this.state.matricula)
                        data1.append('real_estate_registration', this.state.registro)
                        if(imovel?.sale_value || this.state.valor){
                          let saleValue = this.state.valor === imovel?.sale_value 
                            ? imovel?.sale_value 
                            : parseInt(this.state.valor?.replace('R$ ', '').replace(',', '').replace(/\./g,''));
                          data1.append('sale_value', isNaN(saleValue) ? 0 : saleValue);
                        }
                        
                        if(imovel?.sell_price || this.state.valorPromocional){
                          let sellPrice = this.state.valorPromocional === imovel?.sell_price 
                            ? imovel?.sell_price 
                            : parseInt(this.state.valorPromocional?.replace('R$ ', '').replace(',', '').replace(/\./g,''));
                          data1.append('sell_price', isNaN(sellPrice) ? 0 : sellPrice);
                        }
                        if (imovel?.building_tax_value || this.state.condominioValor) {
                          const parsedCondominioValor = this.state.condominioValor ? parseInt(this.state.condominioValor?.toString().replace('R$ ', '')?.replace(',', '')?.replace(/\./g, '')) : null;
                      
                          if (!isNaN(parsedCondominioValor)) {
                              data1.append('building_tax_value', this.state.condominioValor === imovel?.building_tax_value ? imovel?.building_tax_value : parsedCondominioValor);
                          }
                      }
                        if(imovel?.city_tax_value || this.state.iptuValor){
                          const parsedIptuValor = parseInt(this.state.iptuValor?.toString().replace('R$ ', '').replace(',', '').replace(/\./g, ''));

                          if (!isNaN(parsedIptuValor)) {
                              data1.append('city_tax_value', this.state.iptuValor === imovel?.city_tax_value ? imovel?.city_tax_value : parsedIptuValor);
                          }
                        }
                        if (imovel?.fee_marina !== null || this.state.marinhaValor) {
                          const parsedMarinhaValor = parseInt(this.state.marinhaValor?.toString().replace('R$ ', '').replace(',', '').replace(/\./g, ''));
                      
                          if (!isNaN(parsedMarinhaValor)) {
                              data1.append('fee_marina', this.state.marinhaValor === imovel?.fee_marina ? imovel?.fee_marina : parsedMarinhaValor);
                          }
                      }
                        data1.append('real_state_financing', this.state.financiamento ? 1 : 0)
                        data1.append('accept_property', this.state.permutaImovel ? 1 : 0)
                        data1.append('accept_car', this.state.permutaAutomovel ? 1 : 0)
                        if((imovel?.terrain_measures || this.state.areaTerreno) && this.state.areaTerreno){
                        data1.append('terrain_measures', this.state.areaTerreno === imovel?.terrain_measures ? imovel?.terrain_measures : this.state.areaTerreno?.replace(',', '.'))
                        }
                        if((imovel?.endorsed_measures || this.state.areaPrivativa) && this.state.areaPrivativa){
                        data1.append('endorsed_measures', this.state.areaPrivativa === imovel?.endorsed_measures ? imovel?.endorsed_measures: this.state.areaPrivativa?.replace(',', '.'))
                        }
                        if((imovel?.build_measures || this.state.areaConstruida) && this.state.areaConstruida){
                        data1.append('build_measures', this.state.areaConstruida === imovel?.build_measures ? imovel?.build_measures : this.state.areaConstruida?.replace(',', '.'))
                        }
                        data1.append('description', this.state.descricao)
                        data1.append('parking_spots', this.state.nGaragens ? this.state.nGaragens : 0)
                        data1.append('suites_number', this.state.nSuites ? this.state.nSuites : 0)
                        data1.append('semi_suites', this.state.nDemiSuites ? this.state.nDemiSuites : 0)
                        data1.append('bathroom_number', this.state.nBanheiros ? this.state.nBanheiros : 0)
                        data1.append('n_washrooms', this.state.nLavabos ? this.state.nLavabos : 0)
                        data1.append('elevator_number', this.state.nElevadores ? this.state.nElevadores : 0)
                        data1.append('floor_number', this.state.nPavimentos ? this.state.nPavimentos : 0)
                        data1.append('dorms_number', this.state.nQuartos ? this.state.nQuartos : 0)
                        data1.append('tour_360', this.state.tour)
                        data1.append('youtube_video', this.state.video)
                        data1.append('accept_download', this.state.accept_download ? 1 : 0) 

                        data1.append('decorated', this.state.decorado ? 1 : 0)
                        if(localStorage.getItem('access') === 'A'){
                          data1.append('workflow', this.state.workflow)
                          data1.append('workflow_observation', this.state.workflow_observation)
                        }
                        if(localStorage.getItem('access') !== 'A'){
                          data1.append('workflow', 0)
                          data1.append('workflow_observation', this.state.workflow_observation)
                        }

                        if(this.state.oldStatus == 1 && this.state.status == 0){
                            document.getElementById('load').classList.remove('active')
                            this.setState({confirm_both: true})

                        }else{
                        Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' + imovel?.id, data1,
                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                          .then(response => {

                            if(this.state.tags.length > 0){
                              const dataTag = new FormData()
                              dataTag.append('property_id', imovel?.id)
                              for(let i = 0; i < this.state.tags.length; i++){
                                dataTag.append('tags[]', this.state.tags[i])
                              }
                              Axios.post(process.env.REACT_APP_API + '/api/tag-property', dataTag,
                              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                            }

                            const data2 = new FormData()
                            data2.append('responsible_visit_name', this.state.nomeResponsavel)
                            data2.append('responsible_visit_phone', this.state.contatoResponsavel)
                            data2.append('enterprise', this.state.empreendimento)
                            data2.append('cep', this.state.cep)
                            data2.append('city', citySelected.value)
                            data2.append('state', stateSelected.value)
                            data2.append('country', this.state.pais)
                            data2.append('street', this.state.logradouro)
                            data2.append('district', this.state.bairro)
                            data2.append('number', this.state.numero) 
                            data2.append('complement', this.state.complemento)
                            if(this.state.unidade || imovel?.unities){
                              data2.append('unities', this.state.unidade)
                            }
                            if(this.state.andar || imovel?.andar){
                              data2.append('floor', this.state.andar)
                            }
                            Axios.post(process.env.REACT_APP_API + '/api/auth/address-property/' + imovel?.id, data2,
                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                              .then(response => {
                                setTimeout(() => {
                                  this.setState({updateSuccess: true})
                                  document.getElementById('load').classList.remove('active')
                                }, 500);
                                // document.getElementById('load').classList.add('active')
                                const data4 = new FormData()
                                data4.append('features[]', this.state.churrasqueiraCarvao ? 52 : 0)
                                data4.append('features[]', this.state.closet ? 20 : 0)
                                data4.append('features[]', this.state.banheira ? 28 : 0)
                                data4.append('features[]', this.state.piscinaPriv ? 1 : 0)
                                data4.append('features[]', this.state.gasEncanado ? 22 : 0)
                                data4.append('features[]', this.state.empPiscina ? 24 : 0)
                                data4.append('features[]', this.state.empEspacoGourmet ? 72 : 0)
                                data4.append('features[]', this.state.empSalaoFestas ? 15 : 0)
                                data4.append('property_id', response.data.property_id)
                                Axios.post(process.env.REACT_APP_API + '/api/auth/feature-property/' + imovel?.id, data4,
                                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                .then(respose => {
  

                                })
                          })
                        })
                        }}
                      }}
                        >
                        Salvar
                      </Button>}
                    </Col>
                  </Row>
                  <Row>
                    <span style={{width:'100%', height:'1px', backgroundColor:'#cdcdcd', margin:'10px 0'}}></span>
                  </Row>
                  <Row style={{marginTop:'20px'}}>
                    <Col>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTabJustify === "5"
                            })}
                            onClick={() => {
                              this.toggleCustomJustified("5");
                            }}
                          >
                            <span className="d-none d-sm-block">Imóvel</span>
                            <i className="ri-home-2-fill tabMobile"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTabJustify === "7"
                            })}
                            onClick={() => {
                              this.toggleCustomJustified("7");
                            }}
                          >
                            <span className="d-none d-sm-block">Endereço e Emp.</span>
                            <i className="ri-map-pin-2-fill tabMobile"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTabJustify === "8"
                            })}
                            onClick={() => {
                              this.toggleCustomJustified("8");
                            }}
                          >
                            <span className="d-none d-sm-block">Características</span>
                            <i className="ri-list-check tabMobile"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTabJustify === "9"
                            })}
                            onClick={() => {
                              this.toggleCustomJustified("9");
                            }}
                          >
                            <span className="d-none d-sm-block">Fotos</span>
                            <i className="ri-image-2-fill tabMobile"></i>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTabJustify === "10"
                            })}
                            onClick={() => {
                              this.toggleCustomJustified("10");
                            }}
                          >
                            <span className="d-none d-sm-block">Outras mídias</span>
                            <i className="ri-folder-fill tabMobile"></i>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={this.state.activeTabJustify}>
                        <TabPane tabId="5" className="p-3">
                          <Row>
                            <Col sm="12">
                              <CardText>
                                <Card className="cardBody">
                                {(localStorage.getItem("access") === 'C' || localStorage.getItem("access") === 'I') &&
                                    <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a captação</h5></Col>
                                      </Row>
                                      <Row>
                                      
                                        {localStorage.getItem('access') === 'C' ?
                                          <Col md={4}>
                                            <Label className="form-label">Imobiliária captadora</Label>
                                            <p>{selectedRealEstate.label ? selectedRealEstate.label : 'Imóvel sem imobiliária.'}</p>
                                          </Col>
                                        :
                                          <Col md={4}>
                                            <Label className="form-label">Corretor captador</Label>
                                            <Select
                                              value={selectedBroker}
                                              placeholder="Selecione"
                                              noOptionsMessage={(inputValue) => `Sem resultados`}
                                              onChange={(e) => this.setState({ selectedBroker: e })}
                                              options={this.state.selectOptionsBrokerByRealEstate}
                                              classNamePrefix="select2-selection"
                                            />
                                            <div style={{
                                              textDecoration:'underline',
                                              marginTop:'5px',
                                              cursor: 'pointer'
                                            }} onClick={(e) => this.setState({ selectedBroker: {
                                              value: 2353,
                                              label: 'Sem corretor'
                                            } })}>Limpar</div>
                                          </Col>
                                        }

                                        <Col md={4}>
                                          <Label htmlFor="nomeProprietario" className="col-md-12 col-form-label">Nome do proprietário</Label>
                                            <Input type="text" placeholder="Nome" id="nomeProprietario" value={this.state.nomeProprietario} onChange={(e) => this.setState({nomeProprietario: e.target.value})} />
                                        </Col>
                                      </Row>
                                    </Container>
                                  }
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o contrato</h5></Col>
                                    </Row>
                                    {localStorage.getItem('access') === 'A' ?
                                    <Row>
                                      <Col md={4}>
                                        <Label className="form-label">Corretor captador</Label>
                                        <Select
                                          value={selectedBroker}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={(e) => this.setState({ selectedBroker: e })}
                                          options={selectOptionsBroker}
                                          classNamePrefix="select2-selection"
                                        />
                                        <div style={{
                                          textDecoration:'underline',
                                          marginTop:'5px',
                                          cursor: 'pointer'
                                        }} onClick={(e) => this.setState({ selectedBroker: {
                                          value: 2353,
                                          label: 'Sem corretor'
                                        } })}>Limpar</div>
                                      </Col>
                                      <Col md={4}>
                                        <Label className="form-label">Imobiliária captadora</Label>
                                        <Select
                                          value={selectedRealEstate}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={(e) => this.setState({ selectedRealEstate: e })}
                                          options={selectOptionsRealEstate}
                                          classNamePrefix="select2-selection"
                                        />
                                        <div style={{
                                          textDecoration:'underline',
                                          marginTop:'5px',
                                          cursor: 'pointer'
                                        }} onClick={(e) => this.setState({ selectedRealEstate: {
                                          value: 233,
                                          label: 'Sem imobiliária'
                                        } })}>Limpar</div>
                                      </Col>
                                      <Col md={4}>
                                        <Label className="form-label">Construtora</Label>
                                          <Select
                                            value={selectedConstruction}
                                            placeholder="Selecione"
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onChange={(e) => this.setState({ selectedConstruction: e })}
                                            options={selectOptionsConstructions}
                                            classNamePrefix="select2-selection"
                                          />
                                          <div style={{
                                            textDecoration:'underline',
                                            marginTop:'5px',
                                            cursor: 'pointer'
                                          }} onClick={(e) => this.setState({ selectedConstruction: {
                                            value: 339,
                                            label: 'Sem construtora'
                                          } })}>Limpar</div>
                                      </Col>
                                      <Col md={4}>
                                        <Label className="form-label">Proprietário</Label>
                                        <Select
                                          value={selectedOwner}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={(e) => this.setState({ selectedOwner: e })}
                                          options={selectOptionsOwner}
                                          classNamePrefix="select2-selection"
                                        />
                                        <div style={{
                                          textDecoration:'underline',
                                          marginTop:'5px',
                                          cursor: 'pointer'
                                        }} onClick={(e) => this.setState({ selectedOwner: {
                                          value: 339,
                                          label: 'Sem proprietário'
                                        } })}>Limpar</div>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="tipoContrato" className="col-md-12 col-form-label">Contrato</Label>
                                        {/* <Input type="text" defaultValue="placeholder" id="tipoContrato" /> */}
                                        <select className="form-control" value={this.state.tipoContrato} onChange={(e) => this.setState({tipoContrato: e.target.value})}>
                                          <option value="0">Selecione uma opção</option>
                                          <option value="FS Prop">Fast Sale Proprietário</option>
                                          <option value="FS Corr">Fast Sale Corretor</option>
                                          <option value="FS Imob (antigo)">Fast Sale Imobiliária (antigo)</option>
                                          <option value="FS Imob">Fast Sale Imobiliária</option>
                                          <option value="FS Parc">Parceria 50 / 50</option>
                                          <option value="FS Parc (sc)">Parceria 0 / 100</option>
                                          <option value="Construtora">Construtora</option>
                                          <option value="FS Premium">Premium</option>
                                        </select>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="comissao" className="col-md-12 col-form-label">Comissão</Label>
                                          <Input type="number" placeholder="%" id="comissao" value={this.state.comissao}  onChange={(e) => this.setState({comissao: e.target.value})}/>
                                      </Col>
                                    </Row>
                                    :
                                    <>
                                      <Row>
                                        <Col md={4}>
                                          <Label className="col-md-12 col-form-label">Contrato</Label>
                                          <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333'}}>{this.state.tipoContrato}</Label>
                                        </Col>
                                        <Col md={4}>
                                          <Label className="col-md-12 col-form-label">Comissão (%)</Label>
                                          <Input type="number" placeholder="%" id="comissao" value={this.state.comissao}  onChange={(e) => this.setState({comissao: e.target.value})}/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <Card color="dark" className="text-white-50">
                                            <CardBody style={{padding: '0.75rem 0.75rem'}}>
                                                <CardTitle className="text-white" style={{marginBottom:'0'}}><i className="mdi mdi-alert-circle-outline me-3"></i>Atenção</CardTitle>
                                                {
                                                  this.state.tipoContrato === "FS Parc" && 
                                                  <CardText>
                                                    Imóvel de parceria, a comissão é divida 50% para o captador e 50% para o vendedor. A Fast Sale não fica com nenhuma parte da negociação.
                                                  </CardText>
                                                }
                                                {
                                                  (this.state.tipoContrato === "FS Corr" || this.state.tipoContrato === "FS Imob") && 
                                                  <CardText>
                                                    Comissão de 6%, sendo 1% da Fast Sale, 1% para o corretor captador e 4% para o corretor que vender.
                                                  </CardText>
                                                }
                                                {
                                                  this.state.tipoContrato === "Construtora" && 
                                                  <CardText>
                                                    Comissão de 5%, inteiramente para o corretor que vender.
                                                  </CardText>
                                                }
                                                {
                                                  this.state.tipoContrato === "FS Prop" && 
                                                  <CardText>
                                                    Comissão de 6%, sendo 1% da Fast Sale e 5% para o corretor que vender.
                                                  </CardText>
                                                }
                                            </CardBody>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </>
                                    }
                                    
                                  </Container>
                                  {localStorage.getItem("access") === 'A' &&
                                    <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o proprietário</h5></Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          <Label htmlFor="nomeProprietario" className="col-md-12 col-form-label">Nome do proprietário</Label>
                                            <Input type="text" placeholder="Nome" id="nomeProprietario" value={this.state.nomeProprietario} onChange={(e) => this.setState({nomeProprietario: e.target.value})} />
                                        </Col>
                                          <Col md={4}>
                                            <Label htmlFor="emailProprietario" className="col-md-12 col-form-label">E-mail do proprietário</Label>
                                              <Input type="email" placeholder="E-mail" id="emailProprietario" value={this.state.emailProprietario} onChange={(e) => this.setState({emailProprietario: e.target.value})} />
                                          </Col>
                                          <Col md={4}>
                                            <Label htmlFor="phoneProprietario" className="col-md-12 col-form-label">Telefone do proprietário</Label>
                                            <Input type="text" value={this.state.telefoneProprietario} onChange={(e) => this.setState({telefoneProprietario: e.target.value})} />
                                          </Col>
                                      </Row>
                                    </Container>
                                  }
                                  {localStorage.getItem("access") === 'A' &&
                                    <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o corretor</h5></Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          <Label htmlFor="nomeProprietario" className="col-md-12 col-form-label">Nome do corretor</Label>
                                            <Input type="text" placeholder="Nome" id="nomeProprietario" value={this.state.nomeCorretor} onChange={(e) => this.setState({nomeCorretor: e.target.value})} />
                                        </Col>
                                          <Col md={4}>
                                            <Label htmlFor="emailProprietario" className="col-md-12 col-form-label">E-mail do corretor</Label>
                                              <Input type="email" placeholder="E-mail" id="emailProprietario" value={this.state.emailCorretor} onChange={(e) => this.setState({emailCorretor: e.target.value})} />
                                          </Col>
                                          <Col md={4}>
                                            <Label htmlFor="phoneProprietario" className="col-md-12 col-form-label">Telefone do corretor</Label>
                                            <Input type="text" value={this.state.telefoneCorretor} onChange={(e) => this.setState({telefoneCorretor: e.target.value})} />
                                          </Col>
                                      </Row>
                                    </Container>
                                  }
                                  {localStorage.getItem("access") === 'A' &&
                                    <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre a imobiliária</h5></Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          <Label htmlFor="nomeProprietario" className="col-md-12 col-form-label">Nome da imobiliária</Label>
                                            <Input type="text" placeholder="Nome" id="nomeProprietario" value={this.state.nomeImobiliaria} onChange={(e) => this.setState({nomeImobiliaria: e.target.value})} />
                                        </Col>
                                          <Col md={4}>
                                            <Label htmlFor="emailProprietario" className="col-md-12 col-form-label">E-mail da imobiliária</Label>
                                              <Input type="email" placeholder="E-mail" id="emailProprietario" value={this.state.emailImobiliaria} onChange={(e) => this.setState({emailImobiliaria: e.target.value})} />
                                          </Col>
                                          <Col md={4}>
                                            <Label htmlFor="phoneProprietario" className="col-md-12 col-form-label">Telefone da imobiliária</Label>
                                            <Input type="text" value={this.state.telefoneImobiliaria} onChange={(e) => this.setState({telefoneImobiliaria: e.target.value})} />
                                          </Col>
                                      </Row>
                                    </Container>
                                  }
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Sobre o imóvel</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Label htmlFor="tipoImovel" className="col-md-12 col-form-label">Tipo do imóvel</Label>
                                        <select className="form-control" value={this.state.tipoImovel} onChange={(e) => this.setState({tipoImovel: e.target.value})}>
                                          <option value="36">Escolha uma opção</option>
                                          <option value="12">Apartamento</option>
                                          <option value="14">Casa</option>
                                          <option value="34">Comercial</option>
                                          <option value="22">Terreno</option>
                                          <option value="35">Outros (Sítio, Fazenda, Área Logística, ...)</option>
                                        </select>
                                      </Col>
                                      <Col md={8}>
                                        <Label htmlFor="tituloImovel" className="col-md-12 col-form-label">Título do imóvel</Label>
                                        <Input
                                          type="text"
                                          maxLength="100"
                                          value={this.state.title}
                                          onChange={(e) => {
                                            this.optionchange(e)
                                            this.setState({title: e.target.value})
                                          }}
                                          placeholder="Título do imóvel"
                                          name="alloptions"
                                          id="alloptions"
                                        />
                                        {this.state.disbadge ? (
                                          <span className={this.state.advanceclass}>
                                            <span className="badge badge-success">Você digitou {this.state.optioncount} de 100 caracteres permitidos.</span>
                                          </span>
                                        ) : null}
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="matriculaImovel" className="col-md-12 col-form-label">Matrícula</Label>
                                        <Input type="text" placeholder="Matrícula" id="matriculaImovel" value={this.state.matricula} onChange={(e) => this.setState({matricula: e.target.value})} />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="registroImovel" className="col-md-12 col-form-label">Registro Imob.</Label>
                                        <Input type="text" placeholder="Registro imobiliário" id="registroImovel" value={this.state.registro} onChange={(e) => this.setState({registro: e.target.value})} />
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="condominio" className="col-md-12 col-form-label">Condomínio</Label>
                                        <CurrencyInput value={this.state.condominioValor} defaultValue={this.state.condominioValor} onChange={(e) => this.setState({condominioValor: e.target.value})}/>
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="iptu" className="col-md-12 col-form-label">IPTU</Label>
                                        <CurrencyInput value={this.state.iptuValor} defaultValue={this.state.iptuValor} onChange={(e) => this.setState({iptuValor: e.target.value})}/>
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="marinha" className="col-md-12 col-form-label">Tx. marinha</Label>
                                        <CurrencyInput value={this.state.marinhaValor} defaultValue={this.state.marinhaValor} onChange={(e) => this.setState({marinhaValor: e.target.value})}/>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Medidas</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Label htmlFor="areaTerreno" className="col-md-12 col-form-label">Área do terreno</Label>
                                          <Input type="number" placeholder="em m²" id="areaTerreno"  value={this.state.areaTerreno} onChange={(e) => this.setState({areaTerreno: e.target.value})}/>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="areaConstruida" className="col-md-12 col-form-label">Área construída</Label>
                                          <Input type="number" placeholder="em m²" id="areaConstruida"  value={this.state.areaConstruida} onChange={(e) => this.setState({areaConstruida: e.target.value})}/>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="areaPrivativa" className="col-md-12 col-form-label">Área privativa</Label>
                                        <Input type="number" placeholder="em m²" id="areaPrivativa"  value={this.state.areaPrivativa} onChange={(e) => this.setState({areaPrivativa: e.target.value})}/>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Valor</h5></Col>
                                      </Row>
                                      <Row>
                                        <Col md={6}>
                                          <Label htmlFor="iptu" className="col-md-12 col-form-label">Valor</Label>
                                          <CurrencyInput value={this.state.valor} defaultValue={this.state.valor} onChange={(e) => this.setState({valor: e.target.value})}/>
                                        </Col>
                                        {
                                          localStorage.getItem('access') === 'A' ? (
                                            <Col md={6}>
                                              <Label htmlFor="marinha" className="col-md-12 col-form-label">Valor promocional 
                                                <span className='valor-promocial-obs'> (Caso não queira exibir valor promocional, coloque o valor 0)</span>
                                              </Label>
                                              <CurrencyInput value={this.state.valorPromocional} defaultValue={this.state.valorPromocional} onChange={(e) => this.setState({valorPromocional: e.target.value})}/>
                                            </Col>
                                          ) : (
                                            <></>
                                          )
                                        }

                                      </Row>
                                    </Container>
                                    <Container>
                                      <Row style={{margin:'10px 0'}}>
                                        <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Condições</h5></Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="financiamento" onClick={(e) =>{
                                            this.setState({financiamento: e.target.checked})
                                          }} />
                                          <Label className="form-check-label" htmlFor="financiamento">
                                              Aceita financiamento?
                                          </Label>
                                        </Col>
                                        <Col md={4}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="permutaImovel"  onClick={(e) =>{
                                            this.setState({permutaImovel: e.target.checked})
                                          }}/>
                                          <Label className="form-check-label" htmlFor="permutaImovel">
                                              Aceita permuta imóvel?
                                          </Label>
                                        </Col>
                                        <Col md={4}>
                                          <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="permutaAutomovel"  onClick={(e) =>{
                                            this.setState({permutaAutomovel: e.target.checked})
                                          }}/>
                                          <Label className="form-check-label" htmlFor="permutaAutomovel">
                                              Aceita permuta automóvel?
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Descrição do imóvel</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <Label htmlFor="descricaoImovel" className="col-md-12 col-form-label">Descrição</Label>
                                        <textarea className="form-control" placeholder="Descreva seu imóvel com o máximo de informações e detalhes que acredite ser importantes." rows="10"
                                        value={this.state.descricao} onChange={(e) => this.setState({descricao: e.target.value})}></textarea>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card>
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="7" className="p-3">
                          <Row>
                            <Col sm="12">
                              <CardText>
                                <Card className="cardBody">
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Endereço</h5></Col>
                                    </Row>
                                    {localStorage.getItem('access') === 'A' ?
                                    <Row>
                                      <Col md={4}>
                                        <Label htmlFor="cep" className="col-md-12 col-form-label">Cep</Label>
                                          <Input type="text" placeholder="Cep" id="cep" value={this.state.cep} onChange={(e) => {
                                            this.setState({cep: e.target.value})
                                            if (e.target.value?.length !== 8) {
                                              this.setState({logradouro: ''});
                                              this.setState({bairro: ''});
                                              this.setState({stateSelected: ''})
                                              this.setState({citySelected: ''});
                                            }else{
                                              fetch('https://viacep.com.br/ws/' + e.target.value + '/json/')
                                                .then((res) => res.json())
                                                .then((data) => {
                                                    if (data.erro === true) {
                                                      this.setState({logradouro: ''});
                                                      this.setState({bairro: ''});
                                                      this.setState({stateSelected: ''})
                                                      this.setState({citySelected: ''});
                                                    } else {
                                                      this.setState({logradouro: data.logradouro});
                                                      this.setState({bairro: data.bairro});
                                                      this.setState({stateSelected: {
                                                        value:data.uf,
                                                        label:data.uf
                                                      }})
                                                      this.setState({citySelected: {
                                                        value:data.localidade,
                                                        label:data.localidade
                                                      }});
                                                    }
                                                  })
                                                }
                                            }} />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="rua" className="col-md-12 col-form-label">Rua</Label>
                                          <Input type="text" placeholder="Rua, Avenida..." id="rua"  value={this.state.logradouro} onChange={(e) => this.setState({logradouro: e.target.value})}/>
                                      </Col>
                                      <Col md={2}>
                                        <Label htmlFor="numero" className="col-md-12 col-form-label">Número</Label>
                                          <Input type="text" placeholder="Número" id="numero" value={this.state.numero} onChange={(e) => this.setState({numero: e.target.value})}/>
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="bairro" className="col-md-12 col-form-label">Bairro</Label>
                                          <Input type="text" placeholder="Bairro" id="bairro" value={this.state.bairro} onChange={(e) => this.setState({bairro: e.target.value})} />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="complemento" className="col-md-12 col-form-label">Complemento</Label>
                                          <Input type="text" placeholder="Complemento" id="complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                                      </Col>
                                      <Col md={6}>
                                        <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Nome do Empreendimento</Label>
                                          <Input type="text" placeholder="Nome do emprendimento" id="nomeEmpreendimento"  value={this.state.empreendimento} onChange={(e) => this.setState({empreendimento: e.target.value})}/>
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="unidade" className="col-md-12 col-form-label">Unidade <small>(nunca é divulgado)</small></Label>
                                        <Input type="text" placeholder="Unidade apto." id="unidade" value={this.state.unidade} onChange={(e) => this.setState({unidade: e.target.value})} />
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="andar" className="col-md-12 col-form-label">Andar <small>(nunca é divulgado)</small></Label>
                                        <Input type="text" placeholder="Andar apto." id="andar" value={this.state.andar} onChange={(e) => this.setState({andar: e.target.value})} />
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="estado" className="col-md-12 col-form-label">Estado</Label>
                                        <Select
                                          value={stateSelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={this.handleSelectState}
                                          options={statesAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                          {/* <Input type="text" placeholder="Estado" id="estado" /> */}
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="cidade" className="col-md-12 col-form-label">Cidade</Label>
                                        <Select
                                          value={citySelected}
                                          placeholder="Selecione"
                                          noOptionsMessage={(inputValue) => `Sem resultados`}
                                          onChange={(e) => this.setState({ citySelected: e })}
                                          options={cityAddress}
                                          classNamePrefix="select2-selection"
                                        />
                                          {/* <Input type="text" placeholder="Cidade" id="cidade" /> */}
                                      </Col>
                                      <Col md={4}>
                                        <Label htmlFor="pais" className="col-md-12 col-form-label">País</Label>
                                          <Input type="text" placeholder="País" id="pais" value={this.state.pais} onChange={(e) => this.setState({pais: e.target.value})}/>
                                      </Col>
                                    </Row>
                                    :
                                    <Row>
                                      <Col md={4}>
                                        <Label className="col-md-12 col-form-label">Cep</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.cep}</span></Label>
                                      </Col>
                                      <Col md={6}>
                                        <Label className="col-md-12 col-form-label">Rua</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.logradouro}</span></Label>
                                      </Col>
                                      <Col md={2}>
                                        <Label className="col-md-12 col-form-label">Número</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.numero}</span></Label>
                                      </Col>
                                      <Col md={6}>
                                        <Label className="col-md-12 col-form-label">Bairro</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.bairro}</span></Label>
                                      </Col>
                                      <Col md={6}>
                                        <Label className="col-md-12 col-form-label">Complemento</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.complemento}</span></Label>
                                      </Col>
                                      <Col md={4}>
                                        <Label className="col-md-12 col-form-label">Estado</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.stateSelected.label}</span></Label>
                                      </Col>
                                      <Col md={4}>
                                        <Label className="col-md-12 col-form-label">Cidade</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.citySelected.label}</span></Label>
                                      </Col>
                                      <Col md={4}>
                                        <Label className="col-md-12 col-form-label">País</Label>
                                        <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.pais}</span></Label>
                                      </Col>
                                      {/* {this.state.empreendimento && */}
                                        <Col md={6}>
                                          <Label className="col-md-12 col-form-label">Nome do Empreendimento</Label>
                                          <Input type="text" placeholder="Nome do emprendimento" id="nomeEmpreendimento"  value={this.state.empreendimento} onChange={(e) => this.setState({empreendimento: e.target.value})}/>
                                        </Col>
                                      {/* } */}
                                      {this.state.unidade &&
                                        <Col md={3}>
                                          <Label className="col-md-12 col-form-label">Unidade <small>(nunca é divulgado)</small></Label>
                                          <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.unidade}</span></Label>
                                        </Col>
                                      }
                                      {this.state.andar &&
                                        <Col md={3}>
                                          <Label className="col-md-12 col-form-label">Andar <small>(nunca é divulgado)</small></Label>
                                          <Label className="col-md-12 col-form-label" style={{fontSize:'16px', color:'#333', backgroundColor:"#f4f4f4"}}><span style={{padding:'5px', display: 'block'}}>{this.state.andar}</span></Label>
                                        </Col>
                                      }
                                    </Row>
                                    }
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características do empreendimento</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empEspacoGourmet"  onClick={(e) =>{
                                            this.setState({empEspacoGourmet: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="empEspacoGourmet">
                                            Espaço Gourmet
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empPiscina"  onClick={(e) =>{
                                            this.setState({empPiscina: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="empPiscina">
                                            Piscina
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="empSalaoFestas"  onClick={(e) =>{
                                            this.setState({empSalaoFestas: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="empSalaoFestas">
                                            Salão de Festas
                                        </Label>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card>
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="8" className="p-3">
                          <Row>
                            <Col sm="12">
                              <CardText>
                                <Card className="cardBody">
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Características principais</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={3}>
                                        <Label htmlFor="nGaragens" className="col-md-12 col-form-label">Garagens</Label>
                                          <Input type="number" placeholder="Nº de garagens" id="nGaragens"  value={this.state.nGaragens} onChange={(e) => this.setState({nGaragens: e.target.value})}/>
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="nQuartos" className="col-md-12 col-form-label">Quartos</Label>
                                          <Input type="number" placeholder="Nº de quartos" id="nQuartos"  value={this.state.nQuartos} onChange={(e) => this.setState({nQuartos: e.target.value})}/>
                                      </Col>
                                      <Col md={3}>
                                        <Label htmlFor="nSuites" className="col-md-12 col-form-label">Suítes</Label>
                                          <Input type="number" placeholder="Nº de suítes" id="nSuites"  value={this.state.nSuites} onChange={(e) => this.setState({nSuites: e.target.value})}/>
                                      </Col>
                                      {localStorage.getItem('access') === 'A' &&
                                        <Col md={3}>
                                          <Label htmlFor="nDemiSuites" className="col-md-12 col-form-label">Demi Suítes</Label>
                                            <Input type="number" placeholder="Nº de demi suítes" id="nDemiSuites"  value={this.state.nDemiSuites} onChange={(e) => this.setState({nDemiSuites: e.target.value})}/>
                                        </Col>
                                      }
                                      <Col md={3}>
                                        <Label htmlFor="nBanheiros" className="col-md-12 col-form-label">Banheiros</Label>
                                          <Input type="number" placeholder="Nº de banheiros" id="nBanheiros"  value={this.state.nBanheiros} onChange={(e) => this.setState({nBanheiros: e.target.value})}/>
                                      </Col>
                                      {localStorage.getItem('access') === 'A' &&
                                        <>
                                          <Col md={3}>
                                            <Label htmlFor="nLavabos" className="col-md-12 col-form-label">Lavabos</Label>
                                              <Input type="number" placeholder="Nº de lavabos" id="nLavabos"  value={this.state.nLavabos} onChange={(e) => this.setState({nLavabos: e.target.value})}/>
                                          </Col>
                                          <Col md={3}>
                                            <Label htmlFor="nElevadores" className="col-md-12 col-form-label">Elevadores</Label>
                                              <Input type="number" placeholder="Nº de elevadores" id="nElevadores"  value={this.state.nElevadores} onChange={(e) => this.setState({nElevadores: e.target.value})}/>
                                          </Col>
                                          <Col md={3}>
                                            <Label htmlFor="nPavimentos" className="col-md-12 col-form-label">Pavimentos</Label>
                                              <Input type="number" placeholder="Nº de pavimentos" id="nPavimentos"  value={this.state.nPavimentos} onChange={(e) => this.setState({nPavimentos: e.target.value})}/>
                                          </Col>
                                        </>
                                      }
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Outras características e tags (palavras chave)</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <CreatableSelect
                                            value={selectedOption}
                                            onChange={this.handleChange}
                                            options={tagsOptions}
                                            formatCreateLabel={(inputValue) => `Criar nova tag: ${inputValue}`}
                                            noOptionsMessage={(inputValue) => `Sem resultados`}
                                            onInputChange={(inputValue) => {
                                                this.loadOptions(inputValue, (newOptions) => {
                                                    this.setState({ tagsOptions: newOptions });
                                                });
                                            }}
                                            onCreateOption={this.handleCreate}
                                            isClearable={true}
                                            isMulti={true}
                                            placeholder="Busque uma tag ou crie uma nova"
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col style={{display:'flex', gap:'8px', margin:'15px 0'}}>
                                            {imovel?.tag?.map((tag, index) => (
                                              <Badge style={{color:'#333', backgroundColor:'#f4f4f4', fontSize:'14px', display:'flex', alignItems:'center', gap:'5px', cursor:'pointer'}} onClick={(e) => {
                                                Axios.post(process.env.REACT_APP_API + '/api/tag-property-remove/', {tag_id: tag.tags?.id, property_id: imovel?.id},
                                                {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                .then(response => {
                                                  e.target.style.display = 'none'
                                                })
                                              }}><i className="ri-close-fill" style={{cursor:'none'}}></i>{tag.tags?.name}</Badge>
                                            ))}
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Outras características</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="banheira"  onClick={(e) =>{
                                            this.setState({banheira: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="banheira">
                                        Banheira
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="churrasqueiraCarvao"  onClick={(e) =>{
                                            this.setState({churrasqueiraCarvao: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="churrasqueiraCarvao">
                                        Churrasqueira à Carvão
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="closet"  onClick={(e) =>{
                                            this.setState({closet: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="closet">
                                        Closet
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="decorado"  onClick={(e) =>{
                                            this.setState({decorado: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="decorado">
                                        Decorado
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="gasEncanado"  onClick={(e) =>{
                                            this.setState({gasEncanado: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="gasEncanado">
                                        Gás Encanado
                                        </Label>
                                      </Col>
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" value="" id="piscinaPriv"  onClick={(e) =>{
                                            this.setState({piscinaPriv: e.target.checked})
                                          }}/>
                                        <Label className="form-check-label" htmlFor="piscinaPriv">
                                        Piscina Privativa
                                        </Label>
                                      </Col>
                                    </Row>
                                  </Container>  
                                </Card> 
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="9" className="p-3">
                          <Row>
                            <Col sm="12">
                              <CardText>
                                  <Container>
                                    <Form>
                                    {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                      <Dropzone
                                        onDrop={acceptedFiles =>
                                          this.handleAcceptedFiles(acceptedFiles)
                                        }
                                        accept={{
                                          "image/*": [".png", ".jpeg", ".jpg", '.heic'],
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                              </div>
                                              <h4>Para adicionar fotos arraste ou clique aqui.</h4>
                                              <small>Tamanho máximo do arquivo de 8 mb.</small>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      }
                                      <Col md={4}>
                                        <Input className="form-check-input" style={{marginRight:'10px'}} type="checkbox" id="accept_download"
                                        value={this.state.accept_download} onClick={(e) => this.setState({accept_download : e.target.checked})}
                                        />
                                        <Label className="form-check-label" htmlFor="accept_download">
                                          Permitir que outros corretores façam o download das fotos e anunciem.
                                        </Label>
                                      </Col>
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                          {this.state.selectedFiles.sort((c1, c2) => (c1.order > c2.order) ? 1 : -1).map((f, i) => 
                                              <Col key={i + "-file"} >
                                              <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              >
                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                      <Col className="col-auto">
                                                        <img
                                                          data-dz-thumbnail=""
                                                          height="120"
                                                          className="avatar-sm rounded bg-light"
                                                          alt={f.name}
                                                          src={f.preview}
                                                        />
                                                      </Col>
                                                      <Col>
                                                        <Link
                                                          to="#"
                                                          className="text-muted fw-bold"
                                                        >
                                                          {f.name}
                                                        </Link>
                                                        <p className="mb-0">
                                                          <strong>{f.formattedSize}</strong>
                                                          {this.state.errors[i] ? (
                                                              <div style={{ color: 'red' }}>{this.state.errors[i].message}</div>
                                                          ) : (
                                                              <Progress value={this.state.progressBars[i]}/>
                                                          )}
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                </div>
                                              </Card>
                                              </Col>
                                          )}
                                      </div>
                                    </Form>

                                  </Container>
                                  {window.screen.width > 768 ?
                                    imovel?.photos?.length > 0 &&
                                      <Container style={{padding:'0'}}>
                                        <Row style={{margin:'30px 0 10px'}}>
                                          <h3 style={{fontSize:'18px', color:'#3E5E3F'}}>Reordenar fotos</h3>
                                          <Container style={{padding: '15px', marginTop: '15px', backgroundColor:'#f4f4f4'}}>
                                            <Row className="attencion">
                                              <Col className="col-auto">
                                                <i className="ri-error-warning-line" style={{fontSize:'30px'}}></i>
                                              </Col>
                                              <Col>
                                                <p><b>A primeira foto é a foto de capa do imóvel.</b></p>
                                                <p>Recomendamos que a ordem seja conforme uma visita no imóvel, exemplo:</p>
                                                <p>Sala <i className="ri-arrow-right-line"></i> Cozinha <i className="ri-arrow-right-line"></i> Quartos <i className="ri-arrow-right-line"></i> Banheiros <i className="ri-arrow-right-line"></i> Etc</p>
                                              </Col>
                                            </Row>
                                          </Container>
                                        </Row>
                                        <PhotosOrder
                                          onFormDataChange={this.handleFormData}
                                          imoveis={imovel?.photos}
                                          count={this.state.count}
                                          deleteImage={this.deleteImage}
                                        />
                                      </Container>
                                    :
                                      <Row style={{margin:'30px 0 10px'}}>
                                        <h3 style={{fontSize:'18px', color:'#3E5E3F'}}>Reordenar fotos</h3>
                                        <div style={{backgroundColor:'#333', width:'100%', padding:'20px', borderRadius:'5px', marginTop:'20px'}}>
                                          <p style={{color:'#fff', textAlign:'center'}}>A reordenação das fotos está disponível apenas na versão para computadores.</p>
                                        </div>
                                      </Row>
                                  }
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="10" className="p-3">
                          <Row>
                            <Col sm="12">
                              <CardText>
                                <Card className="cardBody"> 
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Autorização de venda</h5></Col>
                                    </Row>
                                    {imovel?.auth_image ?
                                      <Row style={{marginBottom:'15px'}}>
                                        <Col className="col-auto">
                                          {imovel?.auth_image?.slice((imovel?.auth_image?.length - 3), imovel?.auth_image?.length) === 'pdf' ?
                                          <div style={{display:'flex', flexDirection:'column', width:'120px', height:'150px', border:'solid 1px #cdcdcd', cursor:'pointer', alignItems:'center', justifyContent:'center'}} onClick={() => {
                                            setTimeout(() => {
                                              window.open(imovel?.auth_image, '_blank')
                                            }, 300);
                                          }}>
                                            <i className="ri-file-search-fill" style={{fontSize:'60px', color:'#333'}}></i>
                                            <p>Visualizar</p>
                                          </div>
                                          :
                                          <div style={{backgroundImage:'url(' +  imovel?.auth_image + ')', backgroundPosition:'center', backgroundSize:'cover', display:'flex', alignItems:'center', justifyContent:'center', width:'120px', height:'150px', cursor:'pointer'}} onClick={() => {
                                            setTimeout(() => {
                                              window.open(imovel?.auth_image, '_blank')
                                            }, 300);
                                          }}>
                                            <i className="ri-zoom-in-line" style={{fontSize:'34px'}}></i>
                                          </div>}
                                          </Col>
                                      </Row>
                                      :
                                      <>Imóvel sem autorização de venda cadastrada.</>
                                    }
                                    <Row style={{marginBottom: '20px'}}>
                                    {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                      <Dropzone
                                        onDrop={acceptedFiles =>
                                          this.handleAcceptedAuth(acceptedFiles)
                                        }
                                        maxFiles={1}
                                        maxSize={50000000}
                                        multiple={false}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                              </div>
                                              <h4>Para adicionar uma autorização arraste ou clique aqui.</h4>
                                              <small>Tamanho máximo do arquivo de 8 mb.</small>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      }
                                      <div
                                          className="dropzone-previews mt-3"
                                          id="auth-previews"
                                        >
                                          {this.state.selectedAuth.map((f, i) => {
                                            return (
                                              <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                              >
                                                <div className="p-2">
                                                  <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Link
                                                        to="#"
                                                        className="text-muted fw-bold"
                                                      >
                                                        {f.name}
                                                      </Link>
                                                      <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                        {this.state.errorsAuth[i] ? (
                                                              <div style={{ color: 'red' }}>{this.state.errorsAuth[i].message}</div>
                                                          ) : (
                                                              <Progress value={this.state.progressBarsAuth[i]}/>
                                                          )}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Card>
                                            );
                                          })}
                                        </div>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Arquivos adicionais <small>*para sua segurança, não envie arquivos que contenham os dados do proprietário do imóvel</small></h5></Col>
                                    </Row>
                                    <Row style={{marginBottom:'15px'}}>
                                      {imovel?.documents?.map((doc, index) => (
                                        <Col key={index} className="col-auto" style={{position:'relative'}}>
                                          <i className="ri-close-fill" style={{
                                            position:'absolute',
                                            top:'8px',
                                            right:'20px',
                                            zIndex:'999',
                                            backgroundColor:'#da2c3e',
                                            width:'20px',
                                            height:'20px',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center',
                                            color:'#fff',
                                            cursor:'pointer',
                                          }} onClick={() => this.handleDeleteDoc(doc.id)}></i>
                                        {doc?.url?.slice((doc?.url?.length - 3), doc?.url?.length) === 'pdf' ?
                                        <div style={{display:'flex', flexDirection:'column', width:'120px', height:'150px', border:'solid 1px #cdcdcd', cursor:'pointer', alignItems:'center', justifyContent:'center'}} onClick={() => {
                                          setTimeout(() => {
                                            window.open(doc?.url, '_blank')
                                          }, 300);
                                        }}>
                                          <i className="ri-file-search-fill" style={{fontSize:'60px', color:'#333'}}></i>
                                          <p>Visualizar</p>

                                        </div>
                                        :
                                        <div style={{backgroundImage:'url(' +  doc?.url + ')', backgroundPosition:'center', backgroundSize:'cover', display:'flex', alignItems:'center', justifyContent:'center', width:'120px', height:'150px', cursor:'pointer'}} onClick={() => {
                                          setTimeout(() => {
                                            window.open(doc?.url, '_blank')
                                          }, 300);
                                        }}>
                                          <i className="ri-zoom-in-line" style={{fontSize:'34px'}}></i>
                                        </div>}
                                        </Col>
                                      ))}
                                    </Row>
                                    <Row style={{marginBottom: '20px'}}>
                                    {!(localStorage.getItem('access') === 'S' && localStorage.getItem('construction_role') === 'view') &&
                                      <Dropzone
                                        onDrop={acceptedFiles =>
                                          this.handleAcceptedDocs(acceptedFiles)
                                        }
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                              </div>
                                              <h4>Para adicionar arquivos arraste ou clique aqui.</h4>
                                              <small>Tamanho máximo do arquivo de 8 mb.</small>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                    }
                                      <div
                                          className="dropzone-previews mt-3"
                                          id="docs-previews"
                                        >
                                          {this.state.selectedDocs.map((f, i) => {
                                            return (
                                              <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                              >
                                                <div className="p-2">
                                                  <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Link
                                                        to="#"
                                                        className="text-muted fw-bold"
                                                      >
                                                        {f.name}
                                                      </Link>
                                                      <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                        {this.state.errorsDoc[i] ? (
                                                            <div style={{ color: 'red' }}>{this.state.errorsDoc[i].message}</div>
                                                        ) : (
                                                            <Progress value={this.state.progressBarsDoc[i]}/>
                                                        )}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Card>
                                            );
                                          })}
                                        </div>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Vídeo (Youtube)</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <Label htmlFor="linkVideo" className="col-md-12 col-form-label">Link vídeo</Label>
                                        <Input type="text" placeholder="Link vídeo" id="linkVideo"   value={this.state.video} onChange={(e) => this.setState({video: e.target.value})}/>
                                      </Col>
                                    </Row>
                                  </Container>
                                  <Container>
                                    <Row style={{margin:'10px 0'}}>
                                      <Col style={{padding:'0'}}><h5 style={{fontSize:'14px', color:'#3E5E3F'}}>Tour 360º</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <Label htmlFor="linkTour" className="col-md-12 col-form-label">Link tour 360º</Label>
                                        <Input type="text" placeholder="Link tour 360º" id="linkTour"   value={this.state.tour} onChange={(e) => this.setState({tour: e.target.value})}/>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card>  
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>      
        </Container>
        <Modal
          isOpen={this.state.modal_center}
          toggle={this.tog_center}
          centered={true}
        >
          <ModalHeader toggle={() => this.setState({ modal_center: false })}>
            {imovel?.workflow === 0 ? <h5>Pendente</h5>
            : imovel?.workflow === 1 ? <h5>Rep. Fotos</h5>
            : imovel?.workflow === 2 ? <h5>Rep. Aut. Venda</h5>
            : imovel?.workflow === 3 ? <h5>Rep. Prop.</h5>
            : imovel?.workflow === 11 ? <h5>Fora padrão FS</h5>
            : imovel?.workflow === 4 ? <h5>Rep. Imóvel</h5>
            : imovel?.workflow === 5 ? <h5>Repetido</h5>
            : imovel?.workflow === 6 ? <h5>Vendido (ext)</h5>
            : imovel?.workflow === 8 ? <h5>Vendido (fs)</h5>
            : imovel?.workflow === 9 ? <h5>Rep. Imóvel</h5>
            : imovel?.workflow === 10 ? <h5>Bloqueado</h5>
            : imovel?.workflow === 13 ? <h5>Pendente atualização</h5>
            : imovel?.workflow === 14 ? <h5>Bloq. atualização</h5>
            : <h5>Aprovado</h5>}
          </ModalHeader>
          <ModalBody>
            <Col style={{padding:'0', marginBottom:'10px'}}><h5 style={{marginBottom:'10px'}}>Observações:</h5></Col>
            <p>{imovel?.workflow_observation === '' || !imovel?.workflow_observation || imovel?.workflow_observation === 'null' ? 'Nenhuma observação referente a este imóvel.' : imovel?.workflow_observation}</p>
            {localStorage.getItem('access') === 'A' &&
            <Row>
              <Col xs={12}>
                <Label>Status</Label>
                <select className="form-control" value={this.state.workflow} onChange={(e) => this.setState({workflow: e.target.value})}>
                  <option value={0}>Cadastro aguardando análise</option>
                  <option value={7}>Aprovado</option>
                  <option value={1}>Fotos reprovadas</option>
                  <option value={2}>Autorização de venda inválida</option>
                  <option value={3}>Informações do proprietário inválidas</option>
                  <option value={4}>Informações do imóvel inválidas</option>
                  <option value={5}>Imóvel já existe na plataforma</option>
                  <option value={11}>Imóvel fora do padrão Fast Sale</option>
                  <option value={8}>Imóvel vendido via Fast Sale</option>
                  <option value={6}>Imóvel vendido (externo)</option>
                  <option value={9}>Contrato cancelado</option>
                  <option value={10}>Bloqueado por falta de pagamento</option>
                  <option value={13}>Pendente atualização</option>
                  <option value={14}>Bloqueado por falta de atualização</option>
                </select>
              </Col>
              <Col xs={12}>
                <Label>Observação</Label>
                <Input type="textarea" value={this.state.workflow_observation} onChange={(e) => this.setState({workflow_observation: e.target.value})} placeholder="Exibido no cadastro no imóvel e também enviado para o captador via e-mail"></Input>
              </Col>
              <Row style={{marginTop:'20px', justifyContent:'flex-end', alignItems:'flex-end'}}>
                <Col className="col-auto">
                  <Button className="btn-light" color="#333" onClick={(e) => {
                    this.setState({ modal_center: false })
                  }}>Fechar</Button>
                </Col>
                <Col className="col-auto">
                  {localStorage.getItem('level') !== '2' &&
                  <Button className="btn-success" onClick={() => {
                      Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' + imovel?.id, {
                        'workflow': this.state.workflow,
                        'workflow_observation': this.state.workflow_observation
                      },
                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                      .then(response => {
                        setTimeout(() => {
                          window.location.reload()
                        }, 300);
                      })
                  }}>Salvar</Button>}
                </Col>
              </Row>
            </Row>
            }
          </ModalBody>
        </Modal>

          {/* Sucesso na atualização */}

          {this.state.update_success ? (
            <SweetAlert
              title="Imóvel atualizado com sucesso!"
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ update_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na atualização */}

          {this.state.update_error ? (
            <SweetAlert
              title="A atualização falhou, tente novamente."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ update_error: false })}
            ></SweetAlert>
            ) : null}

        {
          this.state.uploading ? (
            <div id="load" className="load" style={{display: 'flex', flexDirection: 'column'}}>
              <div className='container-background'>
                <div className='container-uploading'>
                  <div className='container-phrase-uploading'>
                    {(() => {
                        if (this.state.uploadPercentage < 20) {
                          return(
                            <div className='phrase-text'>
                                <img className='upload-image' src={Upload} alt='ícone fazendo um upload' /> 
                                <div className='phrase-details'>
                                  <p>Fazendo upload do material</p><span className='dots'>{this.state.dots}</span>
                                </div>
                            </div>
                          )
                      } else if (this.state.uploadPercentage < 40) {
                          return(
                            <div className='phrase-text'> 
                              <img className='upload-image' src={Corretores} alt='ícone corretores' /> 
                              <div className='phrase-details'>
                                <p>Disponibilizando seu imóvel para milhares de corretores</p><span className='dots'>{this.state.dots}</span>
                              </div>
                            </div>
                          ) 
                      } else if (this.state.uploadPercentage < 60) {
                        return(
                          <div className='phrase-text'>
                            <img className='upload-image' src={Contrato} alt='ícone contrato parceria' />
                            <div className='phrase-details'> 
                              <p>Adicionando seus dados ao contrato de parceria</p><span className='dots'>{this.state.dots}</span>
                            </div>
                          </div>
                        )
                      } else if (this.state.uploadPercentage < 80) {
                        return(
                          <div className='phrase-text'>
                            <img className='upload-image' src={Cafezinho} alt='ícone passando cafezinho' /> 
                            <div className='phrase-details'> 
                              <p>Passando um cafezinho</p><span className='dots'>{this.state.dots}</span>
                            </div>
                          </div>
                        ) 
                      } else if (this.state.uploadPercentage < 100) {
                        return(
                          <div className='phrase-text'>
                              <img className='upload-image' src={Instante} alt='Só um instante' />
                              <div className='phrase-details'> 
                                <p>Só mais um instante</p><span className='dots'>{this.state.dots}</span>
                              </div>
                          </div>
                        ) 
                      } else {
                        return(
                          <div className='phrase-text'>
                            <img className='upload-image' src={Sucesso} alt='ícone de cadastro com successo' />
                            <div className='phrase-details'> 
                              <p>Sucesso! Cadastro de imóvel concluído</p>
                            </div>
                          </div>
                        ) 
                      }
                    })()}
                  </div>
                  <Spinner className="me-2" color="success" style={{ width: '60px',height: '60px', fontSize: '1.3rem', marginTop: '4rem' }}/>
                  <p className='quantify-percentage'>{this.state.uploadPercentage}%</p>
                </div>
              </div>
            </div>
          ) : (
            <div id="load" className="load">
              <Spinner className="me-2" color="success" />
            </div>
          )
        }

        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            customClass="custom-sweetalert"
          >
            <p className="alertText">Você está desativando este imóvel, ele não será mais exibido no catálogo, mas ainda estará cadastrado em sua lista de imóveis e poderá ativá-lo novamente.</p>
            <Label>Qual o motivo para desativá-lo?</Label>
            <select style={{marginTop:'20px'}} className="form-control" value={this.state.reason} onChange={(e) => this.setState({reason: e.target.value})}>
              <option value={null}>Escolha uma das opções</option>
              <option value="O imóvel foi vendido por parceria Fast Sale">O imóvel foi vendido por parceria Fast Sale</option>
              <option value="O imóvel foi vendido de outra forma">O imóvel foi vendido de outra forma</option>
              <option value="O proprietário desistiu da venda">O proprietário desistiu da venda</option>
              <option value="Quero adicionar outros imóveis no lugar">Quero adicionar outros imóveis no lugar</option>
              <option value="Outro">Outro</option>
            </select>
            {this.state.reason === 'Outro' &&
              <Input
                value={this.state.reasonOther}
                style={{marginTop:'20px'}}
                placeholder="Escreva aqui o motivo"
                onChange={(e) => this.setState({reasonOther: e.target.value})}
               ></Input>
            }
            <Row>
              <Col className="col-auto">
                <Button
                  className="btn-lg"
                  color="danger"
                  onClick={() => {
                    this.setState({ confirm_both: false })
                    document.getElementById('load').classList.remove('active')
                  }}>Cancelar</Button>
              </Col>
              <Col className="col-auto">
                {(this.state.reason === 'Outro' && this.state.reasonOther !== '') ||
                (this.state.reason !== '' && this.state.reason !== 'Outro' && this.state.reason) ?
                  <Button
                    className="btn-lg"  
                    onClick={() => {
                      document.getElementById('load').classList.add('active')

                      setTimeout(() => {
                        this.setState({ confirm_both: false })
                      }, 300);

                      const inactive = new FormData()
                      inactive.append('property_id', imovel.id)
                      if(imovel?.broker_id){inactive.append('broker_id', imovel?.broker?.id)}
                      if(imovel?.real_estate){inactive.append('real_estate_id', imovel?.real_estate?.id)}
                      inactive.append('reason', this.state.reason === 'Outro' ? this.state.reasonOther : this.state.reason)
                    
                    Axios.post(process.env.REACT_APP_API + '/api/auth/inativar-imovel', inactive,
                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
        
        
                      const data1 = new FormData()
                      if(localStorage.getItem('access') === 'A'){
                        if(selectedBroker.value){
                          data1.append('broker_id', selectedBroker.value)
                        }
                        if(selectedRealEstate.value){
                          data1.append('real_estate_id', selectedRealEstate.value)
                        }
                        if(selectedOwner.value){
                          data1.append('owner_id', selectedOwner.value)
                        }
                      }

                      data1.append('owner_name', this.state.nomeProprietario)
                      data1.append('title', this.state.title)
        
                     data1.append('status', this.state.status)
        
                      data1.append('contract_type', 'sell')
                      data1.append('sell_contract_type', this.state.tipoContrato)
                      data1.append('commission_percentage_on_sale', (this.state.tipoContrato === "FS Corr" || this.state.tipoContrato === "FS Imob") ? '4' : this.state.tipoContrato === "FS Prop" ? '5' : (this.state.tipoContrato === "FS Parc" || this.state.tipoContrato === "FS Imob (antigo)") ? '3' : this.state.comissao)
                      data1.append('category_id', this.state.tipoImovel)
                      data1.append('registry', this.state.matricula)
                      data1.append('real_estate_registration', this.state.registro)
                      if(imovel?.sale_value || this.state.valor){
                        data1.append('sale_value', this.state.valor === imovel?.sale_value ? imovel?.sale_value : parseInt(this.state.valor?.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
                      }
                      if(imovel?.sell_price || this.state.valorPromocional){
                      data1.append('sell_price', this.state.valorPromocional === imovel?.sell_price ? imovel?.sell_price : parseInt(this.state.valorPromocional?.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
                      }
                      // if(imovel?.building_tax_value || this.state.condominioValor){
                      // data1.append('building_tax_value', this.state.condominioValor === imovel?.building_tax_value ? imovel?.building_tax_value : parseInt(this.state.condominioValor.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
                      // }
                      // if(imovel?.city_tax_value || this.state.iptuValor){
                      // data1.append('city_tax_value', this.state.iptuValor === imovel?.city_tax_value ? imovel?.city_tax_value : parseInt(this.state.iptuValor?.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
                      // }
                      // if(imovel?.fee_marina !== null || this.state.marinhaValor){
                      // data1.append('fee_marina', this.state.marinhaValor === imovel?.fee_marina ? imovel?.fee_marina : parseInt(this.state.marinhaValor?.replace('R$ ', '').replace(',', '').replace(/\./g,'')))
                      // }
                        if (imovel?.building_tax_value || this.state.condominioValor) {
                          const parsedCondominioValor = this.state.condominioValor ? parseInt(this.state.condominioValor?.toString().replace('R$ ', '')?.replace(',', '')?.replace(/\./g, '')) : null;
                      
                          if (!isNaN(parsedCondominioValor)) {
                              data1.append('building_tax_value', this.state.condominioValor === imovel?.building_tax_value ? imovel?.building_tax_value : parsedCondominioValor);
                          }
                        }
                        if(imovel?.city_tax_value || this.state.iptuValor){
                          const parsedIptuValor = parseInt(this.state.iptuValor?.toString().replace('R$ ', '').replace(',', '').replace(/\./g, ''));

                          if (!isNaN(parsedIptuValor)) {
                              data1.append('city_tax_value', this.state.iptuValor === imovel?.city_tax_value ? imovel?.city_tax_value : parsedIptuValor);
                          }
                        }
                        if (imovel?.fee_marina !== null || this.state.marinhaValor) {
                          const parsedMarinhaValor = parseInt(this.state.marinhaValor?.toString().replace('R$ ', '').replace(',', '').replace(/\./g, ''));
                      
                          if (!isNaN(parsedMarinhaValor)) {
                              data1.append('fee_marina', this.state.marinhaValor === imovel?.fee_marina ? imovel?.fee_marina : parsedMarinhaValor);
                          }
                      }
                      data1.append('real_state_financing', this.state.financiamento ? 1 : 0)
                      data1.append('accept_property', this.state.permutaImovel ? 1 : 0)
                      data1.append('accept_car', this.state.permutaAutomovel ? 1 : 0)
                      if((imovel?.terrain_measures || this.state.areaTerreno) && this.state.areaTerreno){
                      data1.append('terrain_measures', this.state.areaTerreno === imovel?.terrain_measures ? imovel?.terrain_measures : this.state.areaTerreno?.replace(',', '.'))
                      }
                      if((imovel?.endorsed_measures || this.state.areaPrivativa) && this.state.areaPrivativa){
                      data1.append('endorsed_measures', this.state.areaPrivativa === imovel?.endorsed_measures ? imovel?.endorsed_measures: this.state.areaPrivativa?.replace(',', '.'))
                      }
                      if((imovel?.build_measures || this.state.areaConstruida) && this.state.areaConstruida){
                      data1.append('build_measures', this.state.areaConstruida === imovel?.build_measures ? imovel?.build_measures : this.state.areaConstruida?.replace(',', '.'))
                      }
                      data1.append('description', this.state.descricao)
                      data1.append('parking_spots', this.state.nGaragens ? this.state.nGaragens : 0)
                      data1.append('suites_number', this.state.nSuites ? this.state.nSuites : 0)
                      data1.append('semi_suites', this.state.nDemiSuites ? this.state.nDemiSuites : 0)
                      data1.append('bathroom_number', this.state.nBanheiros ? this.state.nBanheiros : 0)
                      data1.append('n_washrooms', this.state.nLavabos ? this.state.nLavabos : 0)
                      data1.append('elevator_number', this.state.nElevadores ? this.state.nElevadores : 0)
                      data1.append('floor_number', this.state.nPavimentos ? this.state.nPavimentos : 0)
                      data1.append('dorms_number', this.state.nQuartos ? this.state.nQuartos : 0)
                      data1.append('tour_360', this.state.tour)
                      
                      data1.append('youtube_video', this.state.video)
                      // for(let i = 0; i < this.state.selectedDocs.length; i++){
                      //   data1.append('docs[]', this.state.selectedDocs[i])
                      // }
                      
                      // if(this.state.selectedAuth.length > 0){
                      //   data1.append('auth_photo', this.state.selectedAuth[0])
                      // }
                      data1.append('accept_download', this.state.accept_download ? 1 : 0) 
        
        
                      data1.append('decorated', this.state.decorado ? 1 : 0)
                      if(localStorage.getItem('access') === 'A' && this.state.workflow){
                        data1.append('workflow', this.state.workflow)
                        data1.append('workflow_observation', this.state.workflow_observation)
                      }
                      if(localStorage.getItem('access') !== 'A'){
                          data1.append('workflow', 0)
                        data1.append('workflow_observation', this.state.workflow_observation)
                      }
        
                      Axios.post(process.env.REACT_APP_API + '/api/auth/property-dash/' + imovel?.id, data1,
                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
        
        
                          if(this.state.tags.length > 0){
                            const dataTag = new FormData()
                            dataTag.append('property_id', imovel?.id)
                            for(let i = 0; i < this.state.tags.length; i++){
                              dataTag.append('tags[]', this.state.tags[i])
                            }
                            Axios.post(process.env.REACT_APP_API + '/api/tag-property', dataTag,
                            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                          }
        
        
                          const data2 = new FormData()
                          data2.append('responsible_visit_name', this.state.nomeResponsavel)
                          data2.append('responsible_visit_phone', this.state.contatoResponsavel)
                          data2.append('enterprise', this.state.empreendimento)
                          data2.append('cep', this.state.cep)
                          data2.append('city', citySelected.value)
                          data2.append('state', stateSelected.value)
                          data2.append('country', this.state.pais)
                          data2.append('street', this.state.logradouro)
                          data2.append('district', this.state.bairro)
                          data2.append('number', this.state.numero) 
                          data2.append('complement', this.state.complemento)
                          if(this.state.unidade || imovel?.unities){
                            data2.append('unities', this.state.unidade)
                          }
                          if(this.state.andar || imovel?.andar){
                            data2.append('floor', this.state.andar)
                          }
                          
                          Axios.post(process.env.REACT_APP_API + '/api/auth/address-property/' + imovel?.id, data2,
                          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                            .then(response => {
                          const data4 = new FormData()
                          data4.append('features[]', this.state.churrasqueiraCarvao ? 52 : 0)
                          data4.append('features[]', this.state.closet ? 20 : 0)
                          data4.append('features[]', this.state.banheira ? 28 : 0)
                          data4.append('features[]', this.state.piscinaPriv ? 1 : 0)
                          data4.append('features[]', this.state.gasEncanado ? 22 : 0)
                          data4.append('features[]', this.state.empPiscina ? 24 : 0)
                          data4.append('features[]', this.state.empEspacoGourmet ? 72 : 0)
                          data4.append('features[]', this.state.empSalaoFestas ? 15 : 0)
                          data4.append('property_id', response.data.property_id)
                          Axios.post(process.env.REACT_APP_API + '/api/auth/feature-property/' + imovel?.id, data4,
                          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                          .then(respose => {
        
                            const data5 = new FormData();
        
                            for(let i = 0; i < this.state.selectedFiles.length; i++){
                              data5.append(`photo[${i}][file]`, this.state.selectedFiles[i])
                              data5.append(`photo[${i}][position]`, (i + 100))
                            }
        
                              Axios.post(process.env.REACT_APP_API + '/api/auth/photo-order', this.state.photosOrder,
                              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                              .then(response => {})
                              .catch(response => {})       
        
        
                              let totalSize = 0;
                              this.state.selectedFiles.forEach((file) => {
                                totalSize += file.size;
                              });
        
                              // Tamanho Médio do Arquivo
                              let averageFileSize = parseInt(totalSize / this.state.selectedFiles.length) / 1000;
        
                              const simulateUpload = (averageFileSize) => {
                                return new Promise((resolve) => {
                                  const uploadInterval = setInterval(() => {
                                    this.setState((prevState) => {
                                    this.setState({ uploading: true });
                                      let nextPercentage = prevState.uploadPercentage + 1;
                                      if (nextPercentage >= 99) {
                                        clearInterval(uploadInterval);
                                        resolve(); 
                                        return { uploadPercentage: 99 };
                                      } else {
                                        return { uploadPercentage: nextPercentage };
                                      }
                                    });
                                  }, averageFileSize);
                                });
                              }
        
                              simulateUpload(averageFileSize)
                              .then(() => {
                                Axios.post(
                                  process.env.REACT_APP_API + '/api/auth/property-update-images/' + imovel?.id,
                                  data5,
                                  {
                                    headers: { Authorization: "Bearer " + localStorage.getItem('token') },
                                  }
                                )
                                .then((response) => {
                                  this.setState({ uploadPercentage: 100 });
                                  setTimeout(() => {
                                      this.setState({ update_success: true, uploadPercentage: 100, uploading: false });
                                      document.getElementById('load').classList.remove('active');
                                      setTimeout(() => {
                                          window.location.reload();
                                      }, 2000);
                                  }, 2000);
                              })
                                .catch((response) => {
                                  this.setState({ update_error: true });
                                  document.getElementById('load').classList.remove('active');
                                });
                            });
                          })
                        })
                      })            
                    })
                    }}>Desativar</Button>
                  :
                  <Button disabled className="btn-lg">Desativar</Button>
                }
              </Col>
            </Row>
          </SweetAlert>
        ) : null}


            {this.state.checkAddress ? (
              <SweetAlert
                title="Checagem de endereço"
                warning
                showCloseButton={true}
                showConfirm={true}
                onConfirm={() => this.setState({checkAddress: !this.state.checkAddress})}
              >
                <Row>
                  <Col xs={12}>
                    
                    {imovel.category_id !== 12 ?
                    (
                      this.state.checkAddressProperty?.similar_properties_case_1?.length > 0 ?
                      <span className="checkAddressList" style={{margin:"20px 0"}}>
                        <h5>Imóveis com mesmo endereço</h5>
                        {this.state.checkAddressProperty?.similar_properties_case_1.map((property, index) => (
                            <HorizontalMiniCard key={index} imovel={property}/>
                        ))}
                      </span>
                      :
                      <h5>Nenhum imóvel com mesmo endereço.</h5>
                    )
                    :
                    (
                      this.state.checkAddressProperty?.similar_properties_case_2?.length > 0 ?
                      <span className="checkAddressList" style={{margin:"20px 0"}}>
                        <h5>Imóveis com mesmo endereço e unidade</h5>
                        {this.state.checkAddressProperty?.similar_properties_case_2.map((property, index) => (
                          <HorizontalMiniCard key={index} imovel={property}/>
                        ))}
                      </span>
                      :
                      <h5>Nenhum imóvel com o endereço e unidade.</h5>
                    )
                    }
                    
                    {/* {this.state.checkAddressProperty?.similar_properties_case_3?.length > 0 ?
                    <div className="checkAddressList" style={{margin:"20px 0"}}>
                      <h5>Imóveis no mesmo empreendimento</h5>
                      {this.state.checkAddressProperty?.similar_properties_case_3.map((property, index) => (
                        <HorizontalMiniCard key={index} imovel={property}/>
                      ))}
                    </div>
                    :
                    <h5>Nenhum imóvel no mesmo empreendimento.</h5>
                    } */}
                    {/* {(this.state.checkAddressProperty?.similar_properties_case_1?.length === 0
                    && this.state.checkAddressProperty?.similar_properties_case_2?.length === 0
                    &&this.state.checkAddressProperty?.similar_properties_case_3?.length === 0) &&
                      <h5>Nenhum imóvel com endereço similar encontrado.</h5>
                    } */}
                  </Col>
                </Row>
              </SweetAlert>
            ) : null}

            {/* Sucesso na atualização */}

            {this.state.deleteSuccess ? (
              <SweetAlert
                title="Imagem excluída"
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => this.setState({deleteSuccess: !this.state.deleteSuccess})}
              ></SweetAlert>
              ) : null}

            {/* Falha na atualização */}

            {this.state.deleteError ? (
              <SweetAlert
                title="A exclusão falhou, tente novamente."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => this.setState({deleteError: !this.state.deleteError})}
              ></SweetAlert>
              ) : null}

                          {/* Sucesso na atualização */}

            {this.state.deleteSuccessDoc ? (
              <SweetAlert
                title="Documento excluído"
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => this.setState({deleteSuccessDoc: !this.state.deleteSuccessDoc})}
              ></SweetAlert>
              ) : null}

            {/* Falha na atualização */}

            {this.state.deleteErrorDoc ? (
              <SweetAlert
                title="A exclusão falhou, tente novamente."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => this.setState({deleteErrorDoc: !this.state.deleteErrorDoc})}
              ></SweetAlert>
              ) : null}


            {this.state.updateSuccess ? (
              <SweetAlert
                title="As edições no imóvel foram salvas"
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => this.setState({updateSuccess: !this.state.updateSuccess})}
              ></SweetAlert>
              ) : null}


            {this.state.updateError ? (
              <SweetAlert
                title="O imóvel não pode ser salvo, verifique as informações e tente novamente"
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => this.setState({updateError: !this.state.updateError})}
              ></SweetAlert>
              ) : null}

            {/* Falha envio de imagem */}

            {this.state.sendImageError ? (
              <SweetAlert
                title="Algumas imagens não puderam ser enviadas."
                timeout={1500}
                showCloseButton={false}
                showConfirm={false}
                error
                onConfirm={() => {
                  document.getElementById('load').classList.remove('active')
                  this.setState({sendImageError: !this.state.sendImageError})
                }}
              ></SweetAlert>
              ) : null}

        {this.state.error_auth ? (
          <SweetAlert
              error
              title='Ops'
              onConfirm={() => this.setState({ error_auth: false })}
          >
              Você não tem permissão para esta ação.
          </SweetAlert>
        ) : null}
      </div>
    );
  }
}

export default editPropertiesFields;