import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import logoDark from "../../../assets/images/logo-dark.png";
import { useEffect, useState } from 'react';
import Axios from 'axios';
import Whats from '../../../components/Whats/Whats';
import { NewPlansV2 } from '../../Home/newPlans/plans';
import { CardPlan } from '../../Home/newPlans/cardPlan';
import { SelectedPlanInfo } from './components/selectedPlanInfo';
import { BannerConecta } from './components/bannerConecta';

export const CheckoutCart = () => {
  const history = useHistory()
  const location = useLocation()
  const [productsList, setProductsList] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [selectedUserType, setSelectedUserType] = useState('');
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [recurrenceOptions, setRecurrenceOptions] = useState([]);
  const [selectedRecurrence, setSelectedRecurrence] = useState('');
  const [planPrice, setPlanPrice] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState('')
  const [notFound, setNotFound] = useState('')
  const [discount, setDiscount] = useState(null)
  const [freeProduct, setFreeProduct] = useState([])
  const [verifyCoupon, setVerifyCoupon] = useState(null)

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API + '/api/public/list-products')
    .then(response => {
      setProductsList(response.data)
      setFreeProduct(response.data.find(product => product.id === 6))

      const uniqueUserTypes = Array.from(
        new Set(response.data.map(product => product.user_type))
      ).map(type => {
        switch(type) {
          case 'C': return { value: 'C', label: 'Corretor' }
          case 'I': return { value: 'I', label: 'Imobiliária' }
          case 'T': return { value: 'T', label: 'Construtora' }
          default: return { value: '', label: '' }
        }
      });

      setUserTypes(uniqueUserTypes)
    })
  }, [])

  const params = new URLSearchParams(location.search);
  const product = params.get('product');
  const recurrence = params.get('recurrence');
  const coupon = params.get('coupon');

  const [count, setCount] = useState(0)

  useEffect(() => {
    if(coupon){
      handleChangeCoupon(coupon)
      setCount(prevState => prevState + 1)
    }
  }, [coupon])

  useEffect(() => {
    if(count === 1){
      handleApply()
    }
  }, [count])

  const handleApply = () => {
    if(selectedCoupon !== '' && selectedCoupon !== 'null' && selectedCoupon !== undefined){
      Axios.get(process.env.REACT_APP_API + '/api/coupon/discount/' + selectedCoupon)
      .then(resp => {
        setDiscount(parseInt(resp.data.discount))
        setVerifyCoupon(parseInt(resp.data.id))

        const currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('coupon', selectedCoupon);
        history.push({
          search: currentUrlParams.toString()
        });
      }).catch(error => {
        if(error.response?.data?.error === 'Coupon not found'){
          setNotFound('Cupom inválido.')
        }
      })
    }
  }


  useEffect(() => {

    if (product && productsList.length > 0) {
      setSelectedUserType(productsList?.find(products => products.id == product).user_type)
      setSelectedPlan(product)
    }

    if (recurrence) {
      setSelectedRecurrence(recurrence);
    }
  }, [location.search, productsList]);


  useEffect(() => {

    if (selectedUserType) {
      const plans = productsList.filter(product => product.user_type == selectedUserType);
      setFilteredPlans(plans);
      setRecurrenceOptions([]);
      setSelectedRecurrence('');
      setPlanPrice(0);
      if(product){
        setSelectedPlan(product);
      }else{
        setSelectedPlan(null);
      }
    } else {
      setFilteredPlans([]);
    }
  }, [selectedUserType, productsList]);

  useEffect(() => {
    if (selectedPlan) {
      const plan = productsList?.find(product => product.id == selectedPlan);
      const options = [];
      if (plan.recurrence_month) options.push({ value: 'month', label: 'Mensal', multiplier: 1 });
      if (plan.recurrence_third) options.push({ value: 'third', label: 'Trimestral', multiplier: 3 });
      if (plan.recurrence_six) options.push({ value: 'six', label: 'Semestral', multiplier: 6 });
      if (plan.recurrence_year) options.push({ value: 'year', label: 'Anual', multiplier: 12 });
      setRecurrenceOptions(options);

      setPlanPrice(plan.price);
    } else {
      setRecurrenceOptions([]);
      setPlanPrice(0);
    }

    if (recurrence) {
      setSelectedRecurrence(recurrence);
    }else{
      setSelectedRecurrence('');
    }
  }, [selectedPlan, productsList]);

  useEffect(() => {
    if (selectedRecurrence) {
      const option = recurrenceOptions.find(option => option.value === selectedRecurrence);
      if (option) {
        const plan = productsList.find(product => product.id == selectedPlan);
        setPlanPrice(plan.price * option.multiplier);
      }
    } else {
      setPlanPrice(0);
    }
  }, [selectedRecurrence, recurrenceOptions, selectedPlan, productsList]);

//   useEffect(() => {
//     if(discount && verifyCoupon){
//       onApplyCoupon(verifyCoupon)
//     }
// }, [discount, verifyCoupon])

const handleRemoveCoupon = () => {
  setSelectedCoupon('')
  setDiscount(null)
  setVerifyCoupon(null)
  setNotFound('')

  const currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.delete('coupon');
  history.push({
    search: currentUrlParams.toString()
  });
}

const handleChangeCoupon = (e) => {
  setSelectedCoupon(e)
  setNotFound('')
}

const productPrice = productsList.find(products => products.id == selectedPlan)?.price * 12;
const productDiscountPrice = ((productsList.find(products => products.id == selectedPlan)?.price) * (parseFloat(productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10000)));
const productDiscountedPrice = ((productsList.find(products => products.id == selectedPlan)?.price) - (productsList.find(products => products.id == selectedPlan)?.price) * (parseFloat(productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10000)));
const productPriceMonth = parseFloat(productsList.find(products => products.id == selectedPlan)?.price);
const productParcels = (((parseFloat(productsList.find(products => products.id == selectedPlan)?.price) * 12) / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 100)) - 0.6) / 12);

  return(
    productsList && productsList.length > 0 &&
    <div className="checkout-cart-section">
      <Container>
        <Row className="top-bar">
          <Col className="col-auto">
            <img src={logoDark}/>
          </Col>
          <Col className="col-auto">
            <span>Checkout Seguro</span>
            <i className="ri-lock-fill"></i>
          </Col>
        </Row>
      </Container>

    {(coupon === 'EVENTOFAST' || coupon === 'eventofast' || coupon === 'EventoFast') &&
      <BannerConecta/>
    }

      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col xs={12}>
                <h3>{(coupon === 'EVENTOFAST' || coupon === 'eventofast' || coupon === 'EventoFast') ? 'Escolha seu plano' : 'Revise as opções do seu plano'}</h3>
              </Col>
              <Col xs={12}>
                <Row className="checkout-cart-item-label">
                  {/* <Col xs={1}></Col> */}
                  <Col xs={4}>Tipo</Col>
                  <Col xs={4}>Plano</Col>
                  <Col xs={4}>Recorrência</Col>
                  {/* <Col xs={2}>Valor</Col> */}
                </Row>
                <Row className="checkout-cart-item">
                  {/* <Col md={1}>
                    <img src=""/>
                  </Col> */}
                  <Col md={4}>
                    <Label>Tipo</Label>
                    <Input type="select" value={selectedUserType} onChange={(e) => setSelectedUserType(e.target.value)}>
                      <option value="">Selecione</option>
                      {userTypes.map(type => (
                        <option key={type.value} value={type.value}>{type.label}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label>Plano</Label>
                    <Input type="select" value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                      <option value="">Selecione</option>
                      {filteredPlans.filter(plans => plans.slug !== 'free').map(plan => (
                        <option key={plan.id} value={plan.id}>{plan.name}</option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label>Recorrência</Label>
                    <Input type="select" value={selectedRecurrence} onChange={(e) => setSelectedRecurrence(e.target.value)}>
                      <option value="">Selecione</option>
                      {recurrenceOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </Input>
                  </Col>
                  {/* <Col md={2} className="value">
                    {(productsList?.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount !== 0 && selectedRecurrence === 'year') ?
                      <>
                        <h6 style={{textDecoration:'line-through'}}>{planPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h6>
                        <h5>{(planPrice / (100 / (productsList?.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h5>
                      </>
                    :
                      <h5>{planPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h5>
                    }
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      <Container>
        <Row className="checkout-cart-values-container">
          <Col md={8}>
            {productsList.filter(plan => plan.id == selectedPlan).map((item, index) => (
              <SelectedPlanInfo key={index} freePlan={freeProduct} item={item} horizontal={true} typeUser={selectedUserType}/>
            ))}
          </Col>
          <Col md={4} className="checkout-cart-value-col">
            <div className="checkout-cart-values">
              {/* {selectedRecurrence === 'year' ?
              <>
                <div className="economy">
                  <span>Economia plano anual</span>
                  <p>{(planPrice - (planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10)))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                </div>
                <p className="atual-price">{(planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                <span>até 12x de {((planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].card_year_discount / 10)) - 0.6) / 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}<br />
                {(productsList.find(products => products.id == selectedPlan)?.product_plans[0].pix_year_discount !== 0 && selectedRecurrence === 'year') &&
                  <span>ou {(planPrice - (planPrice / (100 / (productsList.find(products => products.id == selectedPlan)?.product_plans[0].pix_year_discount / 10)))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} no pix</span>
                }</span>
              </>
              :
              <p className="atual-price">{(planPrice).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
            } */}

              {selectedRecurrence === 'year' ?
                <>
                  {discount ?
                  <>
                    <span style={{textDecoration:'line-through', color:'#888'}}>{productPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                    <span style={{fontSize:'13px', color:'#628adc'}}>-{(productDiscountPrice * 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (desc. plano anual)</span>
                    <span style={{fontSize:'13px', color:'#628adc'}}>-{((productDiscountedPrice * 12) * (discount / 100)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (desc. cupom)</span>
                    <span style={{fontSize:'24px'}}>{((productDiscountedPrice * 12) - ((productDiscountedPrice * 12) * (discount / 100))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                    <span>até 12x de {(productDiscountedPrice - ((productDiscountedPrice) * (discount / 100)))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                  </>
                  :
                  <>
                    <span style={{textDecoration:'line-through', color:'#888'}}>{productPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                    <span style={{fontSize:'13px', color:'#628adc'}}>-{(productDiscountPrice * 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (desc. plano anual)</span>
                    <span style={{fontSize:'24px'}}>{(productDiscountedPrice * 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                    <span>até 12x de {productDiscountedPrice?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                  </>
                  }
                </>
                :
                <>
                 {discount ?
                  <>
                    {selectedRecurrence === 'month' && <span style={{textDecoration:'line-through', color:'#888'}}>{(productPriceMonth)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>}
                    {selectedRecurrence === 'month' && <span style={{fontSize:'13px'}}>-{discount ? (((discount / 100) * productPriceMonth))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : productPriceMonth?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (Cupom)</span>}
                    {selectedRecurrence === 'month' && <span style={{fontSize:'22px'}}>{discount ? (productPriceMonth - ((discount / 100) * productPriceMonth))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : productPriceMonth?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>}
                  </>
                  :
                  selectedRecurrence === 'month' && <span style={{fontSize:'22px'}}>{parseFloat(productsList.find(products => products.id == selectedPlan)?.price)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                  }
                </>
              }

              <div className="coupon-container">
                <label>Cupom de desconto</label>
                <div className="coupon">
                  <input value={selectedCoupon} onChange={(e) => handleChangeCoupon(e.target.value)}/>
                  <span onClick={handleApply}>{discount > 0 ? 'Aplicado' : 'Aplicar'}</span>
                </div>
                {discount ? <span className="remove-coupon" onClick={handleRemoveCoupon}>Remover cupom</span> : <></>}
                <p className="coupon-error">{notFound}</p>
              </div>

              {discount && selectedRecurrence === 'month' && <small>{`O desconto de ${discount}% aplicado por meio do cupom é válido exclusivamente para a primeira mensalidade do plano escolhido e não se aplica às mensalidades recorrentes. `}<b>Para garantir o desconto em todos os meses, adquira o plano anual.</b></small>}

              <button onClick={() => {
                history.push(`/novo-checkout?product=${selectedPlan}&recurrence=${selectedRecurrence}${coupon ? '&coupon=' + coupon : ''}`)
              }}>Ir para pagamento</button>
            </div>
          </Col>
        </Row>
      </Container>
      <Whats/>
    </div>
  )
}