import React, { useState } from 'react';

export const CardPlan = ({item, horizontal = false,  totalBrokers = 0, properties = 0}) => {
  const [activePlan, setActivePlan] = useState('year')
  
  const recorrenceLabel = [
    {label:'Mês', label2:'Plano mensal', value:'month'},
    {label:'Trimestre', label2:'Plano trimestral', value:'third'},
    {label:'Semestre', label2:'Plano semestral', value:'six'},
    {label:'Ano', label2:'Plano anual', value:'year'},
  ]

  const [planActive, setPlanActive] = useState({});

  const togglePlanDetails = (slug) => {
    setPlanActive(prevState => ({
      ...prevState,
      [slug]: !prevState[slug]
    }));
  };

  const formattedValue = (prop) => {
    return parseFloat(prop / 100000000000).toLocaleString("pt-BR", { maximumSignificantDigits: 2 }).toLocaleString("pt-BR", { minimumSignificantDigits: 1 });
  } 

  return(
    <div className={`home-plan-content ${item.slug} ${horizontal ? 'horizontal' : ''}`}>
      <span className={`recomended ${item.slug}`}>Mais popular</span>
      <div className={`home-plans-card ${item.slug}`}>
        <div className="home-plans-rec">
          {item.recurrences.map((rec, i) => (
            <div
              className={`home-plans-rec-option ${activePlan === rec ? 'active' : ''}`}
              onClick={() => setActivePlan(rec)}
            >{recorrenceLabel.find(recs => recs.value === rec)?.label}</div>
          ))}
        </div>
        <div className="home-plans-title">
          <h3>{item.name}</h3>
        </div>
        <div className="home-plans-subtitle">
          <p>{item.description?.replace('totalBrokers', totalBrokers).replace('totalProperties', formattedValue(properties))}</p>
        </div>
        <div className="home-plans-selected">
          <p>{recorrenceLabel.find(recs => recs.value === activePlan)?.label2}</p>
        </div>
        {(activePlan === 'year' && item.slug !== 'free') &&
          <div className="home-plans-selected-year">
            <p>R$ {item.slug === 'free' ? item.price.replace('.', ',') : item.price.replace('.', ',')}</p>
            <span>Economize {parseInt(item.product_plans[0].card_year_discount / 100)}%</span>
          </div>
        }
        <div className="home-plans-price">
          {activePlan === 'year' ?
            <h3><small>{item.slug !== 'free' && '12x de '}R$</small>{item.slug === 'free' ? item.price.replace('.', ',') : (item.price - (item.price * (parseFloat(item.product_plans[0].card_year_discount / 10000)))).toFixed(1).replace('.', ',')}{item.slug !== 'free' && '9'}</h3>
          :
            <h3><small>R$</small>{item.slug === 'free' ? item.price.replace('.', ',') : item.price.replace('.', ',')}<small>/mês</small></h3>
          }            
        </div>
        <div className="home-plans-cta">
          <a href={item.slug === 'free' ? '/registre-se' : `/carrinho?product=${item.id}&recurrence=${activePlan}`}><button>{item.cta}</button></a>
        </div>
        <div className="home-plans-observation">
          {!horizontal ? <p>{item.observation}</p> : <a href="/registre-se">{item.observation}</a>}
        </div>
      </div>
      <div className={`list-open ${planActive[item.slug] ? 'active' : ''}`} onClick={() => togglePlanDetails(item.slug)}>
        <span>Detalhes do plano</span>
        <i className=" ri-arrow-down-s-line"></i>
      </div>
      <div className={`home-plans-list ${planActive[item.slug] ? 'active' : ''}`}>
        {item.skills_title !== '' && <h5>{item.skills_title}</h5>}
        {horizontal && <h4>Vantagens</h4>}
        <ul>
          {item.skills.split(',').map((skill, i) => (
            <li key={i} className={item.slug === 'premium' && (i === 1 || i === 0) ? 'high' : ''}><i className="ri-checkbox-circle-fill"></i>{skill}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}