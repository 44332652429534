import { useEffect, useState } from 'react';
import './styles.scss';
import Axios from 'axios';
import { Button } from 'reactstrap';

export const PlansTerm = ({open, onClose, user}) => {

    const [selectedPlan, setSelectedPlan] = useState([])
    const [checked, setChecked] = useState(false)
    const [date, setDate] = useState('');

    useEffect(() => {
        if(user){
            Axios.get(process.env.REACT_APP_API + '/api/public/list-products')
            .then(response => {
                if(user.type === 'C'){
                    if(user.premium === 1){
                        setSelectedPlan(response.data.find(plan => plan.id === 7))
                    }
                    if(user.premium === 2){
                        setSelectedPlan(response.data.find(plan => plan.id === 8))
                    }
                }
                if(user.type === 'I'){
                    setSelectedPlan(response.data.find(plan => plan.id === 9))
                }
            })
        }
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        setDate(formattedDate);
    }, [user])


    const handleAccept = () => {
        const contractHTML = document.querySelector('.plan-term-body').innerHTML;

        Axios.post(process.env.REACT_APP_API + '/api/auth/buy-plan-term', {
            user_type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            product_id: selectedPlan.id,
            accept: 1,
            contract_html: contractHTML, 
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            onClose()
        })
        .catch(error => {
            onClose()
        });
    };


    return(
        <>
        <div className={`plan-term ${open ? 'active' : ''}`}>
            <div className="plan-term-title">
                <h2>TERMO DE LICENÇA DE USO DE SOFTWARE SAAS</h2>
                <h4>{user.type === 'C' ? (user.premium === 1 ? 'PREMIUM CORRETORES' : user.premium === 2 ? 'START CORRETORES' : '') : (user.premium === 1 ? 'PREMIUM IMOBILIÁRIAS' : '')}</h4>
            </div>
            <div className="plan-term-body">
                <p><b>CONTRATANTE: {user.name}, </b>{user.type === 'C' ? 'corretor de imóveis' : 'imobliária'}, CRECI: {user.type === 'C' ? user.creci : user.creci_j}, com {user.type === 'C' ? 'CPF ' : 'CNPJ '}
                sob o nº {user.type === 'C' ? user.cpf : user.cnpj}, localizado na {user.street}, nº {user.number}, {user.complement}, {user.district}, {user.city} - CEP {user.cep}, doravante denominada apenas como CONTRATANTE.</p>
                <p><b>CONTRATADA: FAST SALE TECNOLOGIA LTDA,</b> pessoa jurídica de direito privado, com CNPJ sob o nº 39.985.590/0001-94, com sede na Rua 700, nº 489, sala 2005, Centro, Balneário Camboriú – CEP 88.330-084, neste ato representada nos termos de seu contrato social, doravante denominada apenas como <b>FAST SALE/CONTRATADA.</b></p>
                <p>Resolvem as Partes firmar o presente <b><span>CONTRATO DE LICENÇA DE USO DE SOFTWARE COMO SERVIÇO – SAAS</span></b> (“Contrato”), que observará as cláusulas e condições dispostas a seguir:</p>
                <h3>CLÁUSULA PRIMEIRA - DO OBJETO</h3>
                <p>1.1 O presente Contrato tem por objeto a outorga de licença de uso do Software <b><span>FAST SALE</span></b> (o “Software/Sistema”), na modalidade Software as a Service - SaaS, em caráter oneroso por contratação através de plano de assinatura, não exclusivo e intransferível, pela <b>FAST SALE</b> para a <b>CONTRATANTE</b>.</p>
                <h3>CLÁUSULA SEGUNDA – FINALIDADE E CONTEÚDO DO SISTEMA</h3>
                <p>2.1 O Software é licenciado com a única finalidade de servir como sistema para intermediação da compra e venda de imóveis em parceria entre corretores e imobiliárias cadastrados no Sistema Fast Sale.</p>
                <p>2.1.1 Para fins de explicação da finalidade acima, o Sistema Fast Sale somente poderá ser utilizado para disponibilização de fotos e informações (“Materiais Informativos”) dos imóveis em mandato em nome da <b>CONTRATANTE</b>, para que a <b>CONTRATANTE</b> disponibilize os imóveis na pauta de imóveis da <b>FAST SALE</b>, os quais serão compartilhados com os corretores e imobiliárias cadastrados no Sistema Fast Sale, com a possibilidade de intermediação de eventual compra e venda de imóvel em parceria. Do mesmo modo, a <b>CONTRATANTE</b> poderá ter acesso à pauta de imóveis da <b>FAST SALE</b>, com a possibilidade de realizar a intermediação de eventual compra e venda de imóvel em parceria com outros corretores e imobiliárias que realizaram o cadastro do imóvel que tenham mandato.</p>
                <p>2.2 Caso a <b>FAST SALE</b> tome ciência de que o conteúdo veiculado pela <b>CONTRATANTE</b> através do Software está em desacordo com o sistema jurídico vigente, o conteúdo poderá ser suspenso, total ou parcialmente, mediante comunicação prévia da FAST SALE, devendo a <b>CONTRATANTE</b> se manifestar a respeito, para solucionar as irregularidades.</p>
                <h3>CLÁUSULA TERCEIRA – DO PLANO DE ASSINATURA CONTRATADO, VALORES E CONDIÇÕES DE PAGAMENTO</h3>
                <p>3.1 A <b>CONTRATANTE</b> realizará a contratação do plano de assinatura denominado <b>{user.type === 'C' ? (user.premium === 1 ? 'PREMIUM CORRETORES' : user.premium === 2 ? 'START CORRETORES' : '') : (user.premium === 1 ? 'PREMIUM IMOBILIÁRIAS' : '')}</b>, no valor estipulado conforme negociações e proposta comercial. O acesso será liberado pelo período contratado, com possibilidade de prorrogação por igual período caso haja renovação.</p>
                <p>3.2 No plano de assinatura contratado estão inclusos os seguintes itens:</p>
                <ul>
                    {selectedPlan?.skills?.split(',')?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
                <p>3.3 O plano de assinatura contratado será renovado automaticamente após o vencimento do prazo contratual, independentemente de notificação da <b>FAST SALE</b> à <b>CONTRATANTE</b>, sendo que os valores estabelecidos neste instrumento poderão ser reajustados anualmente mediante atualização conforme o valor do Índice Nacional de Preços ao Consumidor Amplo – IPCA.</p>
                <p>3.4 Cada Parte será responsável pelo recolhimento e pagamento dos tributos, impostos, taxas e contribuições de qualquer espécie, que existam ou venham a existir, relativos ao presente contrato e de responsabilidade do sujeito passivo da obrigação tributária, conforme definido em lei.</p>
                <p>3.5 O início de uso do Sistema se dará pela formalização deste contrato com a assinatura da <b>CONTRATANTE</b> e o pagamento do valor acordado nas Cláusulas 3.1.</p>
                <h5>UTILIZAÇÃO DO SISTEMA</h5>
                <p>3.6 A <b>FAST SALE</b> disponibilizará uma senha de uso pessoal e intransferível para a CONTRATANTE, a fim de possibilitar o acesso ao Sistema, que serão encaminhadas diretamente para o endereço eletrônico informado no momento do cadastro e deverá ser alterada no primeiro acesso do Usuário.</p>
                <p>3.6.1 A <b>CONTRATANTE</b> é a única responsável pelo resguardo de sua senha e dados para login no Sistema <b>FAST SALE</b>, comprometendo-se a não repassar a terceiros, sob qualquer hipótese.</p>
                <p>3.6.2 Na ocorrência de qualquer fato ou ato que possa comprometer o uso das senhas e acesso aos serviços contratados, a <b>CONTRATANTE</b> deverá comunicar imediatamente à <b>FAST SALE</b>, a fim de que seja realizado o bloqueio das senhas e reinicializado o processo de cadastramento de novas senhas.</p>
                <p>3.6.3 A <b>FAST SALE</b>, com vistas a garantir a necessária segurança na utilização das senhas, reserva-se no direito de, independentemente de prévio aviso, suspender as senhas e reinicializar o processo de cadastramento de novas senhas.</p>
                <p>3.7 Os Materiais dos imóveis enviados pela <b>CONTRATANTE</b> ao Sistema seguirão internamente o padrão do Sistema, sendo que o padrão visual e estético do Sistema não poderá ser modificado. </p>
                <p>3.8 Os imóveis e empreendimentos cadastrados no Sistema serão disponibilizados para os corretores e imobiliárias cadastrados no Sistema da <b>FAST SALE</b>, estando ciente a <b>CONTRATANTE</b> que o presente contrato permanece com natureza de procuração/autorização aos corretores e imobiliárias para realizar a intermediação, em parceria ou individualmente, da compra e venda dos imóveis e empreendimentos disponibilizados.</p>
                <h3>CLÁUSULA QUARTA – ATUALIZAÇÃO DO SOFTWARE E SUPORTE</h3>
                <p>4.1 A <b>FAST SALE</b> poderá realizar atualizações no software, que compreenderão:</p>
                <ul>
                    <li>correção de falhas do Sistema, através da disponibilização de versão corrigida;</li>
                    <li>atualização tecnológica do Sistema, fornecendo as novas versões disponibilizadas com alterações, acréscimos de rotina e/ou melhoria de desempenho.</li>
                    <li>liberação de novas versões com melhorias e evoluções realizadas no Sistema, liberadas periodicamente, conforme o planejamento e iniciativa da <b>FAST SALE</b>.</li>
                </ul>
                <p>4.2 Todas as versões liberadas pela <b>FAST SALE</b> serão colocadas à disposição da <b>CONTRATANTE</b> sem ônus adicional, para acesso direto a ser feito pela própria <b>CONTRATANTE</b>.</p>
                <p>4.3 As implementações e/ou alterações no Sistema, solicitadas pela <b>CONTRATANTE</b>, bem como customizações específicas para a <b>CONTRATANTE</b>, serão objeto de análise da viabilidade, escopo e valor por parte da <b>FAST SALE</b>, mediante apresentação da proposta com prazo, escopo e valor estabelecido pela <b>FAST SALE</b> conforme seu cronograma de desenvolvimento, para aprovação da <b>CONTRATANTE</b>.</p>
                <p>4.4 Manutenção Programada. Qualquer manutenção que possa afetar a disponibilidade do Sistema ou qualquer Serviço contratado será avisada com antecedência mínima de 24 (vinte e quatro) horas.</p>
                <h5>SUPORTE E SLA</h5>
                <p>4.5 O suporte técnico será prestado pela <b>FAST SALE</b> por meio de ferramenta de suporte, em dias úteis, de segunda a sexta-feira, das 08:00h às 12:00h e das 14:00h às 18:00h (horário de Brasília), exceto feriados nacionais e seguindo o prazo de primeira resposta de até 3 (três) horas, e um prazo para resolução de pelo menos 1 (hum) dia útil, a partir do recebimento da solicitação, para solicitações consideradas não complexas. Para demandas consideradas complexas, a <b>FAST SALE</b> enviará à <b>CONTRATANTE</b> prazo específico para resolução, conforme cada caso.</p>
                <p>4.6 Para acesso e validade do serviço de suporte técnico, de acordo com este Contrato, a <b>CONTRATANTE</b> deverá, observadas ainda eventuais premissas adicionais previstas nos demais documentos integrantes deste Contrato:</p>
                <ul>
                    <li>Estar adimplente com o pagamento conforme as Cláusulas 3.1.;</li>
                    <li>Consultar a <b>FAST SALE</b> somente através dos meios de comunicação disponibilizados pela <b>FAST SALE</b>.</li>
                </ul>
                <p>4.7 SLA. A <b>FAST SALE</b> mantém o padrão de qualidade de seus Serviços (SLA) com a disponibilidade ativa de 99,7% (noventa e nove vírgula sete por cento) do Sistema FAST SALE, excluindo-se manutenções previamente comunicadas. </p>
                <h3>CLÁUSULA QUINTA - OBRIGAÇÕES E RESPONSABILIDADES DAS PARTES</h3>
                <h5>DA FAST SALE</h5>
                <p>5.1 Sem prejuízo de outras decorrentes deste Contrato e do sistema jurídico vigente, são obrigações da <b>FAST SALE</b>:</p>
                <ul>
                    <li>Disponibilizar a equipe necessária para cumprir com os prazos pactuados, utilizando, para tanto, pessoal regularmente contratado, com qualificação técnica adequada à plena execução do objeto contratual;</li>
                    <li>Fornecer esclarecimentos e informações que venham a ser solicitados pela <b>CONTRATANTE</b> sobre a execução do objeto contratual;</li>
                    <li>Manter o sistema funcional e operacional de forma a garantir o acesso às funcionalidades e informações, adotando medidas corretivas cabíveis, quando for notificada por escrito de eventual falha no Sistema;</li>
                    <li>Efetuar os ajustes necessários e aprimorar sempre que necessário a qualidade das informações prestadas, aproximando-as à realidade de mercado;</li>
                    <li>Enviar os documentos necessários para pagamento dos valores contratados pela Contratante, como por exemplo, Nota Fiscal ou Fatura.</li>
                </ul>
                <h5>DA CONTRATANTE</h5>
                <p>Sem prejuízo de outras obrigações e responsabilidades previstas neste Contrato, são obrigações da <b>CONTRATANTE</b>:</p>
                <ul>
                    <li>Cadastrar apenas empreendimentos e imóveis que tenha relação comercial ou propriedade, com documentação formalizada, sendo vedada a divulgação de empreendimentos e imóveis de propriedade de terceiros, que não tenha a relação formalmente documentada.</li>
                    <li>Informar os corretores e imobiliárias parceiros na intermediação da compra e venda realizada, para que seja possível realizar o cálculo e repasse dos valores de comissionamento, bem como a retirada dos Materiais do imóvel no Sistema Fast Sale.</li>
                    <li>Realizar corretamente todos os repasses de valores de comissionamento às Partes envolvidas na intermediação da compra e venda realizada, caso o pagamento tenha ocorrido fora do ambiente do Sistema Fast Sale.</li>
                    <li>Informar à <b>FAST SALE</b> quaisquer falhas ou problemas com o Sistema, provendo, nestes casos, toda a documentação, relatórios e demais informações, cooperando com os atendimentos e execução das demais atividades desenvolvidas pela <b>FAST SALE</b>.</li>
                    <li>Responsabilizar-se perante os órgãos e instituições, governamentais ou particulares, os quais regulam seu mercado e sua prática das atividades profissionais, isentando a <b>FAST SALE</b> de qualquer responsabilidade perante tais órgãos e instituições.</li>
                    <li>A <b>CONTRATANTE</b> declara, para todos os fins deste contrato, que realiza suas atividades profissionais em conformidade com as legislações nacionais e internacionais de compliance e governança corporativa para evitar fraudes, corrupção, lavagem de dinheiro e financiamento ao terrorismo.</li>
                    <li>A <b>CONTRATANTE</b> declara que realiza sua atividade profissional conforme às legislações de proteção de dados pessoais, editar e manter políticas, contratos e regimentos, com fundamento e princípios especialmente da Lei Geral de Proteção de Dados Pessoais – LGPD, nº 13.709/2018, e seguir o disposto neste contrato.</li>
                    <li>A <b>CONTRATANTE</b> declara que está devidamente inscrita e apta para desenvolver sua atividade profissional enquanto corretora de imóveis, conforme legislação e normas do CRECI de seu Estado. </li>
                </ul>
                <p>5.3 Outras obrigações poderão ser estabelecidas em termos e aditivos dentro do Sistema Fast Sale, as quais deverão ser cumpridas pela <b>CONTRANTE</b>, caso a <b>CONTRATANTE</b> realize o aceite de tais termos e aditivos. </p>
                <h3>CLÁUSULA SEXTA - LIMITAÇÕES DE RESPONSABILIDADES</h3>
                <p>6.1 Este Contrato não vincula nenhuma das Partes com relação à outra quanto aos resultados econômicos presentes ou futuros de seus respectivos negócios, não sendo, desta forma, nenhuma delas responsável com relação à outra, por tais resultados, seja durante a vigência deste Contrato ou mesmo após o seu término, exceto nos casos em que for comprovada a ação ou omissão deliberada a fim de prejudicar a outra, caracterizando o dolo.</p>
                <p>6.2 A <b>FAST SALE</b> não fornece garantia de funcionamento a softwares de terceiros que interajam com o Sistema.</p>
                <p>6.3 Fica expressamente estipulado que não se estabelece entre as partes por força do presente Contrato, qualquer vínculo empregatício, societário ou associativo de qualquer natureza ou espécie, não existindo qualquer vínculo ou responsabilidade entre uma parte e os empregados, trabalhadores ou colaboradores a qualquer título da parte contrária, permanecendo cada parte como única responsável por todas as respectivas despesas e encargos, sejam de natureza trabalhista, previdenciária, securitária, civil ou de qualquer outra natureza ou espécie. </p>
                <p>6.4 A <b>FAST SALE</b> se resguarda o direito de, independentemente de autorização ou notificação a <b>CONTRATANTE</b>, a qualquer tempo, ser objeto de alienação, fusão, incorporação ou cisão, no todo ou em parte, a terceiro ou a empresa filiada sua, ou coligada, controladora, controlada ou subsidiária, devendo a transferência dos direitos e obrigações descritas no presente Contrato operar-se automaticamente, mediante comunicação a <b>CONTRATANTE</b>, com integral cumprimento das condições e cláusulas aqui previstas.</p>
                <p>6.5 As Partes se comprometem a não contratar, durante a vigência deste Contrato e pelo prazo de 1 (um) ano após o seu término, qualquer empregado ou prestador de serviços diretamente envolvido na prestação de serviços objeto do presente instrumento, sem o consentimento por escrito da outra Parte, sob pena de indenizá-la pelo equivalente a 6 (seis) vezes o valor da última remuneração do(s) respectivo(s) profissional(is).</p>
                <h3>CLÁUSULA SÉTIMA - PROTEÇÃO DE DADOS PESSOAIS</h3>
                <h5>Tratamento de Dados Pessoais</h5>
                <p>7.1 As Partes reconhecem que, em razão do objeto deste Contrato, realizarão atividades de tratamento de dados pessoais relacionadas a pessoas naturais identificadas ou identificáveis e declaram que, no contexto do desempenho de suas obrigações contratuais, cumprirão toda a legislação aplicável a tal tratamento, especialmente, à Lei Geral de Proteção de Dados – LGPD (Lei nº 13.709/2018).</p>
                <p>7.2 Qualquer tratamento de dados pessoais realizado por qualquer das Partes que exceda as finalidades previstas neste Contrato e o escopo das instruções fornecidas pela <b>FAST SALE</b> será expressamente proibido, obrigando-se a Parte Infratora a indenizar a outra Parte por todo e qualquer dano e/ou prejuízo eventualmente causado, em razão de tal tratamento não autorizado.</p>
                <p>7.3 Caso a <b>CONTRATANTE</b> precise se utilizar de quaisquer dos dados pessoais tratados no âmbito deste Contrato para cumprir obrigações legais ou regulatórias a que esteja sujeito ou para exercer seus direitos em processos judiciais, administrativos ou arbitrais existentes, deverá informar tais necessidades à <b>FAST SALE</b>, por escrito e de forma detalhada, com razoável antecedência em relação à realização do tratamento almejado.</p>
                <p>7.4 A <b>CONTRATANTE</b> compromete-se a realizar suas atividades profissionais conforme boas práticas de proteção de dados pessoais que levem em conta o tratamento de dados pessoais relativo ao objeto deste contrato, a natureza, o escopo, a finalidade, a probabilidade e a gravidade dos riscos e dos benefícios decorrentes de tratamento de dados do titular.</p>
                <p>7.5 A <b>CONTRATANTE</b> somente utilizará as informações obtidas através do Sistema Fast Sale para o fim específico de intermediar a compra e venda dos imóveis na pauta da <b>FAST SALE</b>, bem como cadastrá-los na pauta da <b>FAST SALE</b>, utilizando-se da boa-fé e do bom costume que permeia o mercado imobiliário para a realização da captação de: imóveis; proprietários; compradores; investidores interessados; dentre outros.</p>
                <h5>Exercício de Direitos por Titulares </h5>
                <p>7.6 Caso algum titular de dados pessoais questione qualquer Parte sobre o tratamento de seus dados pessoais realizado pelas Partes e/ou solicite o exercício de quaisquer de seus direitos previstos na legislação aplicável, a Parte requerida deverá tomar as medidas conforme a legislação e requerimento do titular, preservando e observando sempre a base legal para o tratamento de tais dados pessoais. </p>
                <h3>CLÁUSULA OITAVA - PROPRIEDADE INTELECTUAL</h3>
                <p>8.1 A <b>CONTRATANTE</b> declara e reconhece que o Sistema, assim como sua documentação técnica e quaisquer informações, bem como seus respectivos nomes, marcas e demais sinais distintivos, são de propriedade exclusiva da FAST SALE e/ou de terceiros, regularmente cedidos pela <b>FAST SALE</b>, sendo permitido a <b>CONTRATANTE</b> sua utilização, nos limites e para os fins previstos neste instrumento, não havendo alteração em relação aos direitos sobre tecnologias, programas e outros relacionados, que permanecerão como propriedade da <b>FAST SALE</b>.</p>
                <p>8.2 Quaisquer implementações feitas no Sistema, a qualquer tempo, pela <b>FAST SALE</b>, a pedido ou não da <b>CONTRATANTE</b>, serão de propriedade exclusiva da <b>FAST SALE</b>, podendo ser incorporadas ao Sistema, a critério da <b>FAST SALE</b>, e/ou utilizadas como melhor lhe convier, não cabendo a <b>CONTRATANTE</b> reivindicar direitos pecuniários, de uso, comercialização ou autorais.</p>
                <p>8.3 Qualquer outra cópia do Sistema objeto deste Contrato, além da Cópia de Reserva (“backup”), de Contingência, de Testes, de Homologação ou de Treinamento, será considerada cópia não autorizada, e sua existência, dentro ou fora do ambiente da <b>CONTRATANTE</b>, será uma violação aos direitos de propriedade da <b>FAST SALE</b>, sujeitando-se a <b>CONTRATANTE</b> às penalidades previstas neste Contrato e legislação específica.</p>
                <p>8.4 Sem prejuízo de outras limitações decorrentes da lei ou do presente Contrato, não poderá a <b>CONTRATANTE</b> modificar, copiar, imitar, distribuir, criar modalidades semelhantes ou realizar qualquer espécie de engenharia reversa, total ou parcial, do Método e Software da <b>FAST SALE</b> em detrimento desta, bem como usá-las na para tornar-se concorrente da <b>FAST SALE</b>.</p>
                <p>8.5 Para além das Cláusulas acima, a <b>CONTRATANTE</b> deverá observar o disposto nas legislações nacionais de proteção aos direitos de propriedade intelectual, direito autoral e softwares, em especial a Lei nº 9.610/98 (Lei de Direitos Autorais), Lei nº 9.609/98 (Lei de Programa de Computador) e Lei nº 9.279/96 (Lei de Propriedade Intelectual).</p>
                <h3>CLÁUSULA NONA – CONFIDENCIALIDADE</h3>
                <p>9.1 Cada <b>PARTE</b> (a “Parte Receptora”) se responsabiliza por si, seus empregados e prepostos, por prazo de 5 (cinco) anos, em manter como confidenciais, os termos deste Contrato e todas as outras informações e conhecimentos não públicos da outra Parte, revelados ou obtidos pela Parte Receptora, como resultado deste Contrato, ou a ele relativos, e do relacionamento extracontratual entre as <b>PARTES</b> (“Informação Confidencial”).</p>
                <p>9.2 As <b>PARTES</b> concordam que os termos deste Contrato constituem Informação Confidencial. Ademais, concordam em utilizar todos os esforços para proteger as Informações Confidenciais da outra parte e tomar as precauções necessárias e idênticas às que tomaria para proteger as suas próprias informações confidenciais da mesma natureza.</p>
                <p>9.3 As restrições precedentes não devem ser aplicadas a qualquer informação que:</p>
                <ul>
                    <li>seja comprovadamente conhecida pela Parte Receptora, anteriormente à sua divulgação pela outra parte.</li>
                    <li>seja ou se tornou de domínio público, sem qualquer intervenção da Parte Receptora.</li>
                    <li>a Informação Confidencial seja requisitada judicialmente ou por autoridade policial.</li>
                    <li>seja divulgada à Parte Receptora por qualquer terceiro, desde que legalmente autorizado a efetuar tal divulgação, sem que isto constitua violação de qualquer obrigação de caráter confidencial.</li>
                </ul>
                <h3>CLÁUSULA DÉCIMA - DA FIDELIDADE E RESCISÃO</h3>
                <h5>Prazo de Fidelidade</h5>
                <p>10.1 O prazo de fidelidade deste instrumento é de acordo com o plano contratado sendo ele anual ou recorrência mensal, sendo prorrogado automaticamente por igual período e por quantas vezes necessário, independentemente de notificação pela <b>FAST SALE</b>.</p>
                <p>10.2 A contratação do plano de assinatura é realizada em ato único com condição de pagamento parcelado, e dá direito à <b>CONTRATANTE</b> de acessar o Sistema e os serviços pelo prazo selecionado pela <b>CONTRATANTE</b> no ato do pagamento desse plano. Desta forma, o cancelamento da assinatura em qualquer período durante o prazo de duração deste contrato significará a não renovação da fidelidade estipulada na Cláusula 10.1. acima, permanecendo o contrato atual ativo até a finalização do prazo contratual.</p>
                <h5>Rescisão</h5>
                <p>10.3 Caso a <b>CONTRATANTE</b> deseje cancelar a assinatura/rescindir o contrato, de forma unilateral e sem justa causa, deverá observar o disposto na Cláusula 10.2. acima, permanecendo a obrigação de pagamento do valor contratado, na hipótese de ainda não o ter realizado. Na hipótese de pagamento à vista, o plano de assinatura será considerado totalmente adimplido, não comportando a devolução de qualquer valor pela <b>FAST SALE</b>, permanecendo a <b>CONTRANTE</b> com o acesso ao Sistema, conforme o presente instrumento.</p>
                <h3>CLÁUSULA DÉCIMA PRIMEIRA - DISPOSIÇÕES GERAIS</h3>
                <p>11.1 <span>Cessão.</span> O presente contrato não poderá ser cedido total ou parcialmente por nenhuma das partes sem a prévia e expressa concordância por escrito da parte contrária.</p>
                <p>11.2 <span>Notificações/Comunicações.</span> Todas as notificações e comunicações, judiciais ou extrajudiciais, relativas a este instrumento, deverão ser feitas por escrito, e encaminhadas por e-mail, com comprovante de recebimento pela parte destinatária, ou por carta, com aviso de recebimento, para os endereços informados na qualificação das Partes, sendo consideradas válidas para todos os fins, salvo se houver prévia comunicação de alteração dos dados informados.</p>
                <p>11.3 <span>Sucessão.</span> As Partes se obrigam por si, seus herdeiros e sucessores a qualquer título, ao fiel cumprimento deste Contrato.</p>
                <p>11.4 <span>Nulidade.</span> Se qualquer disposição deste contrato particular for considerada nula, anulável, inválida ou inoperante, nenhuma outra disposição deste contrato particular será afetada como consequência disso e, portanto, as disposições restantes deste pacto permanecerão em pleno vigor e efeito como se tal disposição nula, anulável, inválida ou inoperante não estivesse contida neste ajuste.</p>
                <p>11.5 <span>Representação.</span> As Partes declaram que obtiveram todas as autorizações necessárias para a assinatura e execução deste Contrato e que os signatários deste têm poderes para assumir obrigações em nome das respectivas Partes, sob pena de responderem civil e criminalmente.</p>
                <p>11.6 <span>Foro.</span> As Partes elegem o Poder Judiciário da cidade de Balneário Camboriú/SC - Brasil, como único foro competente para dirimir toda e qualquer questão decorrente, direta ou indiretamente, do Contrato, com exclusão de qualquer outro, por mais privilegiado que seja.</p>
                <p>11.7 Ao aceitar os termos e condições estabelecidos neste contrato, o(a) contratante confirma a sua concordância com todas as cláusulas e disposições aqui descritas. O aceite, registrado através da assinatura ou outro método de confirmação aceito, será considerado válido e vinculativo para todas as partes envolvidas, efetivando a aplicação e execução integral das cláusulas estipuladas. A partir do momento do aceite, todas as disposições deste contrato entram em vigor e passam a ser obrigatórias, tendo plena validade jurídica conforme as leis aplicáveis.</p>
                <br></br>
                <p><b>Termo aceito e validado em {date}</b></p>
             </div>
             <div className="plan-term-footer">
                <div className="plan-term-accept">
                    <input type="checkbox" id="accept" name="accept" value={checked} onClick={() => setChecked(!checked)}></input>
                    <label htmlFor='accept'>Estou de acordo com todos os termos descritos acima.</label>
                </div>
                <div className="plan-term-ctas">
                    {checked &&
                        <Button onClick={handleAccept}>Aceitar contrato</Button>
                    }
                </div>
             </div>
        </div>
        <div className={`plan-term-overlay ${open ? 'active' : ''}`}></div>
        </>
    )
}