import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './styles.scss';
import { useEffect, useState } from 'react';
import Axios from 'axios';

export const SelectedPlan = ({product, recurrence, coupon, onApplyCoupon}) => {
  const history = useHistory()
  const [selectedCoupon, setSelectedCoupon] = useState('')
  const [notFound, setNotFound] = useState('')
  const [discount, setDiscount] = useState(null)
  const [verifyCoupon, setVerifyCoupon] = useState(null)

  useEffect(() => {
    if(coupon){
      handleChangeCoupon(coupon)
    }
  }, [coupon])

  const handleApply = () => {
    if(selectedCoupon !== '' && selectedCoupon !== 'null' && selectedCoupon !== undefined){
      Axios.get(process.env.REACT_APP_API + '/api/coupon/discount/' + selectedCoupon)
      .then(resp => {
        setDiscount(parseInt(resp.data.discount))
        setVerifyCoupon(parseInt(resp.data.id))
      }).catch(error => {
        if(error.response?.data?.error === 'Coupon not found'){
          setNotFound('Cupom inválido.')
        }
      })
    }
  }

  useEffect(() => {
    	if(discount && verifyCoupon){
        onApplyCoupon(verifyCoupon)
      }
  }, [discount, verifyCoupon])

  const handleRemoveCoupon = () => {
    setSelectedCoupon('')
    setDiscount(null)
    setVerifyCoupon(null)
    setNotFound('')
  }

  const handleChangeCoupon = (e) => {
    setSelectedCoupon(e)
    setNotFound('')
  }

  useEffect(() => {
    handleApply()
  },[handleChangeCoupon])

  const productPrice = product.price * 12;
  const productDiscountPrice = ((product.price) * (parseFloat(product?.product_plans[0].card_year_discount / 10000)));
  const productDiscountedPrice = ((product.price) - (product.price) * (parseFloat(product?.product_plans[0].card_year_discount / 10000)));
  const productPriceMonth = parseFloat(product.price);
  const productParcels = (((parseFloat(product.price) * 12) / (100 / (product?.product_plans[0].card_year_discount / 100)) - 0.6) / 12);

  return(
    product &&
    <div className="selected-plan-review-container">
      <div className="title">
        <h5>Plano selecionado</h5>
      </div>
      <div className="plan">
        <div className="plan-card">
          {product.user_type === 'C' && <h5>Corretor</h5>}
          {product.user_type === 'I' && <h5>Imobiliária</h5>}
          {product.user_type === 'T' && <h5>Construtora</h5>}
          <h3>{product.name}</h3>
          <div className="plan-price">
            {recurrence === 'month' && <p>Plano Mensal</p>}
            {recurrence === 'third' && <p>Plano Trimestral</p>}
            {recurrence === 'six' && <p>Plano Semestral</p>}
            {recurrence === 'year' && <p>Plano Anual</p>}

            {recurrence === 'year' ?
              <>
                {discount ?
                  <>
                    <span style={{textDecoration:'line-through', color:'#888'}}>{productPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span><br></br>
                    <span style={{fontSize:'13px', color:'#f4f4f4'}}>-{(productDiscountPrice * 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (desc. plano anual)</span><br></br>
                    <span style={{fontSize:'13px', color:'#f4f4f4'}}>-{((productDiscountedPrice * 12) * (discount / 100)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (desc. cupom)</span><br></br>
                    <span style={{fontSize:'24px'}}>{((productDiscountedPrice * 12) - ((productDiscountedPrice * 12) * (discount / 100))).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span><br></br>
                    <span>até 12x de {(productDiscountedPrice - ((productDiscountedPrice) * (discount / 100)))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                  </>
                  :
                  <>
                    <span style={{textDecoration:'line-through', color:'#888'}}>{productPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span><br></br>
                    <span style={{fontSize:'13px', color:'#f4f4f4'}}>-{(productDiscountPrice * 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (desc. plano anual)</span><br></br>
                    <span style={{fontSize:'24px'}}>{(productDiscountedPrice * 12).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span><br></br>
                    <span>até 12x de {productDiscountedPrice?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                  </>
                  }
              </>
              :
              <>
                  {discount ?
                    <>
                      {recurrence === 'month' && <span style={{textDecoration:'line-through', color:'#888'}}>{(productPriceMonth)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>}<br></br>
                      {recurrence === 'month' && <span style={{fontSize:'13px'}}>-{discount ? (((discount / 100) * productPriceMonth))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : productPriceMonth?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} (Cupom)</span>}<br></br>
                      {recurrence === 'month' && <span style={{fontSize:'22px'}}>{discount ? (productPriceMonth - ((discount / 100) * productPriceMonth))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : productPriceMonth?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>}
                    </>
                  :
                  recurrence === 'month' && <span style={{fontSize:'22px'}}>{parseFloat(product.price)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                  }
              </>
            }
          </div>
        </div>
        <button onClick={() => history.push('/carrinho')}>Alterar</button>
      </div>
      <div className="coupon-container">
        <label>Cupom de desconto</label>
        <div className="coupon">
          <input value={selectedCoupon} onChange={(e) => handleChangeCoupon(e.target.value)}/>
          <span onClick={handleApply}>{discount > 0 ? 'Aplicado' : 'Aplicar'}</span>
        </div>
        {discount ? <span className="remove-coupon" onClick={handleRemoveCoupon}>Remover cupom</span> : <></>}
        <p className="coupon-error">{notFound}</p>
      </div>
    </div>
  )
}