import { Badge, Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner  } from "reactstrap";
import './styles.scss'
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Select from "react-select";
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import Tinder from './../../../assets/images/deumatch.png';
import Logo from './../../../assets/images/logo_tinder.png';
import { FeedContract } from "./components/feedContract";
import { FeedModal } from "./components/feedModal";
import { ForPremiumUser } from "../../../components/ForPremiumUser";
import { UpdateProfilePhoto } from "../../../components/updateProfilePhoto";
import { FeedFilters } from "./components/feedFilters";

require('moment/locale/pt.js');

export const Feed = ({openFeed}) => {
    const feedRef = useRef(null); 
    const [selectedTab, setSelectedTab] = useState('all')
    const [newOrderModal, setNewOrderModal] = useState(false)
    const [ordersPropertys, setOrdersPropertys] = useState([])
    const [ordersYourPropertys, setOrdersYourPropertys] = useState([])
    const [count, setCount] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteSuccess, setDeleteSuccess] = useState(false)
    const [deleteError, setDeleteError] = useState(false)
    const [deleteId, setDeleteId] = useState(0)
    const [categoryFilter, setCategoryFilter] = useState(0)
    const [contract, setContract] = useState(false)
    const [data, setData] = useState([])
    const [editId, setEditId] = useState(null)
    const [isEditOrder, setIsEditOrder] = useState(false)
    const [modalCenter, setModalCenter] = useState(false)
    const [userPendent, setUserPendent] = useState(localStorage.getItem('status'))
    const [userPlan, setUserPlan] = useState(null)
    const [noPremium, setNoPremium] = useState(false)
    const [addPhoto, setAddPhoto] = useState(false)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [atualPage, setAtualPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState([])
    const [seed, setSeed] = useState(null);

    useEffect(() => {
        Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
            type:localStorage.getItem('access'),
            user_id:localStorage.getItem('id')
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                setUserPlan(true)
            })
            .catch(res => {
                setUserPlan(false)
            })
    }, [])

    const handleContract = (e) => {
        if(e){
            setNewOrderModal(true)
            setContract(false)
        }else{
            setContract(false)
        }
    }
  

    const handleModal = () => {
        setNewOrderModal(prevState => !prevState)
    }

    useEffect(() => {
        if(openFeed){
            setNewOrderModal(true)
        }
    }, [openFeed])

    useEffect(() => {
        setNewOrderModal(false)
        handleItems(1)
        const data = new FormData()
        if(localStorage.getItem('access') === 'C'){
            data.append('broker_id', localStorage.getItem('id'))
        }
        if(localStorage.getItem('access') === 'I'){
            data.append('real_estate_id', localStorage.getItem('id'))
        }

        Axios.post(process.env.REACT_APP_API + '/api/auth/list-user-orders', data,
        {headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => setOrdersYourPropertys(response.data))
    }, [count])

    const handleItems = (page, filters) => {
        let currentSeed = seed;

        if (!currentSeed) {
            currentSeed = Math.floor(Math.random() * 1000000);
            setSeed(currentSeed);
        }
        setLoading(true)
        Axios.post(process.env.REACT_APP_API + '/api/auth/list-orders-paginate', {
            page:page,
            search:filters && filters.search ? filters.search : '',
            category:filters && filters.category ? filters.category : '',
            city:filters && filters.city ? filters.city : '',
            state:filters && filters.state ? filters.state : '',
            seed: currentSeed
        },
        {headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            if(page === 1){
                setOrdersPropertys(response.data.data)

                if (feedRef.current) {
                    feedRef.current.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            }else{
                setOrdersPropertys(prevItems => [...prevItems, ...response.data.data])
            }
            setTotal(response.data.total)
            setTotalPages(response.data.last_page)
            setLoading(false)
        }).catch(response => {
            setLoading(false)
        })
    }

    const handleScroll = () => {
        const feedElement = feedRef.current;
    
        if (feedElement.scrollTop + 700 > feedElement.scrollHeight && atualPage < totalPages && !loading) {
          setAtualPage(prevPage => prevPage + 1);
          handleItems(atualPage + 1, filters)
        }
      };
    
      useEffect(() => {
        if(selectedTab === 'all'){
            const feedElement = feedRef.current;
            feedElement.addEventListener('scroll', handleScroll);
            return () => feedElement.removeEventListener('scroll', handleScroll);
        }
      }, [handleScroll]);

    const handleDelete = () => {
        setDeleteModal(false)
        Axios.post(process.env.REACT_APP_API + '/api/auth/delete-order/' + deleteId, {},
        {headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            setCount(prevState => prevState + 1)
            setDeleteSuccess(true)
        })
    }

    const handleActionType = (type) => {
        if(userPendent !== '3' && userPendent !== '2'){
            if(userPlan){
                if(type === 'update'){
                    setIsEditOrder(true)
                }else{
                    setIsEditOrder(false)
                }
                handleVerifyPhoto()
            }else{
                setNoPremium(true)
            }
        }else{
            setModalCenter(true)
        }

    }

    const handleClear = () => {
        handleModal()
    }
    
    const handleVerifyPhoto = () => {
        if(localStorage.getItem('access') === 'A'){
            handleModal()
        }else{
            Axios.post(process.env.REACT_APP_API + '/api/auth/user-has-photo', {
                type:localStorage.getItem('access'),
                user_id:localStorage.getItem('id')
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                .then(res => {
                    if(res.data.hasProfilePhoto){
                        handleModal()
                    }else{
                        setAddPhoto(true)
                    }
                })
        }
    }

    const handleSuccessNewPhoto = () => {
        handleModal()
    }

    const handleSearch = (filters) => {
        handleItems(1, filters)
        setFilters(filters)
    }

    return (
        <>
        <div className="feed-title-container">
            <img src={Logo}/>
            <h3>Tinder dos imóveis</h3>
        </div>
        <Col>
            <div className="feed-buttons-container">
                <div 
                    className={`feed-option ${selectedTab === 'all' ? 'active' : ''}`}
                    onClick={() => setSelectedTab('all')}
                >Pedidos dos corretores Fast</div>
                {(localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'S' && localStorage.getItem('access') !== 'A') &&
                    <div
                        className={`feed-option ${selectedTab === 'your' ? 'active' : ''}`}
                        onClick={() => setSelectedTab('your')}
                    >Meus pedidos</div>
                }
            </div>
            <Card className="feed-card">
                <CardBody style={{position:'relative'}}>
                    <Row>
                        {selectedTab === 'all' &&
                            <Col md={4}>
                                <div className="card-title-container">
                                    <h4 className="card-title mb-4">Pedidos dos corretores Fast</h4>

                                    <FeedFilters data={ordersPropertys} onSearch={handleSearch}/>

                                    {/* <Button className="new-order-create" onClick={() => handleActionType('create')}>Fazer pedido de imóvel</Button> */}
                                    {(localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'S' && localStorage.getItem('access') !== 'A') &&
                                    <div className="feed-cta-container">
                                        <div className="feed-cta-body">
                                            <p style={{fontSize:'15px', fontWeight: '500'}}>Faça seu pedido para milhares de corretores com a segurança da Fast Sale</p>
                                        </div>
                                        <div className="feed-cta-footer">
                                            <Button style={{width:'auto'}} onClick={() => handleActionType('create')}>
                                                QUERO PEDIR UM IMÓVEL
                                            </Button>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </Col>
                        }
                        <Col md={selectedTab === 'all' ? 8 : 12}>
                            {selectedTab === 'your' ?
                            <>
                                <div className="card-title-container">
                                    <h4 className="card-title mb-4">Seus pedidos</h4>

                                    <span>
                                        <Input type="select" value={categoryFilter} placeholder="Categoria" onChange={(e) => setCategoryFilter(e.target.value)}>
                                            <option value={0}>Todas</option>
                                            <option value={12}>Apartamento</option>
                                            <option value={14}>Casa</option>
                                            <option value={34}>Comercial</option>
                                            <option value={22}>Terreno</option>
                                            <option value={35}>Outros</option>
                                        </Input>

                                        <Button className="new-order-create" onClick={() => handleActionType('create')}>Fazer pedido de imóvel</Button>
                                    </span>
                                </div>

                                <div className="your order-list">
                                    {ordersYourPropertys.length > 0 ? ordersYourPropertys.map((item, index) => (
                                    <div key={index} className="order-list-item">
                                        <div className="order-cover">
                                            <div className="photo" style={{
                                                backgroundImage:`url('${item?.broker?.profile_photo}')`
                                            }}></div>
                                        </div>
                                        <div className="order-content">
                                            <h3>{item?.real_estate_id ? item?.real_estate?.name : item?.broker?.name}</h3><small>Postado dia {moment(item?.created_at).format('DD/MM/YYYY')}</small>
                                            <div className="order-content-container">
                                
                                                <div className="order-type">
                                                    {(item?.objective && item?.objective !== '' && item?.objective !== 'null' && item?.objective !== 'undefined') && <span>{item?.objective === 'sell' && 'Compra'}{item?.objective === 'rent' && 'Aluguel'}</span>}
                                                    {item?.category && <span>{item?.category?.name}</span>}
                                                    {(item?.high && item?.high !== '' && item?.high !== 'undefined') && <span>{item?.high}</span>}
                                                </div>

                                                <div className="order-observation">
                                                    {item?.obs}
                                                </div>

                                                <div className="order-range">
                                                    {(item?.min_price !== 0 || item?.max_price !== 0) &&
                                                        <div className="order-item-range">
                                                            <span><i className="ri-coins-line"></i></span>
                                                            <span>{item?.min_price !== 0 && (item?.min_price / 100)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}{((item?.max_price !== item?.min_price) && item?.max_price !== 0) && ' até ' + (item?.max_price / 100)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                                                        </div>
                                                    }
                                                    {(item?.min_rooms !== 0 || item?.max_rooms !== 0) &&
                                                        <div className="order-item-range">
                                                            <span><i className="ri-hotel-bed-fill"></i></span>
                                                            <span>{item?.min_rooms !== 0 && item?.min_rooms}{((item?.max_rooms !== item?.min_rooms) && item?.max_rooms !== 0) && ' até ' + item?.max_rooms}</span>
                                                        </div>
                                                    }
                                                    {(item?.min_parking !== 0 || item?.max_parking !== 0) &&
                                                        <div className="order-item-range">
                                                            <span><i className="ri-car-line"></i></span>
                                                            <span>{item?.min_parking !== 0 && item?.min_parking}{((item?.max_parking !== item?.min_parking) && item?.max_parking !== 0) && ' até ' + item?.max_parking}</span>
                                                        </div>
                                                    }
                                                    {(item?.min_area !== 0 || item?.max_area !== 0) &&
                                                        <div className="order-item-range">
                                                            <span><i className="ri-ruler-line"></i></span>
                                                            <span>{item?.min_area !== 0 && item?.min_area}{((item?.max_area !== item?.min_area) && item?.max_area !== 0) && ' até ' + item?.max_area}</span>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="order-localization">
                                                    <i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i><span>{(item?.city && item?.city !== 'null') && item?.city}{(item?.district && item?.district !== 'null') && ' - ' + item?.district}{(item?.enterprise && item?.enterprise !== 'null') && ' - ' + item?.enterprise}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="order-action">
                                            <Button color="dark" onClick={() => {
                                                setEditId(item?.id)
                                                handleActionType('update')
                                            }}>Editar</Button>
                                            <span onClick={() => {
                                                setDeleteModal(true)
                                                setDeleteId(item?.id)
                                            }}><i className="ri-delete-bin-line"></i></span>
                                        </div>
                                    </div>
                                    )):
                                    <p>Você ainda não fez um pedido de imóvel.</p>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className="order-list" ref={feedRef}>
                                    {ordersPropertys?.map((item, index) => (
                                        <div key={index} className="order-list-item">
                                            <div className="order-cover">
                                                <div className="photo" style={{
                                                    backgroundImage:`url('${item?.real_estate_id ? item?.real_estate?.profile_photo : item?.broker?.profile_photo}')`
                                                }}></div>
                                            </div>
                                            <div className="order-content">
                                            <div>
                                                <h3>{item?.real_estate_id ? item?.real_estate?.name : item?.broker?.name}</h3><small>Postado dia {moment(item?.created_at).format('DD/MM/YYYY')}</small>
                                                <div className="order-content-container">
                                    
                                                    <div className="order-type">
                                                        {(item?.objective && item?.objective !== '' && item?.objective !== 'null' && item?.objective !== 'undefined') && <span>{item?.objective === 'sell' && 'Compra'}{item?.objective === 'rent' && 'Aluguel'}</span>}
                                                        {item?.category && <span>{item?.category?.name}</span>}
                                                        {(item?.high && item?.high !== '' && item?.high !== 'undefined') && <span>{item?.high}</span>}
                                                    </div>

                                                    <div className="order-observation">
                                                        {item?.obs}
                                                    </div>

                                                    <div className="order-range">
                                                        {(item?.min_price !== 0 || item?.max_price !== 0) &&
                                                            <div className="order-item-range">
                                                                <span><i className="ri-coins-line"></i></span>
                                                                <span>{item?.min_price !== 0 && (item?.min_price / 100)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}{((item?.max_price !== item?.min_price) && item?.max_price !== 0) && ' até ' + (item?.max_price / 100)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                                                            </div>
                                                        }
                                                        {(item?.min_rooms !== 0 || item?.max_rooms !== 0) &&
                                                            <div className="order-item-range">
                                                                <span><i className="ri-hotel-bed-fill"></i></span>
                                                                <span>{item?.min_rooms !== 0 && item?.min_rooms}{((item?.max_rooms !== item?.min_rooms) && item?.max_rooms !== 0) && ' até ' + item?.max_rooms}</span>
                                                            </div>
                                                        }
                                                        {(item?.min_parking !== 0 || item?.max_parking !== 0) &&
                                                            <div className="order-item-range">
                                                                <span><i className="ri-car-line"></i></span>
                                                                <span>{item?.min_parking !== 0 && item?.min_parking}{((item?.max_parking !== item?.min_parking) && item?.max_parking !== 0) && ' até ' + item?.max_parking}</span>
                                                            </div>
                                                        }
                                                        {(item?.min_area !== 0 || item?.max_area !== 0) &&
                                                            <div className="order-item-range">
                                                                <span><i className="ri-ruler-line"></i></span>
                                                                <span>{item?.min_area !== 0 && item?.min_area}{((item?.max_area !== item?.min_area) && item?.max_area !== 0) && ' até ' + item?.max_area}</span>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="order-localization">
                                                        <i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i><span>{(item?.city && item?.city !== 'null') && item?.city}{(item?.district && item?.district !== 'null') && ' - ' + item?.district}{(item?.enterprise && item?.enterprise !== 'null') && ' - ' + item?.enterprise}</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="order-action">
                                                {localStorage.getItem('access') === 'A' ?
                                                    <>
                                                        <Button onClick={() => {
                                                            setEditId(item?.id)
                                                            handleActionType('update')
                                                        }}>Editar pedido</Button>
                                                        <span onClick={() => {
                                                            setDeleteModal(true)
                                                            setDeleteId(item?.id)
                                                        }}><i className="ri-delete-bin-line"></i></span>
                                                    </>
                                                    :
                                                    <Button onClick={() => {
                                                        const data = new FormData();
                                                        data.append('property', item.id)
                                                        if(localStorage.getItem('access') === 'C'){
                                                            data.append('send_broker_id', localStorage.getItem('id'))
                                                        }
                                                        if(localStorage.getItem('access') === 'I'){
                                                            data.append('send_real_estate_id', localStorage.getItem('id'))
                                                        }
                                                        if(data.real_estate_id){
                                                            data.append('receive_real_estate_id', item.real_estate_id)
                                                        }
                                                        if(data.broker_id){
                                                            data.append('receive_broker_id', item.broker_id)
                                                        }
    
                                                        Axios.post(process.env.REACT_APP_API + '/api/auth/contracts/check-existence', data,
                                                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                                        .then(response => {
                                                            if(response.data.exists){
                                                                setTimeout(() => {
                                                                    window.open(`https://api.whatsapp.com/send?phone=55` + (item?.broker?.phone ? item?.broker?.phone : item?.real_estate.phone ? item?.real_estate?.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá, tenho o imóvel que você está procurando no "Tinder dos Imóveis" da Fast Sale.`, "_blank")
                                                                }, 300);
                                                            }else{
                                                                setContract(true)
                                                                setData(item)
                                                            }
                                                        })
                                                    }}>Tenho este imóvel!</Button>
                                                }

                                            </div>
                                            </div>
                                        </div>
                                    ))}
                                    {(ordersPropertys.length === 0) &&
                                        <p>Nenhum pedido de imóvel disponível no momento.</p>
                                    }
                                    {loading &&
                                        <Spinner className="me-2" color="success" />
                                    }
                                </div>
                                <p style={{fontSize:'14px', textAlign:'right'}}>Foram encontrados {total} pedidos.</p>
                            </>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <FeedContract contract={contract} data={data} callback={(e) => handleContract(e)}/>

            <FeedModal openModal={newOrderModal} closeModal={handleClear} isEdit={isEditOrder} editId={editId}/>

            <ForPremiumUser open={noPremium} onClose={() => setNoPremium(false)}/>

            <UpdateProfilePhoto open={addPhoto} onClose={() => setAddPhoto(false)} onSendImage={handleSuccessNewPhoto}/>

            <Modal
                isOpen={modalCenter}
                toggle={() => setModalCenter(false)}
                centered={true}
            >
                <ModalHeader toggle={() => setModalCenter(false)}
                style={{backgroundColor:"#ed4c4b", minHeight:'120px'}}>
                <h3 style={{color:"#fff"}}>Atenção!</h3>
                </ModalHeader>
                <ModalBody style={{textAlign:'center'}}>
                <p>Valide seu cadastro para utilizar esta funcionalidade.</p>
                <br></br>
                <Button onClick={() => {
                window.open('./editar-perfil', '_parent')
                }}>Validar agora</Button>
                </ModalBody>
            </Modal>

            {deleteModal ? (
                <SweetAlert
                    title="Atenção!"
                    warning
                    showCancel
                    confirmBtnText="Tenho certeza"
                    cancelBtnText="Cancelar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={handleDelete}
                    onCancel={() => {
                        setDeleteError(true)
                        setDeleteModal(false)
                    }}
                >
                    <p className="alertText">Você tem certeza que deseja deletar este pedido de imóvel?</p>
                </SweetAlert>
            ) : null}

            {deleteSuccess ? (
                <SweetAlert
                    success
                    title={'Deletado'}
                    onConfirm={() => {
                        setDeleteSuccess(false)
                    }}
                >
                    <p>Pedido excluído com sucesso!</p>
                </SweetAlert>
            ) : null}

            {deleteError ? (
                <SweetAlert
                    error
                    title={'Cancelado'}
                    onConfirm={() => {
                        setDeleteError(false)
                    }}
                >
                    <p>Seu pedido ainda está cadastrado.</p>
                </SweetAlert>
          ) : null}

        </Col>
        </>
    );
}