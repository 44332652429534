import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Component } from "react";
import Axios from 'axios';
import { Badge, Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


import moment from 'moment';
require('moment/locale/pt.js');

class EnterprisesAdminList extends Component {
    constructor(props) {
      super(props);

      this.state = {
        breadcrumbItems: [
          { title: "Fast Sale", link: "/dashboard" },
          { title: "Empreendimentos", link: "#" },
      ],
      imoveis:[],
      allImoveis:[],
      modal_center:false,
      confirm_both: false,
      success_dlg: false,
      error_dlg:false,
      title:'',
      update_error: false,
      logo: null,
      viewport:[
        {
          dataField: "mobile",
          text: "Imóveis"
        },
      ],
      }
      this.tog_center = this.tog_center.bind(this);
      this.createEnterprise = this.createEnterprise.bind(this);
    }

    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
    }
    
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    componentDidMount(){

        document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
          type: localStorage.getItem('access'),
          user_id: localStorage.getItem('id'),
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {

            if(localStorage.getItem('access') === 'A'){

          Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises/',
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({imoveis: response.data})
            this.setState({allImoveis: response.data})
            setTimeout(() => {
              document.getElementById('load').classList.remove('active')
            }, 300);

            if(window.screen.width > 768){
              this.setState({viewport: 
                [
                  {
                    dataField: "status",
                    text: "Status"
                  },
                  {
                    dataField: "image",
                    text: "Foto"
                  },
                  {
                    dataField: "title",
                    text: "Nome"
                  },
                  {
                    dataField: "created_at",
                    classes:"formatDate",
                    text: "Cadastro"
                  },
                  {
                    dataField: "delivery",
                    text: "Entrega"
                  },
                  {
                    dataField: "progress",
                    text: "Andamento"
                  },
                  {
                    dataField: "construction",
                    text: "Construtora"
                  },
                  {
                    dataField: "workflow",
                    text: "WorkFlow"
                  },
                  {
                    dataField: "edit",
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => { 
                        if(localStorage.getItem('level') !== '2'){
                          localStorage.setItem('edit_enterprise_sku', row.sku)
                          setTimeout(() => {
                            window.open("/perfil-empreendimento?id=" + row.id , '_parent')
                          }, 300);
                        }
                      }
                    },
                    text: "Editar"
                  },
                  {
                    dataField: "delete",
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => { 
                        if(localStorage.getItem('level') !== '2'){
                          this.setState({ confirm_both: true })
                          this.setState({ delete_id: row.id })
                          this.setState({ delete_sku: row.title })
                        }
                      },
                    },
                    text: "Excluir"
                  },
                ],
              })
            }

          })

        }else{
          setTimeout(() => {
            window.open("/dashboard", '_parent')
          }, 300);
        }
      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }

    optionchange(event) {
		var count = event.target.value.length;
		if (count > 0) {
			this.setState({ disbadge: true });
		} else {
			this.setState({ disbadge: false });
		}
		if (count > 99) {
			this.setState({ advanceclass: "badgecountextra" });
		} else {
			this.setState({ advanceclass: "badgecount" });
		}
		this.setState({ optioncount: event.target.value.length });
	}

    createSlug(title) {
        let slug = title.toLowerCase().replace(/[^\w\s-]/g, '');
        slug = slug.replace(/\s+/g, '-');
        return slug;
    }

    createEnterprise(event) {

        const data = new FormData()

        data.append('title', this.state.title)
        data.append('slug', this.createSlug(this.state.title))
        data.append('construction_id', localStorage.getItem('id'))
        data.append('status', 0)
        data.append('workflow', 11)
        if(this.state.logo){
            data.append('logo', this.state.logo)
        }

         Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/new-enterprise',
            data, {
           headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
         }).then(response => {
            setTimeout(() => {
                window.open('./../perfil-empreendimento?id=' + response.data.data.id, '_parent')
            }, 300);
         })
         .catch(response => {
            this.setState({update_error: true})
         })
    }

    render(){

        const {imoveis, viewport} = this.state;

    const options = {
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5 resultados p/ página', value: 5
        }, {
          text: '10 resultados p/ página', value: 10
        }, {
          text: 'Todos empreendimetos', value: imoveis.length
        }]

    };

    const imoveisObject = []

    {imoveis.map(imovel => {
      console.log(imovel?.construction?.name)
      const novoImovel = {
        id: imovel?.id,
        edit: <i className="ri-edit-line"></i>,
        delete: <i className="ri-delete-bin-6-line"></i>,
        delivery: imovel?.delivery,
        title: imovel?.title,
        construction: imovel?.construction?.name,
        image:<img src={imovel?.photos?.filter(photo => photo.order === 0)[0]?.small_image} style={{
          objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
        }}></img>,
        created_at: moment(imovel?.created_at).format('DD/MM/YYYY'),
        progress: imovel?.progress + ' %',
        fsProperties:<div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
      </div>,
        workflow: imovel?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
        : imovel?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
        : imovel?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
        : imovel?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
        : imovel?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
        : imovel?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill">Repetido</Badge>
        : imovel?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
        : imovel?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
        : imovel?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
        : imovel?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
        : imovel?.workflow === 11 ? <Badge className="badge-soft-dark rounded-pill me-1">Rascunho</Badge>
        : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>,
        status: imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>,
        slug: imovel?.slug,
        mobile: <Container style={{padding:'0'}}>
          <Row>
            <Col className="col-auto">Status: {imovel?.status === 1 ? <i className="ri-checkbox-blank-circle-fill" style={{color:'#62c90e'}}></i> : <i className="ri-checkbox-blank-circle-fill" style={{color:'#da2c3e'}}></i>}</Col>
            <Col style={{textAlign:'right'}}>{imovel?.workflow === 0 ? <Badge className="bg-warning me-1 rounded-pill">Pendente</Badge>
            : imovel?.workflow === 1 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fotos</Badge>
            : imovel?.workflow === 2 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Aut. Venda</Badge>
            : imovel?.workflow === 3 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Prop.</Badge>
            : imovel?.workflow === 4 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
            : imovel?.workflow === 11 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Fora do padrão</Badge>
            : imovel?.workflow === 5 ? <Badge className="bg-dark me-1 rounded-pill">Repetido</Badge>
            : imovel?.workflow === 6 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (ext)</Badge>
            : imovel?.workflow === 8 ? <Badge className="badge-soft-dark rounded-pill me-1">Vendido (fs)</Badge>
            : imovel?.workflow === 9 ? <Badge className="bg-danger me-1 rounded-pill">Rep. Imóvel</Badge>
            : imovel?.workflow === 10 ? <Badge className="badge-soft-dark rounded-pill me-1">Bloqueado</Badge>
            : imovel?.workflow === 11 ? <Badge className="badge-soft-dark rounded-pill me-1">Rascunho</Badge>
            : <Badge className="bg-success me-1 rounded-pill">Aprovado</Badge>}</Col>
          </Row>
          <Row style={{marginTop:'15px'}}>
            <Col className="col-auto">
              <img src={imovel?.photos?.filter(photo => photo.order === 0)[0]?.small_image} style={{
                objectFit:'cover', width:'100px', height:'80px', borderRadius:'5px'
              }}></img>
            </Col>
            <Col>
            <p style={{lineHeight:'1.2'}}>{imovel?.sku}</p>
            <small>{imovel?.title}</small><br></br>
            <small><b>{imovel?.construction?.name}</b></small>
            </Col>
          </Row>
          <Row style={{marginTop:'15px', backgroundColor:'#f4f4f4', borderRadius:'5px', alignItems:'center'}}>
            <Col xs={8}><span style={{fontSize:'12px', fontWeight:'500'}}>Ações:</span></Col>
            <Col style={{textAlign:'right', padding: '0 5px'}}><i className="ri-edit-line" onClick={() => {
               localStorage.setItem('edit_enterprise_sku', imovel.sku)
               setTimeout(() => {
                  window.open("/perfil-empreendimento?id=" + imovel.id , '_parent')
               }, 300);
            }}></i></Col>
            <Col style={{textAlign:'right', padding: '0 5px'}}><i className="ri-delete-bin-6-line" onClick={() => {
              this.setState({ confirm_both: true })
              this.setState({ delete_id: imovel.id })
              this.setState({ delete_sku: imovel.sku })
            }}></i></Col>
          </Row>
        </Container>
      }
      imoveisObject.push(novoImovel); 
    })}


        return(
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Empreendimentos" breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {localStorage.getItem('level') !== '2' &&
                                      <Row style={{justifyContent:'flex-end'}}>
                                          <Col className="col-auto"><Button onClick={this.tog_center}>Novo empreendimento</Button></Col>
                                      </Row>
                                    }
                                    <h4 className="card-title mb-4">Seus empreendimentos cadastrados</h4>
                                    <div className="table-responsive">
                                    {imoveis.length > 0 ?
                                        <BootstrapTable
                                        keyField='id'
                                        data={imoveisObject}
                                        columns={viewport}
                                        pagination={paginationFactory(options)}
                                        // selectRow={false}
                                        />
                                    :
                                        <p>Você ainda não tem empreendimentos cadastrados.</p>
                                    }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                
                <Modal
                    isOpen={this.state.modal_center}
                    toggle={this.tog_center}
                    size="lg"
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modal_center: false })}>
                    Novo empreendimento
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{minWidth:'400px'}}>
                            <Col xs={12}>
                                <AvForm className="cardBody needs-validation" ref={this.validateRef}>
                                    <AvGroup>
                                        <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Nome do Empreendimento</Label>
                                        <AvField
                                            name="title"
                                            placeholder="Nome do empreendimento"
                                            type="text"
                                            maxLength="100"
                                            errorMessage="Campo obrigatório."
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            value={this.state.title}
                                            onChange={(e) => {
                                                this.optionchange(e)
                                                this.setState({title: e.target.value})
                                            }}
                                        />
                                        {this.state.disbadge ? (
                                            <span className={this.state.advanceclass}>
                                            <span className="badge badge-success">Você digitou {this.state.optioncount} de 100 caracteres permitidos.</span>
                                            </span>
                                        ) : null}
                                    </AvGroup>
                                    <div className="input-group" style={{marginTop:'10px'}}>
                                      <Label htmlFor="nomeEmpreendimento" className="col-md-12 col-form-label">Logo do Empreendimento (jgp, jpeg ou png)</Label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            id="customFile"
                                            onChange={(e) => this.setState({logo: e.target.files[0]})}
                                        />
                                    </div>
                                </AvForm>
                            </Col>
                        </Row>
                        <Row style={{gap:'0px', justifyContent:'flex-end', marginTop:'20px' }}>
                            <Col className="col-auto">
                                <Button onClick={() => this.setState({ modal_center: false })} className="btn-dark">Cancelar</Button>
                            </Col>
                            <Col className="col-auto">
                                {this.state.title !== '' ?
                                <Button onClick={this.createEnterprise}>Criar</Button>
                                :
                                <Button disabled>Criar</Button>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <div id="load" className="load">
                    <Spinner className="me-2" color="success" />
                </div>

                {this.state.update_error ? (
                    <SweetAlert
                        title="Este nome de empreendimento já existe."
                        timeout={3000}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => this.setState({ update_error: false })}
                    ></SweetAlert>
                ) : null}

        {this.state.confirm_both ? (
          <SweetAlert
            title="Atenção!"
            warning
            showCancel
            confirmBtnText="Tenho certeza"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>{
              Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/property-enterprises-delete/' +  this.state.delete_id, {},
              {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(response => {
                this.setState({
                  confirm_both: false,
                  success_dlg: true,
                  dynamic_title: "Deletado",
                  dynamic_description: "Seu empreendimento foi deletado."
                })
              })
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelado",
                dynamic_description: "Seu empreendimento ainda está cadastrado."
              })
            }
          >
            <p className="alertText">Você tem certeza que deseja deletar o emprendimento <b>{this.state.delete_sku}</b>?</p>
          </SweetAlert>
        ) : null}
          {this.state.success_dlg ? (
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => {
                this.setState({ success_dlg: false })
                window.location.reload()
              }}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}

          {this.state.error_dlg ? (
            <SweetAlert
              error
              title={this.state.dynamic_title}
              onConfirm={() => this.setState({ error_dlg: false })}
            >
              {this.state.dynamic_description}
            </SweetAlert>
          ) : null}
            </div>
        )
    }

}

export default EnterprisesAdminList;