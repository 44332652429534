import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Fire from './../../assets/images/Icons/fogooutlet.png';
import {useNavigate} from 'react-router-dom';

import './../../assets/scss/custom/components/_imovelCard.scss';

import Premium from './../../assets/images/premium.png';
import { Button, Card, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Slidewithfade from '../../pages/Ui/CarouselTypes/slidewithfade';
// import Verificado from './../../Assets/Icons/verificado.png';

import ImovelCardCarousel from './Carousel';
import Axios from 'axios';

function ImovelCard({imovel, premium, favoritos}) {
  const [favoritado, setFavoritado] = useState(favoritos.includes(imovel.id));
  const [modalCenter, setModalCenter] = useState(false)
  const [userPendent, setUserPendent] = useState(localStorage.getItem('status'))

  const handleFavoritarClick = () => {
    setFavoritado(!favoritado); // alterna entre favoritado e não favoritado

    const endpoint = favoritado
      ? "/api/auth/favorite-delete"
      : "/api/auth/favorite";

    Axios.post(process.env.REACT_APP_API + endpoint, {
      user_id: parseInt(localStorage.getItem("id")),
      type: localStorage.getItem("access"),
      property_id: imovel.id,
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
  };

  const date = new Date()
  date.setDate(date.getDate() - 7)

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const calcUpdate = (date) => {
    const lastUpdate = new Date(date)
    const dataAtual = new Date()
    const diferenca = dataAtual - lastUpdate;
    const total = Math.floor(diferenca / (1000 * 60 * 60 * 24));
  
    return total;
  }
  
  return (
    <>
      {(userPendent !== '3' && userPendent !== '2') ?
      // ((Date.parse(imovel?.created_at) > date.getTime() || imovel?.id === 2391) && premium == 0 &&  (localStorage.getItem('access') === 'C' ? imovel.broker_id !== parseInt(localStorage.getItem('id')) : imovel.real_estate !== parseInt(localStorage.getItem('id')))) ? 
      // (((imovel?.sell_contract_type !== 'FS Parc' && imovel?.sell_contract_type !== 'FS Parc (sc)') || imovel?.id === 2391) && premium == 0 && (localStorage.getItem('access') === 'C' ? imovel.broker_id !== parseInt(localStorage.getItem('id')) : imovel.real_estate !== parseInt(localStorage.getItem('id')))) ? 
      // <Card className="ImovelCard" style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
      //   <Container>
      //   <Link to={"../home"} target="_parent">
      //     <Row className="slider-row" >
      //         {imovel?.opportunity === 1 &&
      //           <img className="fire" src={Fire}></img>
      //         }
      //         {(imovel?.sell_contract_type !== 'FS Parc' && imovel?.sell_contract_type !== 'FS Parc (sc)') ?
      //           imovel?.opportunity === 1 ?
      //           <div className="premiumFlag"><i className="ri-star-s-fill"></i>Premium</div>
      //           :
      //           <div className="premiumFlag" style={{left:'10px'}}><i className="ri-star-s-fill"></i>Premium</div>
      //           :
      //           <></>
      //         }
      //         <Row className="comissionContainer">

      //           {imovel.sell_contract_type === 'FS Imob (antigo)' &&
      //               <>
      //                 <Col xs={3} className="until">Até</Col>
      //                 <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
      //                 <Col xs={6} className="comission">
      //                   <span className="fs">Contrato FS</span>
      //                 </Col>
      //               </>
      //             }
      //             {imovel.sell_contract_type === 'FS Parc' &&
      //               <>
      //                 <Col xs={12} className="comission">
      //                   <span className="parc">Parceria 50/50</span>
      //                 </Col>
      //               </>
                    
      //             }
      //             {imovel.sell_contract_type === 'FS Parc (sc)' &&
      //               <>
      //                 <Col xs={12} className="comission">
      //                   <span className="parc">Parceria 50/50</span>
      //                 </Col>
      //               </>
                    
      //             }
      //             {(imovel.sell_contract_type === 'FS Corr'
      //             || imovel.sell_contract_type === 'FS Imob') &&
      //               <>
      //                 <Col xs={3} className="until">Até</Col>
      //                 <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
      //                 <Col xs={6} className="comission">
      //                   <span className="fs">Contrato FS</span>
      //                 </Col>
      //               </>
                    
      //             }
      //             {imovel.sell_contract_type === 'FS Prop' &&
      //               <>
      //                 <Col xs={3} className="until">Até</Col>
      //                 <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
      //                 <Col xs={6} className="comission">
      //                   <span className="fs">Contrato FS</span>
      //                 </Col>
      //               </>
      //             }
      //         </Row>
      //         <div style={{backgroundColor:'#333',
      //           height:'180px',
      //         }}>
      //           <i className="ri-lock-2-fill" style={{zIndex: '3', position:'absolute', color:'#fff', fontSize:'65px', left:'38%', top:'25px'}}></i>
      //           <p style={{
      //             zIndex: '3', position:'absolute', left:'0px', top:'110px', color:'#fff', textAlign: 'center',
      //           }}>Este imóvel está disponível apenas para assinantes.</p>
      //           <div style={{backgroundImage:'url(' +  imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1)[0]?.small_image + ')',
      //             width:'100%',
      //             height:'100%',
      //             borderRadius: '8px',
      //             backgroundSize:'130% 130%',
      //             backgroundPosition:'center center',
      //             filter: 'blur(4px)',
      //             opacity:0.4,
      //         }}></div></div>
      //     </Row>
      //     <Row>
      //       <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
      //     </Row>
      //     <Row>
      //       <Col xs={10} style={{padding:'0'}}>
      //         <Row className="skills">
      //           {(imovel.dorms_number && imovel.dorms_number !== '0') ?
      //             <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
      //             :
      //             <></>
      //           }
      //           {((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0')) ?
      //             <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
      //             :
      //             <></>
      //           }
      //           {imovel?.parking_spots ? <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col> : <></>}
      //           <Col><i className="ri-ruler-line"></i><p>{
      //                 (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
      //                 (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
      //                 (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
      //                 }m²</p></Col>
      //         </Row>
      //       </Col>
      //     </Row>
      //     </Link>  
      //     <Row>
      //       <div className="line"></div>
      //     </Row>
      //     <Row>
      //       <Col xs={12} style={{padding:'0 10px 0 0'}}>
      //         {imovel?.sell_price && imovel?.sell_price !== 0 ?
      //           <div className="offer-container">
      //             <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
      //             <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
      //           </div>
      //         :
      //           <div className="offer-container">
      //             <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
      //           </div>
      //         }
      //       </Col>
      //     </Row>
      //     <Row>
      //       <Col><Button style={{width:'100%', marginTop:'15px'}}>Quero ser Premium</Button></Col> 
      //     </Row>
      //   </Container>   
      // </Card>
      // :
      <Card className={`ImovelCard ${imovel?.workflow === 13 ? 'no-update' : ''}  ${imovel?.id === 3341 ? 'high-imovel' : ''}`} style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
        <span className="high-border"></span>
        <Container>
        <Link to={"../imovel/" + imovel.slug} target="_parent">
          <Row className="slider-row">
              {imovel?.opportunity === 1 &&
                <img className="fire" src={Fire}></img>
              }
              {imovel?.workflow === 13 &&
                <span className="desatualizado">
                  <p>Pendente de atualização</p>
                  <p>Última atualização há {calcUpdate(imovel?.last_update)} dias</p>
                </span>
              }
              {(imovel?.sell_contract_type !== 'FS Parc' && imovel?.sell_contract_type !== 'FS Parc (sc)') ?
                imovel?.opportunity === 1 ?
                <div className="premiumFlag"><i className="ri-star-s-fill"></i>Premium</div>
                :
                <div className="premiumFlag" style={{left:'10px'}}><i className="ri-star-s-fill"></i>Premium</div>
                :
                <></>
              }
              <Row className="comissionContainer">
              {imovel.sell_contract_type === 'FS Imob (antigo)' &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                  }
                  {imovel.sell_contract_type === 'FS Parc' &&
                    <>
                      <Col xs={12} className="comission">
                        <span className="parc">Parceria 50/50</span>
                      </Col>
                    </>
                    
                  }
                  {imovel.sell_contract_type === 'FS Parc (sc)' &&
                    <>
                      <Col xs={12} className="comission">
                        <span className="parc">Parceria 50/50</span>
                      </Col>
                    </>
                    
                  }
                  {(imovel.sell_contract_type === 'FS Corr'
                  || imovel.sell_contract_type === 'FS Imob') &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                    
                  }
                  {imovel.sell_contract_type === 'FS Prop' &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                  }
              </Row>
              <ImovelCardCarousel  photos={imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1).slice(0,4)}/>
          </Row>
          <Row>
            <div className='ap'>
              <p>{imovel?.sku}</p>
              {premium === 1 &&
                <h5 style={{fontSize:'12px'}}>
                  {
                    imovel?.address_properties?.enterprise && imovel?.address_properties?.enterprise !== "null" ? 
                      toTitleCase(imovel?.address_properties?.enterprise)
                      : <></>
                  }
                </h5>
              }
            </div>
          
            <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imovel?.title}</h3>
            <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
          </Row>
          <Row>
            <Col xs={10} style={{padding:'0'}}>
              <Row className="skills">
                {(imovel.dorms_number && imovel.dorms_number !== '0') ?
                  <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
                  :
                  <></>
                }
                {((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0')) ?
                  <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
                  :
                  <></>
                }
                {(imovel.parking_spots && imovel.parking_spots !== '0') ?
                  <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col>
                :
                  <></>
                }
                <Col><i className="ri-ruler-line"></i><p>{
                      (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                      (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                      (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                      }m²</p></Col>
              </Row>
            </Col>
          </Row>
          </Link>  
          <Row>
            <div className="line"></div>
          </Row>
          <Row>
            <Col xs={10} style={{padding:'0 10px 0 0'}}>
              {imovel?.sell_price && imovel?.sell_price !== 0 ?
                <div className="offer-container">
                  <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                  <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                </div>
              :
                <div className="offer-container">
                  <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                </div>
              }
            </Col>
            <Col xs={2} className="like">
              <i
                className={favoritado ? "ri-heart-fill" : "ri-heart-line"}
                style={{ color: "#0f6e43", cursor: 'pointer' }}
                onClick={handleFavoritarClick}
              />
            </Col>
          </Row>
        </Container>   
      </Card>
  :
  //Usuário sem documentos
    <Card className={`ImovelCard`} style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}} onClick={() => setModalCenter(true)}>
      <Container>
        <Row className="slider-row">
            {imovel?.opportunity === 1 &&
              <img className="fire" src={Fire}></img>
            }
            {(Date.parse(imovel?.created_at) > date.getTime()) ?
              imovel?.opportunity === 1 ?
              <div className="premiumFlag"><i className="ri-star-s-fill"></i>Premium</div>
              :
              <div className="premiumFlag" style={{left:'10px'}}><i className="ri-star-s-fill"></i>Premium</div>
              :
              <></>
            }
            <Row className="comissionContainer">
            {imovel.sell_contract_type === 'FS Imob (antigo)' &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                  }
                  {imovel.sell_contract_type === 'FS Parc' &&
                    <>
                      <Col xs={12} className="comission">
                        <span className="parc">Parceria 50/50</span>
                      </Col>
                    </>
                    
                  }
                  {imovel.sell_contract_type === 'FS Parc (sc)' &&
                    <>
                      <Col xs={12} className="comission">
                        <span className="parc">Parceria 50/50</span>
                      </Col>
                    </>
                    
                  }
                  {(imovel.sell_contract_type === 'FS Corr'
                  || imovel.sell_contract_type === 'FS Imob') &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                    
                  }
                  {imovel.sell_contract_type === 'FS Prop' &&
                    <>
                      <Col xs={3} className="until">Até</Col>
                      <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
                      <Col xs={6} className="comission">
                        <span className="fs">Contrato FS</span>
                      </Col>
                    </>
                  }
            </Row>
            <ImovelCardCarousel  photos={imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1).slice(0,4)}/>
        </Row>
        <Row>
          <div className='ap'>
            <p>{imovel?.sku}</p>
            {premium === 1 &&
              <h5 style={{fontSize:'12px'}}>
                {
                  imovel?.address_properties?.enterprise && imovel?.address_properties?.enterprise !== "null" ? 
                    toTitleCase(imovel?.address_properties?.enterprise)
                    : <></>
                }
              </h5>
            }
          </div>
        
          <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imovel?.title}</h3>
          <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
        </Row>
        <Row>
          <Col xs={10} style={{padding:'0'}}>
            <Row className="skills">
              {(imovel.dorms_number && imovel.dorms_number !== '0') ?
                <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
                :
                <></>
              }
              {((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0')) &&
                <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number && imovel?.dorms_number !== 0 ? imovel?.dorms_number : imovel?.suites_number}</p></Col>
              }
              <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col>
              <Col><i className="ri-ruler-line"></i><p>{
                    (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                    (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                    }m²</p></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <div className="line"></div>
        </Row>
        <Row>
          <Col xs={10} style={{padding:'0 10px 0 0'}}>
            {imovel?.sell_price && imovel?.sell_price !== 0 ?
              <div className="offer-container">
                <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
                <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
              </div>
            :
              <div className="offer-container">
                <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
              </div>
            }
          </Col>
          <Col xs={2} className="like">
            <i
              className={favoritado ? "ri-heart-fill" : "ri-heart-line"}
              style={{ color: "#0f6e43", cursor: 'pointer' }}
              onClick={handleFavoritarClick}
            />
          </Col>
        </Row>
      </Container>   
    </Card>
    // <Card className="ImovelCard" style={{boxShadow:'0 0 8px rgba(0,0,0,0.3)'}}>
    //     <Container>
    //       <Row className="slider-row" >
    //           <Row className="comissionContainer">
    //             <Col xs={3} className="until">Até</Col>
    //             <Col xs={3} className="comissionPercentage">{parseFloat(imovel?.commission_percentage_on_sale)}<small>%</small></Col>
    //             <Col xs={6} className="comission">
    //             {imovel.sell_contract_type === 'FS Imob (antigo)' &&
    //                 <span className="fs">Contrato FS</span>
    //               }
    //               {imovel.sell_contract_type === 'FS Parc' &&
    //                 <span className="parc">Parceria</span>
    //               }
    //               {imovel.sell_contract_type === 'FS Parc (sc)' &&
    //                 <span className="parc">Parceria</span>
    //               }
    //               {(imovel.sell_contract_type === 'FS Corr'
    //               || imovel.sell_contract_type === 'FS Imob') &&
    //                 <span className="fs">Contrato FS</span>
    //               }
    //               {imovel.sell_contract_type === 'FS Prop' &&
    //                 <span className="fs">Contrato FS</span>
    //               }
    //             </Col>
    //           </Row>
    //           <ImovelCardCarousel  photos={imovel?.photos?.sort((c1, c2) => (c1?.order > c2?.order) ? 1 : -1).slice(0,4)}/>
    //             <p style={{
    //               zIndex: '3', fontSize:'18px', position:'absolute', left:'0', top:'85px', color:'#fff', backgroundColor:'#3E5E3F', textAlign:'center'
    //             }}>Imóvel vendido!</p>
    //       </Row>
    //       <Row>
    //         <h3 style={{height:'41px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{imovel?.title}</h3>
    //         <p><i className="ri-map-pin-fill" style={{marginRight:'3px'}}></i>{imovel?.address_properties?.city}</p>
    //       </Row>
    //       <Row>
    //         <Col xs={10} style={{padding:'0'}}>
    //           <Row className="skills">
    //             <Col><i className="ri-hotel-bed-fill"></i><p>{imovel?.dorms_number}</p></Col>
    //             <Col><i className="ri-car-line"></i><p>{imovel?.parking_spots}</p></Col>
    //             <Col><i className="ri-ruler-line"></i><p>{
    //                   (imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
    //                   (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
    //                   (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
    //                   }m²</p></Col>
    //           </Row>
    //         </Col>
    //       </Row> 
    //       <Row>
    //         <div className="line"></div>
    //       </Row>
    //       <Row>
    //         <Col xs={12} style={{padding:'0 10px 0 0'}}>
    //           {imovel?.sell_price && imovel?.sell_price !== 0 ?
    //             <div className="offer-container">
    //               <div className="old-price"><span>R${(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
    //               <div className="offer-price"><small>R$</small><span>{(imovel?.sell_price / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
    //             </div>
    //           :
    //             <div className="offer-container">
    //               <div className="offer-price"><small>R$</small><span>{(imovel?.sale_value / 100).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span></div>
    //             </div>
    //           }
    //         </Col>
    //       </Row>
    //     </Container>   
    //   </Card>
  }
    <Modal
        isOpen={modalCenter}
        toggle={() => setModalCenter(false)}
        centered={true}
    >
        <ModalHeader toggle={() => setModalCenter(false)}
        style={{backgroundColor:"#ed4c4b", minHeight:'120px'}}>
        <h3 style={{color:"#fff"}}>Atenção!</h3>
        </ModalHeader>
        <ModalBody style={{textAlign:'center'}}>
        <p>Valide seu cadastro para visualizar todas as informações do imóvel.</p>
        <br></br>
        <Button onClick={() => {
          window.open('./editar-perfil', '_parent')
        }}>Validar agora</Button>
        </ModalBody>
    </Modal>
  </>
  )
}

export default ImovelCard;