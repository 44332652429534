import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Row, Container, Input, Label, Badge, Spinner, Button, CardHeader, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, CardText } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Breadcrumbs from "../../components/Common/Breadcrumb"

import './../../assets/scss/custom/components/_singleProperty.scss'

import moment from 'moment';
import ReactSelect from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import Fire from './../../assets/images/Icons/fogooutlet.png';
import SingleImovelCardCarousel from './Carousel';
import Maps from './Maps';
import SingleAction from './SingleAction';
import ContractUse from './ContractUse';
import { saveAs } from 'file-saver';

import Integrar from './../../assets/images/popups/integrar.png'
import IntegrarMobile from './../../assets/images/popups/integrarMobile.png'
import Download from './../../assets/images/popups/download.png'
import Compartilhar from './../../assets/images/popups/compartilhar.png'

import CanalPro from './../../assets/images/Icons/canalpro.png'
import ChavesMao from './../../assets/images/Icons/chavesnamao.png'
import SeuSite from './../../assets/images/Icons/seusite.png'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";
// import "swiper/scrollbar.css"

import { Navigation } from "swiper"
import ImovelCard from '../../components/ImovelCard/ImovelCard';

import ParceriaAceita from "./../../assets/images/parceria-aceita.png";
import ParceriaNaoceita from "./../../assets/images/parceria-nao-aceita.png";
import NoPremiumProperty from '../../components/noPremium/noPremiumProperty';
import NoDocsProperty from '../../components/noPremium/noDocsProperty';

require('moment/locale/pt.js');

class SingleProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imovel:[],
      col1: true,
			col2: false,
			col3: false,
			col5: true,
      modal_large: false,
      favoritado: false,
      acceptContract: false,
      favoritos:[],
      relacionados:[],
      modal_integration: false,
      modal_premium: false,
      modal_download: false,
      modal_share_options:false,
      modal_share: false,
      verifyContract: false,
      verifyPremium: false,
      integrationChannel:null,
      integrationType:0,
      integration_success:false,
      origem:null,
      integration_error:false,
      copySuccess:'',
      verifyFit: false,
      url:"",
      verifyMktFs: true,
      verifyDocs: true,
      openEnterpriseErrorModal:false,
      breadcrumbItems: [
        { title: "Fast Sale", link: "/dashboard" },
        { title: "Imóveis", link: "/imoveis" },
        { title: '', link: "#" },
    ],
    }
    this.handleContractClosed = this.handleContractClosed.bind(this);
    this.tog_large = this.tog_large.bind(this);
    this.tog_integration = this.tog_integration.bind(this)
    this.tog_premium = this.tog_premium.bind(this)
    this.tog_share_options = this.tog_share_options.bind(this)
    this.tog_download = this.tog_download.bind(this)
    this.tog_share = this.tog_share.bind(this)
    this.t_col1 = this.t_col1.bind(this);
		this.t_col2 = this.t_col2.bind(this);
		this.t_col3 = this.t_col3.bind(this);
		this.t_col4 = this.t_col4.bind(this);
		this.t_col5 = this.t_col5.bind(this);
  }


  componentDidMount(){
    let slug = this.props.match.params

    const searchOwnerProperty = window.location.search;

      if(searchOwnerProperty === '?owner'){
        this.setState({url : "/api/auth/property-slug-pending/"})
      }else{
        this.setState({url: "/api/auth/property-slug/"})
      }

      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {

          Axios.post(process.env.REACT_APP_API + this.state.url + slug.slug, {},
          {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
            this.setState({imovel: response.data})
            localStorage.setItem('view_property_sku', response.data.sku)

            //Verifica usuário sem documentos
            if(localStorage.getItem('status') === '3' || localStorage.getItem('status') === '2'){
              this.setState({verifyDocs: false})
            }


            this.setState({breadcrumbItems: [
              { title: "Fast Sale", link: "/dashboard" }, 
              { title: "Imóveis", link: "/imoveis" },
              { title: response.data.sku, link: "#" },
            ]})

            Axios.post(process.env.REACT_APP_API + '/api/auth/my-favorite', {
              type: localStorage.getItem('access'),
              user_id: localStorage.getItem('id')
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(resp => {
                  resp.data.map((imovel, index) => {
                      this.state.favoritos.push(imovel.property_id)
                  })
                  setTimeout(() => {
                    if(this.state.favoritos.includes(response.data.id))
                    this.setState({ favoritado: true });
                  }, 500);
            })
            if(response.data.broker_id != localStorage.getItem('id')){
              Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
                type:localStorage.getItem('access'),
                user_id:localStorage.getItem('id'),
                property_id:response.data.id
                }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
              .then(resp => {
                this.setState({verifyContract: true})
                localStorage.removeItem("history_url")
              })
              .catch(resp => {
                this.setState({verifyContract: false})
                localStorage.removeItem("history_url")

                if(searchOwnerProperty === '?owner'){
                  this.setState({verifyContract: true})
                }
              })
            }else{
              this.setState({verifyContract: true})
              localStorage.removeItem("history_url")
            }

            Axios.get(process.env.REACT_APP_API + '/api/auth/related-properties/' + response.data.id,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(resp => {
              this.setState({relacionados : resp.data})
            })
            .catch(resp => {
              // this.setState({verifyContract: false})
            })
            // 

            Axios.post(process.env.REACT_APP_API + '/api/auth/visit-history', {
              type:localStorage.getItem('access'),
              user_id:localStorage.getItem('id'),
              property_id:response.data.id
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})

            Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
              type:localStorage.getItem('access'),
              user_id:localStorage.getItem('id')
              }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
              if(localStorage.getItem('access') === 'A' || localStorage.getItem('access') === 'T'){
                this.setState({verifyPremium: true})
              }else{
                if(res.data.premium === 1) {
                  this.setState({verifyPremium: true})
                } else if(res.data.premium === 2) {
                  this.setState({verifyFit: true})
                }
              }

            })
            .catch(res => {
              this.setState({verifyPremium: false})
              if(localStorage.getItem('access') !== 'A' && localStorage.getItem('access') !== 'T'){
                const date = new Date()
                date.setDate(date.getDate() - 7)
                if(((response.data?.sell_contract_type !== 'FS Parc' && response.data?.sell_contract_type !== 'FS Parc (sc)') || response.data?.id === 2391) && (localStorage.getItem('access') === 'C' ? response.data.broker_id !== parseInt(localStorage.getItem('id')) : response.data.real_estate !== parseInt(localStorage.getItem('id')))){
                  // setTimeout(() => {
                  //   window.open("/dashboard", '_parent')
                  // }, 300)
                  this.setState({verifyMktFs: false})
                }
              }
            })
          })
          .catch(response =>{
            localStorage.removeItem("history_url")
            setTimeout(() => {
              window.open("/404", '_parent')
            }, 300);})
        })
        .catch(response =>{
          setTimeout(() => {
            window.open("/login", '_parent')
          }, 300);
        })
  }


  handleFavoritarClick = () => {
    const { favoritado, imovel } = this.state;
    const endpoint = favoritado
      ? "/api/auth/favorite-delete"
      : "/api/auth/favorite";
    this.setState({ favoritado: !favoritado });
    Axios.post(process.env.REACT_APP_API + endpoint, {
      user_id: parseInt(localStorage.getItem("id")),
      type: localStorage.getItem("access"),
      property_id: imovel.id
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    })
  };

  t_col1() {
		this.setState({ col1: !this.state.col1, col2: false, col3: false, col4: false });
	}
	t_col2() {
		this.setState({ col2: !this.state.col2, col1: false, col3: false, col4: false });
	}
	t_col3() {
		this.setState({ col3: !this.state.col3, col1: false, col2: false, col4: false });
	}
  t_col4() {
		this.setState({ col4: !this.state.col4, col1: false, col2: false, col3: false });
	}
	t_col5() {
		this.setState({ col5: !this.state.col5 });
	}

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large
    }));
    this.removeBodyCss();
  }
  tog_integration(){
    this.setState(prevState => ({
      modal_integration: !prevState.modal_integration
    }));
    this.removeBodyCss();
  }
  tog_premium(){
    this.setState(prevState => ({
      modal_premium: !prevState.modal_premium
    }));
    this.removeBodyCss();
  }
  tog_share_options(){
    this.setState(prevState => ({
      modal_share_options: !prevState.modal_share_options
    }))
    this.removeBodyCss();
  }
  tog_download(){
    this.setState(prevState => ({
      modal_download: !prevState.modal_download
    }));
    this.removeBodyCss();
  }
  tog_share(){
    this.setState(prevState => ({
      modal_share: !prevState.modal_share
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  handleContractClosed() {
    this.setState({ acceptContract: false });
  }

  handleContractAccepted() {
    if(this.state.imovel?.broker_id != localStorage.getItem('id')){
      this.setState({acceptContract: false})
      this.setState(prevState => ({
        modal_integration: !prevState.modal_integration
      }));
      this.removeBodyCss();
      Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
        type:localStorage.getItem('access'),
        user_id:localStorage.getItem('id'),
        property_id:this.state.imovel.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(resp => {
        this.setState({verifyContract: true})
      })
      .catch(resp => {
        this.setState({verifyContract: false})
      })
    }else{
      this.setState({verifyContract: true})
    }
  }

  handleContractAcceptedFotos() {
    if(this.state.imovel?.broker_id != localStorage.getItem('id')){
      this.setState({acceptContract: false})
      Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
        type:localStorage.getItem('access'),
        user_id:localStorage.getItem('id'),
        property_id:this.state.imovel.id
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(resp => {
        this.setState({verifyContract: true})
      })
      .catch(resp => {
        this.setState({verifyContract: false})
      })
    }else{
      this.setState({verifyContract: true})
    }
  }

  handleContractAcceptedCaptador() {
    if(this.state.imovel?.broker_id != localStorage.getItem('id')){
      this.setState({acceptContract: false})
      Axios.post(process.env.REACT_APP_API + '/api/auth/log-talks', {
        type: localStorage.getItem('access'),
        user_id: localStorage.getItem('id'),
        property_id: this.state.imovel?.id
      },
      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setTimeout(() => {
            window.open(`https://api.whatsapp.com/send?phone=55` + (this.state.imovel?.broker?.phone ? this.state.imovel?.broker?.phone : this.state.imovel?.real_estate?.phone ? this.state.imovel?.real_estate?.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá,%20tenho%20interesse%20no%20` + this.state.imovel?.title + " (" + this.state.imovel.sku + ')', "_blank")
          }, 300);
        })
        Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
          property_id:this.state.imovel.id
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(resp => {
          this.setState({verifyContract: true})
        })
        .catch(resp => {
          this.setState({verifyContract: false})
        })
    }else{
      this.setState({verifyContract: true})
    }
  }

  handleContractAcceptedDownload() {
    this.setState({acceptContract: false})
    if(!this.state.verifyPremium && !this.state.verifyFit){
      //fazer o download COM logo
      this.tog_download()
    }else{
      //fazer o download SEM logo
      document.getElementById('load').classList.add('active')
      const axiosOptions = {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        }
      }
      Axios.post(process.env.REACT_APP_API + '/api/download-media/' + this.state.imovel?.id, {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
        }, axiosOptions)
        .then((response) => {
          const blob = new Blob([response.data], {type: 'application/octet-stream'})
          const filename = this.state.imovel?.title + '(' + this.state.imovel?.sku + ').zip'
          saveAs(blob, filename)
          document.getElementById('load').classList.remove('active')
          const data = new FormData()
          data.append('type', localStorage.getItem('access'));
          data.append('user_id', localStorage.getItem('id'))
          Axios.post(process.env.REACT_APP_API + '/api/download-count/' + this.state.imovel?.id, data)
          document.getElementById('load').classList.remove('active')
        })
        .catch((e) => {
          document.getElementById('load').classList.remove('active')
        })
        if(this.state.imovel?.broker_id != localStorage.getItem('id')){
          Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', { //Contrato de parceria 
            type:localStorage.getItem('access'),
            user_id:localStorage.getItem('id'),
            property_id:this.state.imovel.id
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({verifyContract: true})
          })
          .catch(resp => {
            this.setState({verifyContract: false})
          })
        }else{
          this.setState({verifyContract: true})
        }
    }
  }

  handleContractAcceptedCompartilhar() {
    if(this.state.imovel?.broker_id != localStorage.getItem('id')){
      this.setState({acceptContract: false})
      if(!this.state.verifyPremium && !this.state.verifyFit){
        //fazer o download COM logo
        this.tog_share()
      }else{
        //fazer o download SEM logo
        Axios.post(process.env.REACT_APP_API + '/api/auth/log-shares', {
          type: localStorage.getItem('access'),
          user_id: localStorage.getItem('id'),
          property_id: this.state.imovel?.id
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(response => {
              this.setState({modal_share_options: true})
          })
          Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
            type:localStorage.getItem('access'),
            user_id:localStorage.getItem('id'),
            property_id:this.state.imovel.id
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
          .then(resp => {
            this.setState({verifyContract: true})
          })
          .catch(resp => {
            this.setState({verifyContract: false})
          })
      }
    }else{
      this.setState({verifyContract: true})
    }
  }

  handleVerifyContract = (value) => {
    this.setState({ verifyContract: value });
    
  }

  handleButtonReleaseAll = () => {
    if(this.state.imovel.broker_id != localStorage.getItem('id')){
      this.setState({acceptContract: false})
      Axios.post(process.env.REACT_APP_API + '/api/auth/verify-contract', {
          type:localStorage.getItem('access'),
          user_id:localStorage.getItem('id'),
          property_id:this.state.imovel?.id
          }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(response => {
        this.setState({verifyContract: true})
      })
      .catch(response => {
        this.setState({verifyContract: false})
      })
    }else{
      this.setState({verifyContract: true})
    }
}

calcUpdate = (date) => {
  const lastUpdate = new Date(date)
  const dataAtual = new Date()
  const diferenca = dataAtual - lastUpdate;
  const total = Math.floor(diferenca / (1000 * 60 * 60 * 24));

  return total;
}

  render() {

    const {imovel, favoritado, verifyDocs, verifyContract, verifyPremium, verifyMktFs} = this.state;

    return (
      // (verifyMktFs && verifyDocs) ?
      (verifyDocs) ?
      <div className="page-content singleProperty">
        <ContractUse
          acceptContract={this.state.acceptContract}
          onContractClosed={this.handleContractClosed}
          onContractAccept={this.handleContractAccepted.bind(this)}
          onContractAcceptCaptador={this.handleContractAcceptedCaptador.bind(this)}
          onContractAcceptDownload={this.handleContractAcceptedDownload.bind(this)}
          onContractAcceptCompartilhar={this.handleContractAcceptedCompartilhar.bind(this)}
          onContractAcceptAll={this.handleButtonReleaseAll.bind(this)}
          imovel={imovel}
          origem={this.state.origem}
          premium={this.state.verifyPremium}
          fit={this.state.verifyFit}
        />
        <Container fluid={true}>
          <Breadcrumbs title={(imovel.sell_contract_type === 'FS Parc' ? 'Parceria'
          : imovel.sell_contract_type === 'FS Parc (sc)' ? 'Parceria' : 'Fast Sale')} breadcrumbItems={this.state.breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row style={{marginBottom:'24px'}}>
                    <Col style={{padding:'0'}}>
                      <SingleImovelCardCarousel photos={imovel?.photos} tour={imovel?.tour_360} fit={this.state.verifyFit} premium={this.state.verifyPremium} onContractAcceptFotos={this.handleContractAcceptedFotos.bind(this)} imovel={imovel}/>
                    </Col>
                  </Row>
                  <Row style={{justifyContent:'space-between'}}>
                    <Col md={7} lg={8}>
                      <Row>
                        <Col xs={12}>
                          <p className='sku' style={{display:'flex', alignItems:'center'}}>
                            <i
                              className={favoritado ? "ri-heart-fill" : "ri-heart-line"}
                              style={{ color: "#0f6e43", fontSize:'22px', marginRight:'8px' }}
                              onClick={this.handleFavoritarClick}
                            />
                            {imovel.sku}
                            {this.calcUpdate(imovel.last_update) === 0 ?
                              <div className="update_now"><i className="ri-star-s-fill"></i>Atualizado hoje</div>
                              :
                              this.calcUpdate(imovel.last_update) < 90 ?
                                <div className="update_medium"><i className="ri-edit-line"></i>Atualizado há {this.calcUpdate(imovel.last_update)} dias</div>
                                :
                                <div className="update_danger"><i className="ri-alert-fill"></i>Atualizado há {this.calcUpdate(imovel.last_update)} dias</div>
                            }
                            {
                              localStorage.getItem('access') === "A" || localStorage.getItem('id') == imovel.broker_id ? (
                                <button className="button-edit-imobile" onClick={() => {
                                    localStorage.setItem('edit_property_sku', imovel.sku)
                                    setTimeout(() => {
                                      window.open("/editar-imovel?" + imovel.id , '_parent')
                                    }, 300);
                                  }}>Editar Imóvel
                                </button>
                              ) : (
                                <></>
                              )
                            }
                          </p>
                          <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                            {imovel?.opportunity === 1 &&
                              <img style={{width:'30px', marginTop: '8px', marginRight:'5px'}} src={Fire}></img>
                            }
                            <h1>{imovel.title}</h1>
                          </div>
                          {verifyContract ?
                              <div className='place'>
                                <i className="ri-map-pin-2-fill"></i>
                                <p>{imovel?.address_properties?.street}, nº {imovel?.address_properties?.number}, {imovel?.address_properties?.district}, {imovel?.address_properties?.city} / {imovel?.address_properties?.state}</p>
                              </div>
                            :
                              <div className='place'>
                                <i className="ri-map-pin-2-fill"></i>
                                <p>
                                  {imovel?.address_properties?.city} <span style={{fontSize:'.8rem', color:'#989898'}}>(Para endereço completo, aceite o termo de parceria)</span> 
                                </p>
                              </div>
                          }
                        </Col> 
                        <Col xs={12}>
                          <Row style={{maxWidth:'600px'}}>
                            {(imovel.dorms_number && imovel.dorms_number !== '0') ?
                            <Col className="highFeature col-auto">
                                <i className="ri-hotel-bed-fill"></i>
                                <p>{imovel.dorms_number} quartos</p>
                              </Col>
                              :
                              <></>
                            }
                            {(((!imovel.dorms_number || imovel.dorms_number === '0') && (imovel.suites_number && imovel.suites_number !== '0'))) ?
                              <Col className="highFeature col-auto">
                                <i className="ri-hotel-bed-fill"></i>
                                <p>{imovel.suites_number} suítes</p>
                              </Col>
                              :
                              <></>
                            }
                            {(imovel.parking_spots && imovel.parking_spots !== '0') ?
                              <Col className="highFeature col-auto">
                                <i className="ri-car-fill"></i>
                                <p>{imovel.parking_spots} vagas</p>
                              </Col>
                              :
                              <></>
                            }
                            <Col className="highFeature col-auto">
                              <i className="ri-ruler-fill"></i>
                              <p>{(imovel?.endorsed_measures && parseInt(imovel?.endorsed_measures) !== 0) ? imovel?.endorsed_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                                (imovel?.terrain_measures && parseInt(imovel?.terrain_measures) !== 0) ? imovel?.terrain_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") :
                                (imovel?.build_measures && parseInt(imovel?.build_measures) !== 0) ? imovel?.build_measures.replace(".", ",").replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,").replace(/(\d)(\d{3}),/g, "$1.$2,") : ''
                              } m²</p>
                            </Col>
                            <Col className="highFeature">
                              <Button onClick={this.tog_large} className="btn-map">
                                <p style={{color:'#333', fontWeight:'600', fontSize:'16px'}}>Mapa</p>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <div className="button-items-verify"> 
                              {(verifyContract && localStorage.getItem('access') !== 'T') ?
                                  <p className='text-accept'>
                                    Parceria Feita <img style={{width: '38px'}} src={ParceriaAceita} alt="Parceria Aceita"></img>
                                  </p>
                                :
                                <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                  if(localStorage.getItem('access') === 'T'){
                                    this.setState({openEnterpriseErrorModal: true})
                                  }else{
                                    this.setState({acceptContract: true})
                                    this.setState({origem: 'geral'})
                                  }
                                  }}>
                                  <span>Fazer parceria</span> <img style={{width: '30px'}} src={ParceriaNaoceita} alt="Realizar Parceria"></img>
                                </Button>
                                }
                            </div>
                          </Row>
                          <Row>
                            <div className="button-items">
                              {imovel.accept_download === 1 && 
                                <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                  if(localStorage.getItem('access') === 'T'){
                                    this.setState({openEnterpriseErrorModal: true})
                                  }else{
                                    if(verifyContract){
                                      if(!verifyPremium && !this.state.verifyFit){
                                        //fazer o download COM logo
                                        this.tog_download()
                                      }else{
                                        //fazer o download SEM logo
                                        document.getElementById('load').classList.add('active')
                                        const axiosOptions = {
                                          responseType: 'arraybuffer',
                                          headers: {
                                            'Content-Type': 'application/json',
                                            "Authorization": "Bearer " + localStorage.getItem('token')
                                          }
                                        }
                                        Axios.post(process.env.REACT_APP_API + '/api/download-media/' + imovel?.id, {
                                            type:localStorage.getItem('access'),
                                            user_id:localStorage.getItem('id'),
                                          }, axiosOptions)
                                          .then((response) => {
                                            const blob = new Blob([response.data], {type: 'application/octet-stream'})
                                            const filename = imovel?.title + '(' + imovel?.sku + ').zip'
                                            saveAs(blob, filename)
                                            document.getElementById('load').classList.remove('active')
                                            const data = new FormData()
                                            data.append('type', localStorage.getItem('access'));
                                            data.append('user_id', localStorage.getItem('id'))
                                            Axios.post(process.env.REACT_APP_API + '/api/download-count/' + imovel?.id, data)
                                            document.getElementById('load').classList.remove('active')
                                          })
                                          .catch((e) => {
                                            document.getElementById('load').classList.remove('active')
                                          })
                                      }
                                    }else{
                                      this.setState({acceptContract: true})
                                      this.setState({origem: 'download'})
                                    }
                                  }
                                  }}>
                                  Baixar material <i className="ri-download-2-line align-middle ms-2"></i>
                                </Button>
                              }
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                // if(localStorage.getItem('access') === 'T'){
                                //   this.setState({openEnterpriseErrorModal: true})
                                // }else{
                                  if(verifyContract){
                                    if(!verifyPremium && !this.state.verifyFit && localStorage.getItem('access') !== 'T' && localStorage.getItem('access') !== 'S'){
                                      //fazer o download COM logo
                                      this.tog_share()
                                    }else{
                                      //fazer o download SEM logo
                                      Axios.post(process.env.REACT_APP_API + '/api/auth/log-shares', {
                                        type: localStorage.getItem('access'),
                                        user_id: localStorage.getItem('id'),
                                        property_id: imovel?.id
                                        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                        .then(response => {
                                          this.setState({modal_share_options: true})
                                        })
                                    }
                                  }else{
                                    this.setState({acceptContract: true})
                                    this.setState({origem: 'compartilhar'})
                                  }
                                // }
                                }}>
                                Compartilhar <i className="ri-share-forward-fill align-middle ms-2"></i>
                              </Button>
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                if(localStorage.getItem('access') === 'T'){
                                  this.setState({openEnterpriseErrorModal: true})
                                }else{
                                  if(!verifyPremium && !this.state.verifyFit){
                                    this.tog_premium()
                                  }else{
                                    if(verifyContract){
                                      this.tog_integration()
                                    }else{
                                      this.setState({acceptContract: true})
                                      this.setState({origem: 'integrar'})
                                    }
                                  }
                                }
                                }}>
                                Integrar <i className="ri-send-plane-line align-middle ms-2"></i>
                              </Button>
                              <Button type="button" className="waves-effect waves-light me-1" onClick={() => {
                                if(localStorage.getItem('access') === 'T'){
                                  this.setState({openEnterpriseErrorModal: true})
                                }else{
                                  if(verifyContract){
                                    //iniciar conversa no whatsapp
                                    Axios.post(process.env.REACT_APP_API + '/api/auth/log-talks', {
                                      type: localStorage.getItem('access'),
                                      user_id: localStorage.getItem('id'),
                                      property_id: imovel?.id
                                    },
                                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                                      .then(response => {
                                        setTimeout(() => {
                                          window.open(`https://api.whatsapp.com/send?phone=55` + (imovel?.broker?.phone ? imovel?.broker?.phone : imovel?.real_estate?.phone ? imovel?.real_estate?.phone : '4741081865' )?.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, "") + `&text=Olá,%20vi%20esse%20imóvel%20na%20Fast%20Sale: ` + imovel?.title + " - " + imovel.address_properties.city + " (" + imovel.sku + ') - https://fastsaleimoveis.com.br/imovel/' + imovel.slug, "_blank")
                                        }, 300);
                                      })
                                  }else{
                                    this.setState({acceptContract: true})
                                    this.setState({origem: 'captador'})
                                  }
                                }
                                }}>
                                Falar com captador <i className="ri-whatsapp-line align-middle ms-2"></i>
                              </Button>
                            </div>
                          </Row>
                          <Row style={{justifyContent:'center'}}>
                            {window.screen.width <= 768 &&
                              <SingleAction verifyContract={this.state.verifyContract} handleVerifyContract={this.handleVerifyContract} premium={this.state.verifyPremium} imovel={imovel}/>
                            }
                          </Row>
                          <Row>
                            <div id="accordion">
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col1} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingOne">
                                    <h6 className="m-0 font-14">
                                      <h3>Sobre o imóvel</h3>
                                      <div className="text_muted"><p>Veja mais informações sobre esse imóvel</p></div>
                                      <i className={this.state.col1 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col1}>
                                  <CardBody>
                                  <pre-line>{imovel.description}</pre-line>
                                    <div style={{margin:'15px 0 10px'}}><h3 style={{fontSize:'14px', fontWeight:'600'}}>Espaços e medidas</h3></div>
                                    <div className="container-medidas">
                                      {(imovel?.endorsed_measures && imovel?.endorsed_measures !== '0') && <p>Área privativa: <b>{imovel?.endorsed_measures + 'm²'}</b></p>}
                                      {(imovel?.build_measures && imovel?.build_measures !== '0' && imovel?.terrain_measures !== '0.00') &&<p>Área construída: <b>{imovel?.build_measures + 'm²'}</b></p>}
                                      {(imovel?.terrain_measures && imovel?.terrain_measures !== '0' && imovel?.terrain_measures !== '0.00') &&<p>Área total: <b>{imovel?.terrain_measures + 'm²'}</b></p>}
                                    </div>
                                  </CardBody>
                                </Collapse>
                              </Card>
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col2} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    {/* <h6 className="m-0 font-14">
                                      {" "}Outras características{" "}
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6> */}
                                    <h6 className="m-0 font-14">
                                      <h3>Outras características</h3>
                                      <div className="text_muted"><p>Características e tags do imóvel</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col2}>
                                  <CardBody className="tags">
                                    {imovel?.features?.filter(feature => feature.show_on_venture === 1).map((feat, index) => (
                                      <li key={index}>{feat.name}</li>
                                    ))}
                                    {imovel?.tag?.map((tag, index) => (
                                      <li key={index}>{tag.tags?.name}</li>
                                    ))}
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              {(imovel?.accept_car === 1 || imovel?.accept_property === 1 || imovel?.real_state_financing === 1) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col4} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingTwo">
                                    {/* <h6 className="m-0 font-14">
                                      {" "}Outras características{" "}
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6> */}
                                    <h6 className="m-0 font-14">
                                      <h3>Condições de negociação</h3>
                                      <div className="text_muted"><p>Condições para negociação do imóvel</p></div>
                                      <i className={this.state.col4 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col4}>
                                  <CardBody className="negotiation">
                                  <ul style={{listStyle:'none', padding:'0'}}>
                                    {imovel?.accept_car === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita carro</li>
                                    }
                                    {imovel?.accept_property === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita imóveis</li>
                                    }
                                    {imovel?.real_state_financing === 1 &&
                                      <li style={{fontSize:'18px', display:'flex', alignItems:'center'}}><i class="ri-checkbox-circle-fill" style={{color:'green'}}></i>Aceita Fin. Imob.</li>
                                    }
                                  </ul>
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                              {(imovel?.youtube_video !== '' && imovel?.youtube_video !== 'null' && imovel?.youtube_video) &&
                              <Card className="mb-1 shadow-none" style={{marginTop:'30px', borderBottom:'solid 1px #cdcdcd'}}>
                                <Link to="#" onClick={this.t_col3} style={{ cursor: "pointer" }} className="text-dark" >
                                  <CardHeader id="headingThree">
                                    <h6 className="m-0 font-14">
                                      <h3>Vídeo</h3>
                                      <div className="text_muted"><p>Assista um vídeo do imóvel</p></div>
                                      <i className={this.state.col2 ? "mdi mdi-minus float-end accor-plus-icon" : "mdi mdi-plus float-end accor-plus-icon"}></i>
                                    </h6>
                                  </CardHeader>
                                </Link>
                                <Collapse isOpen={this.state.col3}>
                                  <CardBody>
                                    <div className="ratio ratio-16x9">
                                      <iframe title="video4" className="embed-responsive-item" src={"https://www.youtube.com/embed/" + imovel?.youtube_video?.replace('https://youtu.be/', '')?.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').split('&').slice(0,1).toString()}></iframe>
                                    </div>
                                  </CardBody>
                                </Collapse>{" "}
                              </Card>
                              }
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5} lg={4} style={{justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                      {window.screen.width > 768 &&
                        <SingleAction verifyContract={this.state.verifyContract} handleVerifyContract={this.handleVerifyContract} premium={this.state.verifyPremium} imovel={imovel}/>
                      }
                    </Col>
                  </Row>
                  {this.state.relacionados?.length > 0 &&
                  <Row>
                    <Col className="col-lg-12">
                      <div style={{padding:'40px 20px 0px'}}><h4 style={{fontSize:'22px'}}>Imóveis semelhantes</h4></div>
                    </Col>
                    <Col className="col-lg-12" style={{padding:'0'}}>
                      <Swiper
                      slidesPerView={"auto"}
                      spaceBetween={0}
                      navigation={true}
                      modules={[Navigation]}
                      className="SwiperImoveis"
                      >
                        
                          {this.state.relacionados?.map((imovel, index) => (
                              <SwiperSlide key={index}><ImovelCard  imovel={imovel} premium={(this.state.verifyPremium || this.state.verifyFit) ? 1 : 0}  favoritos={this.state.favoritos}/></SwiperSlide>
                          ))}
                          <SwiperSlide><div className="moreAll" onClick={() => {
                          localStorage.setItem('paginaAtual', '1')
                          setTimeout(() => {
                              window.open("/imoveis", '_parent')
                          }, 300);
                      }}>Ver mais imóveis</div></SwiperSlide>
                      </Swiper>
                    </Col>
                  </Row>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* PopUp Google Maps */}
          <Modal
            size="lg"
            centered={true}
            isOpen={this.state.modal_large}
            toggle={this.tog_large}
          >
            <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                Localização aproximada do imóvel
            </ModalHeader>
            <ModalBody>
              <Maps latI={imovel?.latitude} lngI={imovel?.longitude} zoomLevel={13}/>
            </ModalBody>
          </Modal>


          {/* PopUp Integração */}
          <Modal
            centered={true}
            isOpen={this.state.modal_integration}
            toggle={this.tog_integration}
          >
            <ModalHeader toggle={() => this.setState({ modal_integration: false })} style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <div className="popupText">
                <p>Escolha o canal para <span style={{cursor:'initial', textDecoration:'none'}}>enviar o imóvel</span></p>
              </div>
              {/* integrationChannel:null,
              integrationType:null, */}
            </ModalHeader>
            <ModalBody>
              <Container className="integrationOptions">
                <Row style={{justifyContent:'center'}}>
                  <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                    <img src={CanalPro} onClick={(e) => {
                      this.setState({integrationChannel: 1})
                      document.getElementById('canalPro').click()
                    }}></img>
                    <Input className="form-check-input" type="radio" name="exampleRadios" id="canalPro" value="option2" onClick={(e) => {
                      this.setState({integrationChannel: 1})
                    }}/>
                  </Col>
                  <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                    <img src={ChavesMao} onClick={(e) => {
                      this.setState({integrationChannel: 2})
                      document.getElementById('chavesNaMao').click()
                    }}></img>
                    <Input className="form-check-input" type="radio" name="exampleRadios" id="chavesNaMao" value="option2"  onClick={(e) => {
                      this.setState({integrationChannel: 2})
                    }}/>
                  </Col>
                  {!this.state.verifyFit &&
                    <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                      <img src={SeuSite} onClick={(e) => {
                        this.setState({integrationChannel: 3})
                        document.getElementById('seuSite').click()
                      }}></img>
                      <Input className="form-check-input" type="radio" name="exampleRadios" id="seuSite" value="option2"  onClick={(e) => {
                        this.setState({integrationChannel: 3})
                      }}/>
                    </Col>
                    // :
                    // <Col className="col-auto" style={{flexDirection:'column', display:'flex', alignItems:'center'}}>
                    //   <img src={SeuSite} onClick={(e) => {
                    //     this.setState({integrationChannel: 4})
                    //     document.getElementById('seuSiteNone').click()
                    //   }}></img>
                    //   <Input className="form-check-input" type="radio" name="exampleRadios" id="seuSiteNone" value="option2"  onClick={(e) => {
                    //     this.setState({integrationChannel: 4})
                    //   }}/>
                    // </Col>
                  }
                </Row>
              </Container>
              <Container>
                {this.state.integrationChannel === 1 &&
                  <Row style={{marginTop:'20px', justifyContent:'space-between'}}>
                    <Label>Escolha o tipo de anúncio para o <b style={{color:'#3E5E3F', fontSize:'16px'}}>Canal Pro</b></Label>
                    <select className="form-control" value={this.state.integrationType} onChange={(e) => this.setState({integrationType: e.target.value})}>
                      <option value={0}>Simples</option>
                      <option value={1}>Destaque</option>
                      <option value={2}>Super Destaque</option>
                    </select>
                  </Row>
                }
                {/* {this.state.integrationChannel === 3 &&
                  <Row style={{marginTop:'20px', justifyContent:'space-between'}}>
                    <Label>Escolha o tipo de anúncio para <b style={{color:'#3E5E3F', fontSize:'16px'}}>Seu Site FS</b></Label>
                    <select className="form-control" value={this.state.integrationType} onChange={(e) => this.setState({integrationType: e.target.value})}>
                      <option value={0}>Simples</option>
                      <option value={1}>Destaque</option>
                    </select>
                  </Row>
                } */}
                {this.state.integrationChannel === 4 &&
                  <Row style={{marginTop:'20px', justifyContent:'space-between'}}>
                    <Card color="dark" className="text-white-50">
                      <CardBody>
                          <CardTitle className="text-white"><i className="mdi mdi-alert-outline me-3"></i>Atenção</CardTitle>
                          <CardText>Antes de adicionar imóveis ao seu site você precisa definir um layout, para isso acesse o construtor "Seu site FS" no menu e conclua todas as etapas.</CardText>
                      </CardBody>
                    </Card>
                  </Row>
                }
              </Container>
            </ModalBody>
            <ModalFooter>
                <div className="popupButtons" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button type="button" color="light" onClick={() => this.setState({ modal_integration: false })}>Cancelar</Button>
                  {(this.state.integrationChannel && this.state.integrationChannel !== 4) ?
                    <Button type="button" onClick={() => {
                      document.getElementById('load').classList.add('active')
                      if(this.state.integrationChannel === 3){
                        const data = new FormData()
                        data.append('user_type', localStorage.getItem('access'))
                        data.append('user_id', localStorage.getItem('id'))
                        data.append('property_ids[]', imovel?.id)
                        Axios.post(process.env.REACT_APP_API + '/api/auth/personal-pages-properties-create', data,
                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_success: true})
                        })
                        .catch(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_error: true})
                        })
                      }else{
                        const data = new FormData()
                        data.append('property_id', imovel?.id)
                        data.append('user_id', localStorage.getItem('id'))
                        data.append('type', localStorage.getItem('access'))
                        data.append('platform', this.state.integrationChannel)
                        data.append('integration_type', this.state.integrationType)
  
                        Axios.post(process.env.REACT_APP_API + '/api/auth/add-integration', data,
                        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                        .then(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_success: true})
                        })
                        .catch(response => {
                          this.setState({ modal_integration: false })
                          document.getElementById('load').classList.remove('active')
                          this.setState({integration_error: true})
                        })
                      }
                    }}>Integrar</Button>
                    :
                    <Button type="button" disabled>Integrar (escolha um canal)</Button>
                  }
                </div>
            </ModalFooter>
          </Modal>

          {/* Sucesso na integração */}

          {this.state.integration_success ? (
            <SweetAlert
              title="Imóvel integrado com sucesso"
              timeout={2000}
              //                         style={{
              //   position: "absolute",
              //   top:"0px",
              //   right:"0px"
              // }}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => this.setState({ integration_success: false })}
            ></SweetAlert>
            ) : null}

          {/* Falha na integração */}

          {this.state.integration_error ? (
            <SweetAlert
              title="O imóvel não pode ser integrado, tente novamente."
              timeout={2000}
              // style={{
              //   position: "absolute",
              //   top:"50%",
              //   right:"50%"
              // }}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ integration_error: false })}
            ></SweetAlert>
            ) : null}


          {/* PopUp Não Premium Integração */}
          <Modal
            centered={true}
            isOpen={this.state.modal_premium}
            toggle={this.tog_premium}
          >
            <ModalHeader toggle={() => this.setState({ modal_premium: false })}>
              <div className="popupText">
                <p>Para integrar os imóveis da Fast Sale com outros portais em apenas um clique, <span>assine o plano Premium</span></p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_premium: false })}>
              <div className="popupImage">
                {window.screen.width > 768 &&
                  <img src={Integrar}></img>
                }
                {window.screen.width <= 768 &&
                  <img src={IntegrarMobile}></img>
                }
              </div>
              <ModalFooter>
                <div className="popupButtons">
                  <Button onClick={() => {
                    setTimeout(() => {
                      window.open("/home", '_parent')
                    }, 300)
                  }}>Quero ser Premium!</Button>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>


          {/* PopUp Premium Download */}   
          <Modal
            centered={true}
            isOpen={this.state.modal_download}
            toggle={this.tog_download}
          >
            <ModalHeader toggle={() => this.setState({ modal_download: false })} style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <div className="popupText">
                <p>Esse arquivo .zip contém as fotos<br></br> <span style={{cursor:'initial', textDecoration:'none'}}>com marca d'água</span></p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_download: false })}>
              <div className="popupImage">
                <img src={Download}></img>
              </div>
              <div>
                <p className="popupTextContent">Para baixar as fotos <span>sem marca'água</span> <br></br> assine um de nossos planos</p>
              </div>
            </ModalBody>
            <ModalFooter>
                <div className="popupButtons" style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button className="comlogo" onClick={() => {
                    document.getElementById('load').classList.add('active')
                    const axiosOptions = {
                      responseType: 'arraybuffer',
                      headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem('token')
                      }
                    }
                    Axios.post(process.env.REACT_APP_API + '/api/download-media/' + imovel?.id, {
                        type:localStorage.getItem('access'),
                        user_id:localStorage.getItem('id'),
                      }, axiosOptions)
                      .then((response) => {
                        const blob = new Blob([response.data], {type: 'application/octet-stream'})
                        const filename = imovel?.title + '(' + imovel?.sku + ').zip'
                        saveAs(blob, filename)
                        document.getElementById('load').classList.remove('active')
                        const data = new FormData()
                        data.append('type', localStorage.getItem('access'));
                        data.append('user_id', localStorage.getItem('id'))
                        Axios.post(process.env.REACT_APP_API + '/api/download-count/' + imovel?.id, data)
                        document.getElementById('load').classList.remove('active')
                        this.setState({ modal_download: false })
                      })
                      .catch((e) => {
                        document.getElementById('load').classList.remove('active')
                      })
                    }}>Baixar com marca d'água</Button>
                  <Button onClick={() => {
                    setTimeout(() => {
                      window.open("/home", '_parent')
                    }, 300)
                  }}>Quero ser Premium!</Button>
                </div>  
              </ModalFooter>
          </Modal>

          {/* PopUp Premium Compartilhamento */}   
          <Modal
            centered={true}
            isOpen={this.state.modal_share}
            toggle={this.tog_share}
          >
            <ModalHeader toggle={() => this.setState({ modal_share: false })} style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <div className="popupText">
                <p>Você está compartilhando o imóvel<br></br> <span style={{cursor:'initial', textDecoration:'none'}}>com marca d'água nas fotos</span></p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_share: false })}>
              <div className="popupImage">
                <img src={Compartilhar}></img>
              </div>
              <div className="popupText">
                <p>Para compartilhar o imóvel <span style={{cursor:'initial', textDecoration:'none'}}>sem marca d'água nas fotos</span> assine um de nossos planos</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="popupButtons" style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button  className="comlogo" onClick={() => {
                  Axios.post(process.env.REACT_APP_API + '/api/auth/log-shares', {
                    type: localStorage.getItem('access'),
                    user_id: localStorage.getItem('id'),
                    property_id: imovel?.id
                    }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                      setTimeout(() => {
                        window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent('https://share.fastsaleimoveis.com.br/imovel?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + imovel?.id ), '_blank');
                      }, 300);
                    })
                  }}>Compartilhar com marca d'água</Button>
                  <Button onClick={() => {
                    setTimeout(() => {
                      window.open("/home", '_parent')
                    }, 300)
                  }}>Quero ser Premium!</Button>
                </div>
              </ModalFooter>
          </Modal>

          {/* PopUp Opções Compartilhamento */}
          <Modal
            centered={true}
            isOpen={this.state.modal_share_options}
            toggle={this.tog_share_options}
            className="share-options-modal"
          >
            <ModalHeader toggle={() => this.setState({ modal_share_options: false })}>
              <div className="popupText">
                <p>Compartilhar imóvel</p>
              </div>
            </ModalHeader>
            <ModalBody toggle={() => this.setState({ modal_share_options: false })}>
              <div className="share-options-container">
                <div className="copy-share-input">
                  <input id="shareInput" value={'https://share.fastsaleimoveis.com.br/imovel?ce29=' + localStorage.getItem('access') + '&ba05a=' + (localStorage.getItem('access') === 'S' ? localStorage.getItem('construction_id') : localStorage.getItem('id')) + '&83acd=' + this.state.imovel?.id } disabled></input>
                  <span onClick={async () => {
                    const inputElement = document.getElementById('shareInput');
                    try {
                      await navigator.clipboard.writeText(inputElement.value);
                      this.setState({copySuccess :'URL copiada para a área de transferência'});
                    } catch (err) {
                      console.error('Erro ao copiar para a área de transferência', err);
                      this.setState({copySuccess :'Erro ao copiar para a área de transferência'});
                    }
                  }}><i className="ri-file-copy-line"></i></span>
                </div>
                {this.state.copySuccess && <p>{this.state.copySuccess}</p>}
                <div className="icons-share-container">
                  <div className="share-option" onClick={() => {
                    setTimeout(() => {
                      window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent('https://share.fastsaleimoveis.com.br/imovel?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + this.state.imovel?.id ), '_blank')
                    }, 100);
                  }}>
                    <i className="ri-whatsapp-fill" style={{color:"#52C665"}}></i>
                  </div>
                  <div className="share-option" onClick={() => {
                    setTimeout(() => {
                      window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent('https://share.fastsaleimoveis.com.br/imovel?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + this.state.imovel?.id ), '_blank')
                    }, 100);
                  }}>
                    <i className="ri-facebook-circle-fill" style={{color:"#0078F6"}}></i>
                  </div>
                  {/* <div className="share-option" onClick={() => {
                    setTimeout(() => {
                      window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(window.location.href.replace('?owner', '').replace(this.state.imovel?.slug, '').replace('imovel', 'compartilhar-imovel') + '?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + this.state.imovel?.id ), '_blank')
                    }, 100);
                  }}>
                    <i className="ri-linkedin-fill" style={{color:"#0066A1"}}></i>
                  </div> */}
                  <div className="share-option" onClick={() => {
                    setTimeout(() => {
                      window.open("https://telegram.me/share/url?url=" + encodeURIComponent('https://share.fastsaleimoveis.com.br/imovel?ce29=' + localStorage.getItem('access') + '&ba05a=' + localStorage.getItem('id') + '&83acd=' + this.state.imovel?.id ), '_blank')
                    }, 100);
                  }}>
                    <i className="ri-telegram-fill" style={{color:"#0088CC"}}></i>
                  </div>
                </div>
              </div>
              <ModalFooter>
                <div className="popupButtons">
                  <Button onClick={() => this.setState({ modal_share_options: false })}>Fechar</Button>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>

          <div id="load" className="load">
            <Spinner className="me-2" color="success" />
          </div>

          {this.state.openEnterpriseErrorModal ? (
            <SweetAlert
              title="Esta função não está disponível para construtoras."
              onConfirm={() => this.setState({ openEnterpriseErrorModal: false })}
            ></SweetAlert>
          ) : null}
        </Container>
      </div>
       :
       verifyDocs ?
       <div className="page-content">
        <Container fluid={true}>
          <NoPremiumProperty/>
        </Container>
       </div>
       :
       <div className="page-content">
       <Container fluid={true}>
         <NoDocsProperty/>
       </Container>
      </div>
    );
  }
}

export default SingleProperty;