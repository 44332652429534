import { useState } from 'react';
import './styles.scss';

export const SelectedPlanInfo = ({item, horizontal = false,  totalBrokers = 0, properties = 0, typeUser = '', freePlan = null}) => {
  
    const [planActive, setPlanActive] = useState({});
  
    const togglePlanDetails = (slug) => {
      setPlanActive(prevState => ({
        ...prevState,
        [slug]: !prevState[slug]
      }));
    };
  
    const formattedValue = (prop) => {
      return parseFloat(prop / 100000000000).toLocaleString("pt-BR", { maximumSignificantDigits: 2 }).toLocaleString("pt-BR", { minimumSignificantDigits: 1 });
    } 
  
    return(
      <div className={`cart-plan-content ${item.slug} ${horizontal ? 'horizontal' : ''}`}>
        <div className={`home-plans-card ${item.slug}`}>
          <div className="home-plans-title">
            <h3>{typeUser === 'C' ? 'Corretor ' : typeUser === 'I' ? 'Imobiliária ' : typeUser === 'T' ? 'Construtora ' : ''}{item.name}</h3>
          </div>
          {/* {item.skills_title !== '' && <h5>{item.skills_title}</h5>} */}
        </div>
        <div className={`list-open ${planActive[item.slug] ? 'active' : ''}`} onClick={() => togglePlanDetails(item.slug)}>
          <span>Detalhes do plano</span>
          <i className=" ri-arrow-down-s-line"></i>
        </div>
        <div className={`home-plans-list ${planActive[item.slug] ? 'active' : ''}`}>
          {horizontal && <h4>Vantagens</h4>}
          <ul>
            {freePlan.skills.split(',').filter(skills => (skills !== 'Baixar fotos') && (skills !== '3 parcerias por mês'))?.map((skill, i) => (
              <li key={i} className={item.slug === 'premium' && (i === 1 || i === 0) ? '' : ''}><i className="ri-checkbox-circle-fill"></i>{skill}</li>
            ))}
            {item.skills.split(',').map((skill, i) => (
              <li key={i} className={item.slug === 'premium' && (i === 1 || i === 0) ? '' : ''}><i className="ri-checkbox-circle-fill"></i>{skill}</li>
            ))}
          </ul>
        </div>
      </div>
    )
}