import React, { Component } from 'react';
import { Badge, Button, Col, Label, Row } from "reactstrap";
import './../../assets/scss/custom/components/_singleUnits.scss'
import SweetAlert from 'react-bootstrap-sweetalert';
import Fire from './../../assets/images/Icons/fogooutlet.png';

class UnitsAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUnits:0,
      unit_error: false,
      active:'inactive',
    };

  }

  componentDidUpdate(prevProps) {
    const { types } = this.props;

    if (types !== prevProps.types) {
      const totalUnits = this.calculateTotalUnits(types);
      this.setState({ totalUnits });
    }
  }

  calculateTotalUnits(types) {
    let totalUnits = 0;
    types.forEach((type) => {
      type.units.forEach((unit) => {
        if (unit.status === 1) {
          totalUnits++;
        }
      });
    });
    return totalUnits;
  }


  render(){

    const {totalUnits, active} = this.state;

    return(
      <>
      <div id="unitsListContainer" className={`${active}`} style={{width:'100%'}}>
          <div className="unitsToggle" onClick={() => {
              if(active === 'inactive'){
                this.setState({active: 'active'})
              }
              if(active === 'active'){
                this.setState({active: 'inactive'})
              }
          }}>
            {active === 'inactive' ?
              <Button>Unidades disponíveis</Button>
              :
              <i className="ri-close-line"></i>
            }
          </div>
          <Row>
            <Col className="col-auto" style={{border:'solid 1px #888', padding:'6px 15px', borderRadius: '8px'}}>
              <p>{totalUnits} {totalUnits === 1 ? 'unidade disponível' : 'unidades disponíveis'}</p>
            </Col>
          </Row>
          {this.props.types?.map((type, index) => (
            <>
            <Row key={index}>
              <Col xs={12} style={{textAlign:'center', margin:'20px 0'}}>
                <h4 style={{fontSize:'16px'}}>{type.title}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={3} style={{textAlign:'center'}}>
                <Label className="col-title" style={{fontSize:'16px', fontWeight:'600', marginBottom:'10px'}}>Unidade</Label>
              </Col>
              <Col xs={3} style={{textAlign:'center'}}>
                <Label className="col-title" style={{fontSize:'16px', fontWeight:'600', marginBottom:'10px'}}>m² Privativos</Label>
              </Col>
              <Col xs={4} style={{textAlign:'center'}}>
                <Label className="col-title" style={{fontSize:'16px', fontWeight:'600', marginBottom:'10px'}}>Valor</Label>
              </Col>
              <Col xs={2}></Col>
            </Row>
            {type?.units?.map((unit, i) => (
              <Row onClick={() => {
                if(unit.status === 1){
                    setTimeout(() => {
                      window.open(`./../../../unidade/${unit.slug}`, '_parent')
                    }, 300);
                }else{
                  this.setState({unit_error: true})
                }
              }} key={i} className={i % 2 === 0 ? "grey-background unitItem" : "white-background unitItem"}>
                <Col xs={3} style={{textAlign:'center'}}>
                  <p>{unit?.sku}</p>
                </Col>
                <Col xs={3} style={{textAlign:'center'}}>
                  <p>{type?.private_area}</p>
                </Col>
                <Col xs={4} style={{textAlign:'center'}}>
                  {unit?.status == 1 ?
                    <div className="unit-status">
                      <Badge className="bg-primary rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer', minWidth:'160px'}}>
                        {((unit?.offer_price || unit?.price) ? (unit?.offer_price ? unit?.offer_price : unit?.price) / 100 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        {unit?.opportunity === 1 ? <img className="outlet" src={Fire}></img> : <></>}
                      </Badge>
                    </div>
                    :<></>
                  }
                  {unit?.status == 2 ? <div className="unit-status">
                      <Badge className="bg-warning me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer', minWidth:'160px'}}>Reservado</Badge>
                    </div>
                    :<></>
                  }
                  {unit?.status == 3 ? <div className="unit-status">
                      <Badge className="badge-soft-dark rounded-pill me-1" style={{fontSize:'16px', cursor:'pointer', minWidth:'160px'}}>Vendido</Badge>
                    </div>
                    :<></>
                  }
                  {unit?.status == 4 ? <div className="unit-status">
                      <Badge className="bg-danger me-1 rounded-pill" style={{fontSize:'16px', cursor:'pointer', minWidth:'160px'}}>Indisponível</Badge>
                    </div>
                    :<></>
                  }
                </Col>
                <Col xs={2} className="cta">
                  <p>Ver</p>
                </Col>
              </Row>
            ))}
            </>
          ))}

          {this.state.unit_error ? (
            <SweetAlert
              title="Esta unidade não está disponível no momento."
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              error
              onConfirm={() => this.setState({ unit_error: false })}
            ></SweetAlert>
            ) : null}
    </div>
    </>
    )
  }
}

  export default UnitsAction;
